import React, { useEffect } from "react";
import { BASE_URL } from "../../../src/global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Checkbox,
  TableRow,
  FormControlLabel,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../utils/components/Styles";
import moment from "moment";
const axios = require("axios");

export default function CreateCustomInvoice(props) {
  const [srfObject, createSrfObject] = React.useState({});
  var [clientArray, setClientArray] = React.useState([]);
  const [address, setAddress] = React.useState("");
  const [gstNumber, setGstNumber] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [invoiceDate, setInvoiceDate] = React.useState("");
  const [conveyanceCharges, setConveyanceCharges] = React.useState("");
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalD, setTotalD] = React.useState(0);
  const [gstType, setGstType] = React.useState(null);

  const [contactNumber, setContactNumber] = React.useState("");
  const [poNumber, setPoNumber] = React.useState("");
  const [sGst, setSGST] = React.useState(0);
  const [cGst, setCGST] = React.useState(0);
  const [readingRows, setReadingRows] = React.useState([["", "", "", "", ""]]);
  const [checkedClient, setChecked] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [clientAddress, setClientAddress] = React.useState("");
  const [invoiceType, setInvoiceType] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [productArray, setProductArray] = React.useState([]);
  const [productsArray, setProductsArray] = React.useState([]);
  const [autoProductArray, setAutoProductArray] = React.useState([]);
  const [rate, setRate] = React.useState("");
  const [totalGst, setTotalGst] = React.useState(0);

  const getClientIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getCustomProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "customProducts")
      .then((res) => {
        setProductArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getProductList = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=pr.products,_j,ins.instruments&_on1=(pr.srfInstrumentId,eq,ins.id)&_fields=pr.id,pr.rate,ins.instrumentName&_where=(pr.status,eq,1)"
      )
      .then((res) => {
        setAutoProductArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var pushCArray = [];
  var clientsArrays = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
    });
  }

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "productName *",
        headerName: "Product Name",
        editable: true,
      },
      {
        field: "discription",
        headerName: "Discription",
        editable: true,
      },
      {
        field: "quantity *",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "rate *",
        headerName: "Rate",
        editable: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        editable: true,
      },
      {
        field: "Action",
      },
    ],
  ];

  // submit form
  const submitDescriptionRows = (readingRows, id) => {
    let rows = readingRows.map((row) => ({
      customProductId: id,
      productId: row[0],
      discription: row[1],
      quantity: parseInt(row[2]),
      rate: parseInt(row[3]),
      // discount: discount,
      amount: parseInt(row[3]) * parseInt(row[2]),
      totalAmount: totalAmount,
      cgst: parseInt(cGst),
      sgst: parseInt(sGst),
    }));

    rows.map((rows) => {
      let url = BASE_URL;
      axios
        .post(url + "customProductRanges", rows)
        .then((res) => {
          toast("product created successfully !");
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      clientId: srfObject.clientId,
      poDate: moment(srfObject.poDate).format("YYYY-MM-DD"),
      invoiceNumber: (Math.random() + 1).toString(36).substring(7),
      subject: subject,
      invoiceDate: moment(srfObject.invoiceDate).format("YYYY-MM-DD"),
      poNumber: poNumber,
      gstType: gstType,
      invoiceType: invoiceType,
    };


    var sGstTot, cGstTot;
    if(gstType == 1 ){
      sGstTot = totalGst/2
      cGstTot =totalGst/2
    }else{
      sGstTot = totalGst
      cGstTot = 0
    }

    var url = BASE_URL;
    axios
      .post(url + "customInvoice", row)
      .then((res) => {
        toast("Custom Invoice created successfully !");
        var insertedID = res.data.insertId;
        submitDescriptionRows(readingRows, insertedID);
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });

    let rows = {
      clientId: srfObject.clientId,
      totalAmount: totalAmount ? totalAmount : 0,
      paidAmount:  0,
      discount: totalD,
      gstType: gstType,
      sgst:sGstTot,
      cgst: cGstTot,
      subject: subject,
      invoiceNumber: (Math.random() + 1).toString(36).substring(7),
      contactNumber: contactNumber,
      poNumber: poNumber,
      invoiceDate: srfObject.invoiceDate
        ? moment(srfObject.invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      paymentDate: srfObject.invoiceDate
        ? moment(srfObject.invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    };
    axios
      .post(url + "paymentMaster", rows)
      .then((res) => {
        props.setLoader(false);
        toast("Payment created successfully!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  const initiateInstrumentList = () => {
    var pushIArray = [];
    for (let i = 0; i < productArray.length; i++) {
      pushIArray.push({
        label: productArray[i].id + ", " + productArray[i].productName,
        rate: productArray[i].rate,
      });
    }
    setProductsArray(pushIArray);
  };

  useEffect(() => {
    getClientIdList();
    getCustomProductList();
    getProductList();
  }, []);

  useEffect(() => {
    initiateInstrumentList();
  }, [productArray]);

  const saveClient = (event) => {
    let row = {
      companyName: companyName,
      contact: contact,
      address: clientAddress,
    };

    let url = BASE_URL;

    axios
      .post(url + "clients", row)
      .then((res) => {
        toast("client created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const counterAmt = () => {
    var count = 0;
    for (let i = 0; i < readingRows.length; i++) {
      if (readingRows[i][2] != "" && readingRows[i][3] != "") {
        count += parseInt(readingRows[i][2] * readingRows[i][3]);
      }
    }
    setTotalGst((18 * (count)) / 100);
    setTotalAmount(count);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", rate, ""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.splice(rowIndex, 0);
    setReadingRows([...newReadingRows]);
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/standard">Home</Link>
  };

  useEffect(() => {
    counterAmt();
  }, [readingRows]);

  const invoicesData = () => {
    if (gstType === 1) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="SGST"
              InputLabelProps={{ shrink: true }}
              value={(9 * totalAmount) / 100}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="CGST"
              disabled
              value={(9 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else if(gstType === 2){
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="IGST"
              value={(18 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              disabled
              onChange={(e) => {
                setTotalGst(("totalGst", 18 * totalAmount) / 100);
              }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    }else{}
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Custom Invoice
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                "clientId",
                parseInt(newInputValue.split(",")[0])
              );
              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue.split(",")[0])) {
                  if (client.address) {
                    setAddress(client.address);
                    setGstNumber(client.gstNumber);
                  } else {
                    setAddress("");
                    setGstNumber("");
                  }
                }
              });
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Supply No *"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("supplyNo", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="GST No. *"
            value={gstNumber}
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{ shrink: true }}
            label="Address *"
            value={address}
            size="small"
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Po Date *"
              inputFormat="dd/MM/yyyy"
              value={new Date()}
              onChange={(newValue) => {
                updateSrfObject("poDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Invoice Number"
            size="small"
            value={(Math.random() + 1).toString(36).substring(7)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Subject *"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Invoice Date *"
              value={invoiceDate ? invoiceDate : new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setInvoiceDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="PO Number *"
            size="small"
            value={poNumber}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPoNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { value: 1, label: "State GST" },
              { value: 2, label: "Central Gst" },
              { value: 3, label: "Non Gst" },
            ]}
            renderInput={(params) => <TextField {...params} label="GST Type *" />}
            onInputChange={(event, newInputValue) => {
              if(newInputValue == "State GST"){
                setGstType(1)
              }else if(newInputValue == "Central Gst"){
                setGstType(2)
              }else{
                setGstType(3)
              }
              if (newInputValue == "State GST") {
                setCGST((18 * totalAmount) / 100);
              } else if(newInputValue == "Central Gst"){
                setCGST((18 * totalAmount) / 100);
              }else{}
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { value: 1, label: "Profroma" },
              { value: 2, label: "Final invoice" }
            ]}
            renderInput={(params) => <TextField {...params} label="Invoice Type *" />}
            onInputChange={(event, newInputValue) => {
             setInvoiceType(newInputValue)
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
              />
            }
            label="Create client"
          />
        </Grid>
      </Grid>
      <br />
      {checkedClient == true ? (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Company Name *"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Contact No. *"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setContact(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Address"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setClientAddress(e.target.value);
              }}
            />
          </Grid>
          <Toolbar style={{ padding: "15px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={() => {
                saveClient();
              }}
            >
              Save
            </Button>
          </Toolbar>
        </Grid>
      ) : (
        ""
      )}
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Products</h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 0 &&
                      cellIndex != 1 &&
                      cellIndex != 2 &&
                      cellIndex != 3 &&
                      cellIndex != 4
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 0) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Autocomplete
                            size="small"
                            id="combo-box-demo"
                            options={productsArray}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event, value) => {
                              if (value.label) {
                                var splArr = value.label.split(",");
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  parseInt(splArr[0])
                                );
                                updateCellValue(index, 3, value.rate);
                              }
                            }}
                          />
                        </TableCell>
                      );
                    }
                    if (cellIndex == 1) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={readingRows[index][cellIndex]}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 2) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={readingRows[index][cellIndex]}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={readingRows[index][cellIndex]}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={
                                readingRows[index][2] * readingRows[index][3]
                              }
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Conveyance Charges"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              setConveyanceCharges(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3} justify="flex-end">
          <TextField
            id="outlined-basic"
            label="Discount %"
            minvalue="100"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setTotalD((totalAmount * parseFloat(e.target.value)) / 100);
            }}
          />
        </Grid>
      </Grid>
      {invoicesData()}
      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3} justify="flex-end">
          <TextField
            id="outlined-basic"
            label="Total Amount"
            size="small"
            fullWidth
            value={totalAmount - totalD}
            InputLabelProps={{ shrink: true }}
            disabled
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {

            handleSubmit();
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            props.setLoader(true);
            handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
