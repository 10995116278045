import React, { Component } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
const axios = require("axios");

export default class CreateInstrument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disciplineId: null,
      instrumentName: null,
      standardMaster: null,
      uncertaintyFactors: null,
      c_data: null,
      calProcRefNo: null,
      status: null,
      lastModified: null,
      disciplineIdError: null,
      instrumentNameError: null,
      standardMasterError: null,
      uncertaintyFactorsError: null,
      c_dataError: null,
      uncertaintyMasterData: [],
      supportiveInstrumentsMasterData: [],
      units: [],
      finalUnitArray: [],
      statusError: null,
      lastModifiedError: null,
      disciplines: [],
      currentCandidate_disciplineId: null,
      currentCandidate_instrumentName: null,
      calibrationProcedureNo: null,
      referenceStandards: null,
      currentCandidate_standardMaster: null,
      supportiveIds: null,
      currentCandidate_uncertaintyMaster: null,
      supportiveInstrumentMasterData: null,
      currentCandidate_supportiveInstrumentMaster: null,
      currentCandidate_uncertaintyFactors: null,
      currentCandidate_c_data: null,
      currentCandidate_calProcRefNo: null,
      currentCandidate_status: null,
      currentCandidate_lastModified: null,
      standardMasterIdArray: null,
      updateModal: false,
      viewModal: false,
      option: [],
      values: null,
      checked: null,
      updateEnableDisable: false,
      setChecked: null,
      readingRows: [["", "", "", "",""]],
      readingRangeValue: [["", "", "","", ""]],
      srfObject: {},
      readingColumns: [
        [
          {
            field: "id",
            headerName: "Sr. No.",
            editable: false,
          },
          {
            field: "fromRange",
            headerName: "From range",
            editable: true,
          },
          {
            field: "toRange",
            headerName: "To range",
            editable: true,
          },
          {
            field: "CMCPLUS",
            headerName: "Lower CMC",
            editable: true,
          },
          {
            field: "CMSMINUS",
            headerName: "Higher CMC",
            editable: true,
          },
          {
            field: "Action",
          },
        ],
      ],
    };
  }
  refresh() {
    window.location.reload(false);
  }

  fetchStandardsData() {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId&_where=(pl.status,eq,1)~and(pl.type,eq,1)"
      )
      .then((res) => {
        this.setState({ standards: res.data });
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(
        url +
          "xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId&_where=(pl.status,eq,1)~and(pl.type,eq,2)"
      )
      .then((res) => {
        this.setState({ supportive: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getUnitMasterArray() {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        this.setState({ units: res.data });
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  }

  fetchUncertinityData() {
    let url = BASE_URL;
    axios
      .get(url + "uncertainty?_where=(status,eq,1)")
      .then((res) => {
        this.setState({ uncertaintyMasterData: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  supportiveInstrumentsData() {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=sp.supportiveInstruments,_j,sr.supportiveInstrumentsReadings&_on1=(sp.id,eq,sr.supportiveInstrumentId)&_fields=sp.name,sr.rangeName,sp.id,sr.id"
      )
      .then((res) => {
        console.log(res.data);
        this.setState({ supportiveInstrumentsMasterData: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchDeciplineData() {
    let url = BASE_URL;
    axios
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => {
        this.setState({ disciplines: res.data });
      })
      .catch((err) => {
        console.log("disciplines data error: ", err);
      });
  }

  onSubmit(e) {
    let url = BASE_URL;
    var jsonData = {
      disciplineId: this.state.currentCandidate_disciplineId,
      instrumentName: this.state.currentCandidate_instrumentName,
      standardMasterIds: this.state.currentCandidate_standardMaster,
      calibrationProcedureNo: this.state.calibrationProcedureNo,
      referenceStandards: this.state.referenceStandards,
      uncertaintyFactors: this.state.currentCandidate_uncertaintyMaster,
      supportiveInstrumentMasterData:
        this.state.supportiveIds,
      isGauge: this.state.updateEnableDisable,
    };

    axios
      .get(url + `standardRanges`)
      .then((res) => {
        var rangesData = res.data;
        var tempArr = [];

        var idsToArray = jsonData.standardMasterIds.split(",");

        if (idsToArray.length) {
          for (let i = 0; i < rangesData.length; i++) {
            for (let j = 0; j < idsToArray.length; j++) {
              if (
                parseInt(idsToArray[j].split(":")[0]) ==
                  rangesData[i].standardId &&
                parseInt(idsToArray[j].split(":")[1]) == rangesData[i].id
              ) {
                tempArr.push(rangesData[i].standardId + ":" + rangesData[i].id);
              }
            }
          }
        }
        jsonData.standardMasterIds = tempArr.toString();
      })

     
      .catch((error) => {
        toast.error(error);
      });
      axios
      .get(url + `standardRanges`)
      .then((res) => {
        var rangesData = res.data;
        var tempArrs = [];

        var idsToArrays = jsonData.supportiveInstrumentMasterData.split(",");

        if (idsToArrays.length) {
          for (let i = 0; i < rangesData.length; i++) {
            for (let j = 0; j < idsToArrays.length; j++) {
              if (
                parseInt(idsToArrays[j].split(":")[0]) ==
                  rangesData[i].standardId &&
                parseInt(idsToArrays[j].split(":")[1]) == rangesData[i].id
              ) {
                tempArrs.push(rangesData[i].standardId + ":" + rangesData[i].id);
              }
            }
          }
        }
        jsonData.supportiveInstrumentMasterData = tempArrs.toString();
      }).catch((error) => {
        toast.error(error);
      });
      
      // axios
      // .get(url + `supportiveInstrumentsReadings`)
      // .then((res) => {
      //   var supportiveInstrumentsReadings = res.data;
      //   var tempArr = [];

      //   var spIdsToArray = jsonData.supportiveInstrumentMasterData.split(",");

      //   if (spIdsToArray.length) {
      //     for (let i = 0; i < supportiveInstrumentsReadings.length; i++) {
      //       for (let j = 0; j < spIdsToArray.length; j++) {
      //         if (
      //           parseInt(spIdsToArray[j].split(":")[0]) ==
      //           supportiveInstrumentsReadings[i].supportiveInstrumentId
      //         ) {
      //           tempArr.push(supportiveInstrumentsReadings[i].supportiveInstrumentId + ":" + supportiveInstrumentsReadings[i].id);
      //         }
      //       }
      //     }
      //   }
      

    axios
      .post(url + "instruments", jsonData)
      .then((res) => {
        if (this.state.readingRows.length) {
          let rows = this.state.readingRows.map((row, i) => ({
            instrumentId: res.data.insertId,
            fromRange:
              this.state.readingRangeValue[i][1] != null
                ? row[1] + "," + this.state.readingRangeValue[i][1]
                : row[1],
            toRange:
              this.state.readingRangeValue[i][2] != null
                ? row[2] + "," + this.state.readingRangeValue[i][2]
                : row[2],
            lowerCmc:
              this.state.readingRangeValue[i][3] != null
                ? row[3] + "," + this.state.readingRangeValue[i][3]
                : row[3],
            higherCmc:
              this.state.readingRangeValue[i][4] != null
                ? row[4] + "," + this.state.readingRangeValue[i][4]
                : row[4],
          }));
          console.log("rows", rows);
          rows.map((rows) => {
            let url = BASE_URL;
            axios
              .post(url + "cmc", rows)
              .then((res) => {
                console.log("cmc added");
              })
              .catch((error) => {
                toast.error("Something Went Wrong!");
              });
          });
        }

        toast("new instrument added!");
        setTimeout(this.refresh, 500);
      })

      .catch((error) => {
        toast.error(error);
      });
  }

  componentDidMount() {
    this.fetchDeciplineData();
    this.fetchStandardsData();
    this.fetchUncertinityData();
    this.supportiveInstrumentsData();
    this.getUnitMasterArray();
  }

  render() {
    var emtryarray = [];
    for (var i = 0; i < this.state.disciplines.length; i++) {
      emtryarray.push({
        label: this.state.disciplines[i].name,
        id: this.state.disciplines[i].id,
      });
    }

    const updateRangeCellValue = (rowIndex, colIndex, value) => {
      const newRows = [...this.state.readingRangeValue];
      newRows[rowIndex][colIndex] = value;
      this.setState({
        readingRangeValue: [...newRows],
      });
    };

    const updateCellValue = (rowIndex, colIndex, value) => {
      const newRows = [...this.state.readingRows];
      newRows[rowIndex][colIndex] = value;
      this.setState({
        readingRows: [...newRows],
      });
    };

    const addDescriptionRow = (tableIndex) => {
      let newReadingRows = [...this.state.readingRows];
      newReadingRows.push(["", "", "","", ""]);
      this.setState({
        readingRows: [...newReadingRows],
      });

      let newReadingRowss = [...this.state.readingRangeValue];
      newReadingRowss.push(["", "", "", "",""]);
      this.setState({
        readingRangeValue: [...newReadingRowss],
      });
    };

    const deleteReadingRow = (tableIndex, rowIndex) => {
      let newReadingRows = [...this.state.readingRows];
      newReadingRows.splice(rowIndex, 1);
      this.setState({
        readingRows: [...newReadingRows],
      });
    };

    return (
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Create new instrument
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={emtryarray}
              renderInput={(params) => (
                <TextField {...params} label="Discipline *" />
              )}
              onChange={(event, value) => {
                if (value != null) {
                  const unitArr = [];
                  for (let i = 0; i < this.state.units.length; i++) {
                    if (
                      parseInt(value.id) == this.state.units[i].disciplineId
                    ) {
                      unitArr.push(this.state.units[i]);
                    }
                  }
                  this.setState({
                    finalUnitArray: unitArr,
                  });
                  this.setState({
                    currentCandidate_disciplineId: value.id,
                  });
                } else {
                  this.setState({
                    currentCandidate_disciplineId: null,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Instrument name *"
              size="small"
              fullWidth
              variant="outlined"
              value={this.state.currentCandidate_instrumentName}
              onChange={(e) => {
                this.setState({
                  currentCandidate_instrumentName: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Calibration Procedure No."
              size="small"
              fullWidth
              variant="outlined"
              value={this.state.calibrationProcedureNo}
              onChange={(e) => {
                this.setState({
                  calibrationProcedureNo: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Is Standards *"
              size="small"
              fullWidth
              variant="outlined"
              value={this.state.referenceStandards}
              onChange={(e) => {
                this.setState({
                  referenceStandards: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              multiple
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                this.state.standards != null
                  ? this.state.standards.map((item) => {
                      return {
                        id: item.pl_id,
                        standardRangesId: item.pr_id,
                        stId: item.pl_stId,
                        title: item.pl_standardName,
                        range: item.pr_rangeName,
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.title + ":" + option.range}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.stId} <b>:</b> {option.title}, {option.range}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Standard Master *"
                  placeholder="select multiple standards"
                />
              )}
              onChange={(event, value) => {
                if (value.length) {
                  var arrToStr = Array.prototype.map
                    .call(value, function (item) {
                      return item.id + ":" + item.standardRangesId;
                    })
                    .join(",");
                  this.setState({
                    currentCandidate_standardMaster: arrToStr,
                  });
                } else {
                  this.setState({
                    currentCandidate_standardMaster: null,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                this.state.uncertaintyMasterData != null
                  ? this.state.uncertaintyMasterData.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        distribution: item.distribution,
                        sensitives: item.sensitives,
                        coefficient: item.coefficient,
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.id} <b>:</b> {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Uncertainity Master"
                  placeholder="select multiple uncertainity"
                />
              )}
              onChange={(event, value) => {
                if (value.length) {
                  var arrToStr = Array.prototype.map
                    .call(value, function (item) {
                      return item.id;
                    })
                    .join(",");
                  this.setState({
                    currentCandidate_uncertaintyMaster: arrToStr,
                  });
                } else {
                  this.setState({
                    currentCandidate_uncertaintyMaster: null,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                this.state.supportive != null
                  ? this.state.supportive.map((item) => {
                      return {
                        id: item.pl_id,
                        standardRangesId: item.pr_id,
                        title: item.pl_standardName,
                        range: item.pr_rangeName,
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.title + ":" + option.range}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                   {option.title}, {option.range}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supportive Instruments *"
                  placeholder="select multiple supportive instruments"
                />
              )}
              onChange={(event, value) => {
                if (value.length) {
                  var arrToStr = Array.prototype.map
                    .call(value, function (item) {
                      return item.id + ":" + item.standardRangesId;
                    })
                    .join(",");
                  this.setState({
                    supportiveIds: arrToStr,
                  });
                } else {
                  this.setState({
                    supportiveIds: null,
                  });
                }
              }}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <Autocomplete
              multiple
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                this.state.supportiveInstrumentsMasterData != null
                  ? this.state.supportiveInstrumentsMasterData.map((item) => {
                    console.log(item)
                      return {
                        id: item.sp_id,
                        rangeName: item.sr_rangeName,
                        name: item.sp_name,
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name} <b>:</b> {option.rangeName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supportive Instruments"
                  placeholder="select multiple uncertainity"
                />
              )}
              onChange={(event, value) => {
                if (value.length) {
                  var arrToStr = Array.prototype.map
                    .call(value, function (item) {
                      return item.id;
                    })
                    .join(",");
                  this.setState({
                    currentCandidate_supportiveInstrumentMaster: arrToStr,
                  });
                } else {
                  this.setState({
                    currentCandidate_supportiveInstrumentMaster: null,
                  });
                }
              }}
            />
          </Grid> */}

          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={[
                { label: "1, Plain plug Gauge" },
                { label: "2, Width gauge" },
                { label: "3, Paddle gauge" },
                { label: "4, Flush pin" },
                { label: "5, Snap gauge" },
              ]}
              renderInput={(params) => <TextField {...params} label="Gauge" />}
              onInputChange={(e, newInputValue) => {
                this.setState({
                  updateEnableDisable: parseInt(newInputValue.split(",")[0]),
                });
              }}
            />
          </Grid>
        </Grid>
        <hr />
        <h4 style={{ "margin-bottom": "0px" }}>CMC</h4>
        <Button
          style={{ float: "right" }}
          onClick={(e) => {
            addDescriptionRow(0);
          }}
        >
          <AddIcon />
        </Button>
        <div style={{ width: "100%", overflow: "auto" }}>
          <ClassicTable>
            <Table sx={{ minWidth: 660 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {this.state.readingColumns[0].map((column) => (
                    <TableCell key={column.field}>
                      {" "}
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.readingRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {row.map((cell, cellIndex) => {
                      if (cellIndex != 0) {
                        return (
                          <TableCell>
                            <TextField
                              size="small"
                              value={cell}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            ></TextField>
                            <br />
                            <select
                              styles={{ width: "100%" }}
                              onChange={(e) => {
                                updateRangeCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            >
                              {this.state.finalUnitArray.map((option) => (
                                <option value={option.symbol}>
                                  {option.symbol}
                                </option>
                              ))}
                            </select>
                          </TableCell>
                        );
                      }
                    })}
                    <TableCell>
                      <DeleteIcon
                        style={{ color: "#dc3545" }}
                        onClick={(e) => {
                          deleteReadingRow(0, index);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ClassicTable>
        </div>

        <br />
        <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              this.onSubmit(e);
            }}
          >
            Save Instrument
          </Button>
        </Toolbar>
        <ToastContainer />
      </Paper>
    );
  }
}
