import React, { useState, useEffect, Fragment } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { FormControlLabel } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import ButtonGroup from "@mui/material/ButtonGroup"
import PreviewIcon from "@mui/icons-material/Preview"
import DeleteIcon from "@mui/icons-material/Delete"
import PrintIcon from "@mui/icons-material/Print"
import QrCode2Icon from "@mui/icons-material/QrCode2"
import { Link } from "react-router-dom"
import moment from "moment"
import DoneRoundedIcon from "@mui/icons-material/DoneRounded"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import ViewHeadline from "@mui/icons-material/ViewHeadline"
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff"
import QRCode from "react-qr-code"
import { Autocomplete } from "@mui/material"
import { BASE_URL, DOMAIN } from "./../../global"
import { Grid, TextField, Toolbar } from "@mui/material"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import { ToastContainer, toast } from "react-toastify"
import Global from "../image/global.png"
import { useReactToPrint } from "react-to-print"
import Tooltip from "@mui/material/Tooltip"
import Box from "@mui/material/Box"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"
import IconButton from "@mui/material/IconButton"

import { useTheme } from "@mui/material/styles"
import SearchIcon from "@mui/icons-material/Search"
const userType = localStorage.getItem("type")
// datatablse packages
var $ = require("jquery")
$.DataTable = require("datatables.net")

const axios = require("axios")
if (userType !== "3") {
  var columns = [
    {
      id: "checkMark",
      label: "Select",
      align: "center",
      minWidth: 10
    },
    {
      id: "enableDisableStatus",
      label: "status",
      align: "ceter",
      minWidth: 10
    },
    {
      id: "id",
      label: "Sr. No.",
      align: "left",
      minWidth: 40
    },
    {
      id: "customerName",
      label: "Customer Name",
      align: "left",
      minWidth: 40
    },
    {
      id: "instrumentName",
      label: "Instrument Name",
      align: "left",
      minWidth: 40
    },
    {
      id: "range",
      label: "Range",
      minWidth: 40,
      align: "left"
    },
    {
      id: "idNo",
      label: "Id No.",
      minWidth: 40,
      align: "left",
      format: (value) => value.toLocaleString("en-US")
    },
    {
      id: "SrNumber",
      label: "Serial Number",
      minWidth: 40,
      align: "left",
      format: (value) => value.toLocaleString("en-US")
    },
    {
      id: "dateOfCalibration",
      label: "Date of Calibration",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2)
    },
    {
      id: "dueDate",
      label: "Due Date",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2)
    },
    {
      id: "createdBy",
      label: "Created By",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2)
    }
  ]
} else {
  var columns = [
    {
      id: "id",
      label: "Sr. No.",
      align: "left",
      minWidth: 40
    },
    {
      id: "customerName",
      label: "Customer Name",
      align: "left",
      minWidth: 40
    },
    {
      id: "instrumentName",
      label: "Instrument Name",
      align: "left",
      minWidth: 40
    },
    {
      id: "range",
      label: "Range",
      minWidth: 40,
      align: "left"
    },
    {
      id: "idNo",
      label: "Id No.",
      minWidth: 40,
      align: "left",
      format: (value) => value.toLocaleString("en-US")
    },
    {
      id: "dateOfCalibration",
      label: "Date of Calibration",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2)
    },
    {
      id: "dueDate",
      label: "Due Date",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2)
    }
  ]
}

if (userType !== "3") {
  console.log("call")
  var createData = (
    checkMark,
    enableDisableStatus,
    id,
    customerName,
    workOrderNo,
    instrument,
    identificationNo,
    dateOfCalibration,
    calProcRefNo
  ) => {
    return {
      checkMark,
      enableDisableStatus,
      id,
      customerName,
      workOrderNo,
      instrument,
      identificationNo,
      dateOfCalibration,
      calProcRefNo
    }
  }
} else {
  console.log("else")
  var createData = (
    id,
    customerName,
    workOrderNo,
    instrument,
    identificationNo,
    dateOfCalibration,
    calProcRefNo
  ) => {
    return {
      id,
      customerName,
      workOrderNo,
      instrument,
      identificationNo,
      dateOfCalibration,
      calProcRefNo
    }
  }
}

const rows = [
  createData(
    2,
    5,
    1,
    "Universal lab",
    "BFI/2112/BFI/22/1/129",
    "Digital Caliper",
    55,
    "1/20/2022 12:00:00 AM",
    "Thermometer rer"
  )
]

const qrModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2
}
const stModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2
}
export default function CertificateList() {
  const printComponentRef = React.useRef()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [certificate, setCertificate] = useState([])
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [standardMasterArray, setStandardMasterArray] = React.useState([])
  const [qrCodeUrl, setQrCodeUrl] = React.useState(null)
  const [qrModalOpen, setQrModalOpen] = React.useState(false)
  const [standardOpen, setstandardOpen] = React.useState(false)
  const [enableDisable, setEnableData] = React.useState("")
  const [instrumentName, setInstrumentName] = React.useState("")
  const [idNumber, setIdNumber] = React.useState("")
  const [calDate, setCalDate] = React.useState("")
  const [dueDate, setDueDate] = React.useState("")
  const [certReadings, setCertReadings] = React.useState([])
  const [finalData, setFinalData] = React.useState([])
  const [searchBy, setSearchBy] = React.useState("")
  const [searchKey, setSearchKey] = React.useState("")
  const [totalRows, setTotalRows] = React.useState(0)
  const _clientId = localStorage.getItem("clientId")

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current
  })

  const updateEnableDisable = (value, id) => {
    const newColumnName = [...enableDisable]
    newColumnName[id] = id + "," + value
    setEnableData(newColumnName)
  }
  // console.log('dd',enableDisable)
  const handleOpen = () => setQrModalOpen(true)
  const handleClose = () => setQrModalOpen(false)
  const handleOpenStandardModal = () => setstandardOpen(true)
  const handleCloseStandardModal = () => setstandardOpen(false)
  function fetchCertificate(certificateId) {
    let url = BASE_URL
    axios
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(cer.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cer.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=cer.clientId,cer.id,cer.id,cer.instrumentId,cer.lc,cer.make,cer.model,cer.ConOfDuc,cer.ranges,cer.mfgNo,cer.deletedTableIds,cust.companyName,ins.instrumentName,ins.standardMasterIds,ins.calibrationProcedureNo,ins.referenceStandards,cust.address,cer.dcNumber,cer.calibrationDate,cer.dcDate,cer.nextDueDate,cer.receiptDate,cer.calProcRefNo,cer.mfgNo,cer.identificationNo,cer.DUCID,cer.DUCID,cer.calibrationType,cer.specificValues,cer.location,cer.serialNumber,cer.additionalStandardIds,cer.serviceReqNumber,cer.jobNumber,cer.accuracy,cer.poNumber,cer.poDate,cer.startTemp,cer.endTemp,cer.dateOfIssue,cer.model,cer.calPoint,cer.calMethod,cer.locationOfInstrument,cer.srnNo,cer.atmosphericPressure,cer.configuration,cer.workOrderNumber,cer.certificateNumber,cer.ULRNo,cer.tableDisplayStatus,disc.name&_where=(cer.id,eq, ${certificateId})`
      )
      .then((res) => {
        if (res.data[0].cer_additionalStandardIds) {
          let standardMasterIds = res.data[0].cer_additionalStandardIds

          var splitStData = standardMasterIds.split(",")
          var combineArray = []
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0]
            combineArray.push(splitByCol)
          })
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data)
            })
        } else if (res.data[0].ins_standardMasterIds) {
          var splitStData = res.data[0].ins_standardMasterIds.split(",")
          var combineArray = []
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0]
            combineArray.push(splitByCol)
          })
          console.log(combineArray.toString())
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data)
              console.log("standards", res.data)
            })
        }
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err)
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  var refresh = () => {
    window.location.reload(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // api calls
  function getTotalRows() {
    var data = {
      query: `select count(*) as totalRows from certificates`
    }
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0].totalRows)
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err)
      })
  }

  function fetchCertificates() {
    let whereConditions = ""
    let pagination_settings = ""

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Customer Name": "cust.companyName",
        "Instrument Name": "ins.instrumentName",
        DUC: "cert.DUCID",
        "Serial Number": "cert.serialNumber"
      }[searchBy]
      whereConditions = `where ${colName} like '%${searchKey}%'`
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where "
      } else {
        whereConditions += " and "
      }

      whereConditions += ` cert.calibrationDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `
    }
    if (whereConditions == "") {
      whereConditions += ` where cert.status != -1`
    } else {
      whereConditions += ` and cert.status != -1 `
    }
    console.log(whereConditions)
    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`
    }

    if (_clientId > 0) {
      var data = {
        query: `select cert.clientId,cust.companyName,ins.instrumentName,cert.id,cert.status,cert.dcNumber,cert.serialNumber,cert.calibrationDate,cert.nextDueDate,cert.identificationNo,cert.identificationNo,cert.ranges,cert.DUCID,cert.dateOfIssue from certificates as cert left join clients as cust on cert.clientId = cust.id left join instruments as ins on cert.instrumentId = ins.id  ${whereConditions} order by cert.id DESC ${pagination_settings}`
      }
    } else {
      var data = {
        query: `select cert.clientId,cust.companyName,ins.instrumentName,cert.id,cert.status,cert.dcNumber,cert.calibrationDate,cert.nextDueDate,cert.identificationNo,cert.identificationNo,cert.ranges,cert.DUCID,cert.dateOfIssue from certificates as cert left join clients as cust on cert.clientId = cust.id left join instruments as ins on cert.instrumentId = ins.id  ${whereConditions} order by cert.id DESC ${pagination_settings}`
      }
    }

    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setCertificate([...res.data])
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err)
      })
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0)
    }

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1)
    }

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page">
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page">
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page">
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page">
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    )
  }

  function changeCertificateStatus(ids) {
    var splitDataToArray = ids.substring(2).split(",")
    let url = BASE_URL
    if (ids.split(":")[0] == 3) {
      if (splitDataToArray.length) {
        splitDataToArray.forEach((id) => {
          axios
            .patch(url + `certificates/${id}`, { status: 3 })
            .then((res) => {
              toast("Certificates are approved successfully!")
              setTimeout(refresh, 200)
            })
            .catch((error) => {
              toast.error("Something Went Wrong!")
            })
        })
      }
    } else {
      if (splitDataToArray.length) {
        splitDataToArray.forEach((id) => {
          axios
            .patch(url + `certificates/${id}`, { status: 4 })
            .then((res) => {
              toast("Certificates are rejected successfully!")
              setTimeout(refresh, 500)
            })
            .catch((error) => {
              toast.error("Something Went Wrong!")
            })
        })
      }
    }
  }

  const search = () => {
    fetchCertificates()
  }

  useEffect(() => {
    getTotalRows()
  }, [])

  useEffect(() => {
    fetchCertificates()
  }, [rowsPerPage, page])

  if (enableDisable.length > 0) {
    var result = enableDisable
      .map(function (item) {
        if (item) {
          let [id, closed] = item.split(",")
          return closed === "true" ? id : null
        }
      })
      .filter(function (item) {
        return item != undefined && item != null
      })
  }

  const renderQrModal = () => {
    return (
      <Modal
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md">
        <Box sx={qrModalStyle} ref={printComponentRef}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            align="center">
            <img src={Global} width="50" alt="IMG" />
            <b>GLOBAL CALIBRATION</b>
          </Typography>
          <hr />

          <div style={{ fontWeight: "bold" }}>
            <span>Inst Name : {instrumentName}</span>
            <br />
            <span>ID No : {idNumber}</span>
            <br />
            <span>Cal. Date : {moment(calDate).format("DD-MM-YYYY")}</span>
            <br />
            <span>
              Due Date : {moment(dueDate).format("DD-MM-YYYY")}
              <div align="right">
                <QRCode value={qrCodeUrl} size={80} align="center" />
              </div>
            </span>
          </div>

          <div align="center" className="textfield">
            {/* <QRCode value={qrCodeUrl} size={80} align="center" /> */}
            <hr />
            <Button size="small" align="center" onClick={handlePrint}>
              Print
            </Button>
          </div>
        </Box>
      </Modal>
    )
  }
  const renderStandarsdModal = () => {
    return (
      <Modal
        open={standardOpen}
        onClose={handleCloseStandardModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={stModalStyle} ref={printComponentRef}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h5"
            align="center">
            <b>Master List</b>
          </Typography>
          <hr />
          <table style={{ width: "-webkit-fill-available" }}>
            <tr>
              <th style={{ textAlign: "center" }}>Name</th>
              <th style={{ textAlign: "center" }}>Actions</th>
            </tr>
            {standardMasterArray.map((instrument) => {
              const list = (
                <tr>
                  <td style={{ padding: "15px" }}>{instrument.standardName}</td>
                  <td style={{ padding: "15px", alignItems: "center" }}>
                    <Button style={{ margin: "auto", display: "block" }}>
                      <a
                        href={instrument.certificateUrl}
                        target="_blank"
                        rel="noopener noreferrer">
                        Download Cerificate
                      </a>
                    </Button>
                  </td>
                </tr>
              )
              return list
            })}
          </table>
        </Box>
      </Modal>
    )
  }

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <br />
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end"
          }}>
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2">
              <DesktopDatePicker
                label="From"
                value={from}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>

          <div style={{ marginRight: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="To"
                value={to}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </div>

          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px"
            }}
            size="small"
            id="combo-box-demo"
            options={[
              { key: "customerName", label: "Customer Name" },
              { key: "instrumentName", label: "Instrument Name" },
              { key: "duc", label: "DUC" },
              { key: "serialNumber", label: "Serial Number" }
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue)
            }}
          />
          <TextField
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search()
            }}>
            <SearchIcon />
          </Button>
        </div>
        <Grid container spacing={2} align="left" style={{ display: "flex" }}>
          {userType !== "3" ? (
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "Approve" },
                  { id: 2, label: "Reject" }
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Approve/Reject" />
                )}
                onInputChange={(event, newInputValue) => {
                  if (result) {
                    if (newInputValue === "Approve") {
                      changeCertificateStatus(3 + ":" + result.toString())
                    } else {
                      changeCertificateStatus(4 + ":" + result.toString())
                    }
                    // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
                  }
                }}
              />
            </Grid>
          ) : (
            console.log("not a admin")
          )}
          {result && result.length ? (
            <Grid item xs={3}>
              <ButtonGroup size="small" aria-label="small button group">
                <Button
                  component={Link}
                  to={`/certificate/multiqr/${result.toString()}`}>
                  <QrCode2Icon />
                </Button>
              </ButtonGroup>
            </Grid>
          ) : (
            ""
          )}
          {result && result.length ? (
            <Grid item xs={3}>
              <ButtonGroup size="small" aria-label="small button group">
                <Button
                  component={Link}
                  to={`/certificate/viewMultipleCertificate/${result.toString()}`}>
                  <PrintIcon />
                </Button>
              </ButtonGroup>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <hr />
        <br />
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certificate &&
              certificate.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {userType !== "3" ? (
                      <TableCell>
                        <Grid item xs={2}>
                          <FormControlLabel
                            classes={{
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                            sx={{
                              gap: 2
                            }}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  updateEnableDisable(e.target.checked, row.id)
                                }}
                              />
                            }
                            // label={matrixData.matrixName}
                          />
                        </Grid>
                      </TableCell>
                    ) : (
                      console.log("not a admin")
                    )}
                    {userType !== "3" ? (
                      <TableCell>
                        {row.status == 3 ? (
                          <DoneRoundedIcon color="success" />
                        ) : row.status == 4 ? (
                          <CloseRoundedIcon style={{ color: "red" }} />
                        ) : (
                          <HistoryToggleOffIcon />
                        )}
                      </TableCell>
                    ) : (
                      console.log("not a admin")
                    )}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell>{row.instrumentName}</TableCell>
                    <TableCell>{row.ranges}</TableCell>
                    <TableCell>{row.DUCID}</TableCell>
                    <TableCell>{row.serialNumber}</TableCell>
                    <TableCell>
                      {moment(row.calibrationDate).format("DD-MM-YYYY")}
                    </TableCell>
                    {/* <TableCell>
                                                {row.calProcRefNo}
                                            </TableCell> */}
                    <TableCell>
                      {moment(row.nextDueDate).format("DD-MM-YYYY")}
                    </TableCell>
                    {userType !== "3" ? (
                      <TableCell>{row.createdBy}</TableCell>
                    ) : (
                      console.log("not a admin")
                    )}
                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="View Certificate" placement="top-start">
                          <Button
                            component={Link}
                            to={`/certificate/edit/${row.id}`}>
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        {userType !== "3" ? (
                          <Tooltip
                            title="Scan Certificate"
                            placement="top-start">
                            <Button
                              onClick={(e) => {
                                setQrCodeUrl(
                                  `${DOMAIN}/#/certificate/viewCertificate/${row.id}`
                                )
                                handleOpen()
                                setInstrumentName(row.ins_instrumentName)
                                setIdNumber(row.cert_DUCID)
                                setCalDate(row.cert_calibrationDate)
                                setDueDate(row.cert_nextDueDate)
                              }}>
                              <QrCode2Icon />
                            </Button>
                          </Tooltip>
                        ) : (
                          console.log("not a admin")
                        )}

                        <Tooltip title="Masters" placement="top-start">
                          <Button
                            onClick={(e) => {
                              fetchCertificate(row.id)
                              handleOpenStandardModal()
                            }}>
                            <ViewHeadline />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page"
            },
            native: true
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </TableContainer>
      {renderQrModal()}
      {renderStandarsdModal()}
    </div>
  )
}
