//viewQuotation
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import "./viewQuotation.css";
import PdfHeader from "../../utils/components/PdfHeader";
import { Tab } from "bootstrap";
const axios = require("axios");
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "2px solid black",
    },
  },
  row: {
    height: 5,
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 100,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 250,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 40,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
});

function InstrumentTable(props) {
  let productDeatails = props.quotationProductDetails;
  let productList = props.productList;
  console.log("Product Details " + props.quotationProductDetails);
  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: 0,
    grandTotal: 0,
  };

  if (productDeatails.length > 0 && productList.length > 0) {
    for (let i = 0; i < productDeatails.length; i++) {
      const result = productList.find(
        ({ id }) => id === productDeatails[i].qt_srfInstrumentId
      );
      productDeatails[i].name = result.productName;
      productDeatails[i].amount =
        productDeatails[i].qt_rate * productDeatails[i].qt_quantity;
      amounts.totalAmountBeforeTax += productDeatails[i].amount;
    }
    amounts.conveyanceCharges = parseInt(
      productDeatails[0].qt_conveyanceCharges
    );
    if (productDeatails[0].qt_gstType == 1) {
      amounts.sgst = (amounts.totalAmountBeforeTax * 9) / 100;
      amounts.cgst = (amounts.totalAmountBeforeTax * 9) / 100;
      amounts.gst = amounts.sgst + amounts.cgst;
    } else if (productDeatails[0].qt_gstType == 2) {
      amounts.gst = (amounts.totalAmountBeforeTax * 18) / 100;
    } else {
      amounts.gst = 0;
    }

    amounts.grandTotal =
      amounts.conveyanceCharges + amounts.gst + amounts.totalAmountBeforeTax;
    console.log(productDeatails);
  }
  return (
    <Table mt={5} className={props.classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={props.classes.srCell} align="center">
            <b>Sr. No</b>
          </TableCell>
          <TableCell className={props.classes.nameCell} align="center">
            <b>Product Name </b>
          </TableCell>
          <TableCell className={props.classes.makeCell} align="center">
            <b>Discription</b>
          </TableCell>
          <TableCell className={props.classes.rangeCell} align="center">
            <b>Rate</b>
          </TableCell>
          <TableCell className={props.classes.qntCell} align="center">
            <b>Quantity</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>Amount</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productList &&
          productDeatails?.map((product, i) => (
            <TableRow className={props.classes.row}>
              <TableCell className={props.classes.srCell} align="center">
                {i + 1}
              </TableCell>
              <TableCell className={props.classes.nameCell} align="center">
                {product.name}
              </TableCell>
              <TableCell className={props.classes.makeCell} align="center">
                {product.qt_discription}
              </TableCell>
              <TableCell className={props.classes.rangeCell} align="center">
                {product.qt_rate}
              </TableCell>
              <TableCell className={props.classes.qntCell} align="center">
                {product.qt_quantity}
              </TableCell>
              <TableCell className={props.classes.rateCell} align="center">
                {product.amount}
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="center"
          >
            <b>Total Amount</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            Taxable Amount
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.totalAmountBeforeTax.toFixed(2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="center"
          >
            {productDeatails[0]?.qt_gstType === 1 ? (
              <b>Total GST</b>
            ) : (
              <b>Total GST(CGST and SGST)</b>
            )}
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            {amounts.gst.toFixed(2)}
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.gst.toFixed(2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="center"
          >
            <b>Round Off</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            ---
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>0</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={5}
            align="center"
          >
            <b>Conveyance Charges </b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b> {amounts.conveyanceCharges.toFixed(2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={5}
            align="center"
          >
            <b>Payable Amount</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.grandTotal.toFixed(2)}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default function EditQuotation() {
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  const [productList, setProductList] = React.useState([]);
  const [termsAndConditions, setTermAndCondition] = React.useState();
  const [details, setDetails] = React.useState({
    companyName: "",
    companyNumber: "",
    address: "",
    referenceNumber: "",
    quotationNumber: "",
    subject: "",
    Date: "",
    thankYouForEnquiry: "",
    termsConditions: "",
    clientId: "",
    conveyanceCharges: "",
  });
  const [quotationProductDetails, setProductDetails] = React.useState([]);
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });
  function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let termsAndCondition = null,
        settings = res.data;
      let _settingList = {};
      settings.forEach((setting) => {
        if (setting?.keyName?.toLowerCase() === "terms and condition") {
          termsAndCondition = setting.value;
        }
        _settingList[setting.keyName] = setting.value;
      });
      setTermAndCondition(termsAndCondition);
    });
  }
  const getProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "customProducts")
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  const getQuotations = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=qt.quotation,_j,client.clients&_on1=(qt.clientId,eq,client.id)&_fields=qt.id,qt.gstType,qt.productId,qt.quotationNumber,qt.referenceNumber,qt.date,qt.totalAmount,client.companyName,client.contact,client.address,client.id,qt.srfInstrumentId,qt.subject,qt.termsConditions,qt.thankYouForEnquiry,qt.conveyanceCharges,qt.rate,qt.quantity,qt.discription&_where=(qt.clientId,eq, ${params.clientId})~and(qt.quotationNumber,eq,${params.quotationNumber})`
      )
      .then((res) => {
        setProductDetails(res.data);
        let data = {
          companyName: res.data[0].client_companyName,
          companyNumber: res.data[0].client_contact,
          address: res.data[0].client_address,
          referenceNumber: res.data[0].qt_referenceNumber,
          quotationNumber: res.data[0].qt_quotationNumber,
          subject: res.data[0].qt_subject,
          Date: res.data[0].qt_date,
          thankYouForEnquiry: res.data[0].qt_thankYouForEnquiry,
          termsConditions: res.data[0].qt_termsConditions,
          clientId: res.data[0].client_id,
          conveyanceCharges: res.data[0].qt_conveyanceCharges,
          gstType: res.data[0].qt_gstType,
        };
        setDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getQuotations();
    fetchSettings();
    getProductList();
  }, []);
  return (
    <>
      <Paper sx={{ mx: 5, mt: 2, p: 2 }} style={{ border: "1px solid" }}>
        <div className="invoice" ref={printComponentRef}>
          {details && termsAndConditions ? (
            <div style={{ padding: "15px", margin: "5px", marginTop: "0px" }}>
              <div style={{ height: "150px" }}></div>
              <Grid container mt={3} style={{ marginLeft: "5%" }} spacing={2}>
                <Grid md={7} className="font-link" align="center">
                  <h5>
                    <b>QUOTATION</b>
                  </h5>
                </Grid>
                <Grid marginLeft={"5%"}>Page 01 of 1</Grid>
              </Grid>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>
                      <b>Name of Company</b>
                    </TableCell>
                    <TableCell className={classes.signCell} align="center">
                      :-
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {details.companyName}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <b> Quotation number</b>
                    </TableCell>
                    <TableCell className={classes.signCell} align="center">
                      :-
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {details.quotationNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow rowSpan={2} className={classes.row}>
                    <TableCell className={classes.cell}>
                      <b>Add of Company</b>
                    </TableCell>
                    <TableCell className={classes.signCell} align="center">
                      :-
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {details.address}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <b>Date</b>
                    </TableCell>
                    <TableCell className={classes.signCell} align="center">
                      :-
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {details.date}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>
                      <b>GST No.</b>
                    </TableCell>
                    <TableCell className={classes.signCell} align="center">
                      :-
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {details.clientId}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <b>Ref. No</b>
                    </TableCell>
                    <TableCell className={classes.signCell} align="center">
                      :-
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {details.referenceNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>
                      <b>MO</b>
                    </TableCell>
                    <TableCell className={classes.signCell} align="center">
                      :-
                    </TableCell>
                    <TableCell className={classes.cell}>---</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div style={{ textAlign: "left" }}>
                Respected Sir,
                <br></br>
                <span style={{ marginLeft: "40px" }}></span>
                {details.subject}
              </div>
              <InstrumentTable
                classes={classes}
                quotationProductDetails={quotationProductDetails}
                productList={productList}
              ></InstrumentTable>
              <div style={{ marginTop: "20px" }}>
                <div className="font-link-footer">
                  <h6>
                    <b>* Terms And Condition:-</b>
                  </h6>{" "}
                  {termsAndConditions}
                </div>
                <br></br>
                <br></br>
                <br></br>
                <h1
                  style={{
                    color: "red",
                    fontSize: "45px",
                  }}
                >
                  {" "}
                  <b>Thank You</b>
                </h1>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </Paper>

      <Toolbar style={{ padding: "0px", width: "100%" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Toolbar>
    </>
  );
}
