import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import {
  Grid,
  Paper,
  TextField,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
const axios = require("axios");

export default function CreateCourier() {
  const [srfObject, createSrfObject] = React.useState({});
  const [executing, setExecuting] = React.useState(false);
  const [client, setClient] = React.useState([]);


  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = (event) => {
    setExecuting(true);

    let row = {
      date: moment(srfObject.date).format("YYYY-MM-DD"),
      workOrderNumber: srfObject.workOrderNumber,
      clientID: srfObject.clientID,
      place: srfObject.place,
      courierPartner: srfObject.courierPartner,
      discription: srfObject.discription,
      purpose: srfObject.purpose,
      trackingNo: srfObject.trackingNo,
    };
    console.log(row);
    let url = BASE_URL;
    axios
      .post(url + "clientCourier", row)
      .then((res) => {
        toast("Client Courier created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  const getClient = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClient(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clients = [];
  for (let i = 0; i < client.length; i++) {
    clients.push({
      label: client[i].id + ", " + client[i].companyName,
    });
  }

  useEffect(() => {
    getClient();
  },[]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              value={new Date()}
              onChange={(newValue) => {
                updateSrfObject("date", new Date(newValue));
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Work Order Number"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("workOrderNumber", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={clients}
            renderInput={(params) => (
              <TextField {...params} label="Client Name" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                "clientId",
                parseInt(newInputValue.split(",")[0])
              );
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="place"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("place", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Courier Partner"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("courierPartner", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="discription"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("discription", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="purpose"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("purpose", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="trackingNo"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("trackingNo", e.target.value);
            }}
          />
        </Grid>{" "}
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          disabled={executing}
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
