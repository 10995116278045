import React, { useEffect, useState } from 'react'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  Input,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import * as XLSX from 'xlsx'
import AddIcon from '@mui/icons-material/Add'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import DeleteIcon from '@mui/icons-material/Delete'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useSnackbar } from 'notistack'
import { useReactToPrint } from 'react-to-print'
import { BASE_URL, MAX_COLS } from './../../global'
import { ClassicTable } from './../../utils/components/Styles'
import {
  prepareCertificateReadings,
  prepareDatasheetReadings,
  prepareTypeBValues,
} from './utils'

import './datasheet.css'

const axios = require('axios')

export default function EditDatasheet(props) {
  const printComponentRef = React.useRef()
  const { enqueueSnackbar } = useSnackbar()
  const { datasheetId } = useParams()

  const [units, setUnits] = useState([])
  const [precisionCount, setPrecisionCount] = useState(4)
  const [staticReadingRows, setStaticReadingRows] = useState([])
  const [certificateStaticReadings, seCertificateStaticReadings] = useState([])
  const [deletedIds, setDeletedIds] = useState([])
  const [datasheetStaticReadings, setDatasheetStaticReadings] = useState([])
  const [datasheet, setDatasheet] = useState(null)
  const [instrument, setInstrument] = useState(null)
  const [instrumentId, setInstrumentId] = useState(null)
  const [staticTables, setStaticTables] = useState(null)
  const [certificateStaticTables, setCertificateStaticTables] = useState(null)
  const [clientId, setClientId] = useState('')
  const [instrumentName, setInstrumentName] = useState('')
  const [make, setMake] = useState('')
  const [calibrationProcedureNo, setCalibrationProcedureNo] = useState('')
  const [referenceStandards, setreferenceStandards] = useState('')
  const [standardMasterArray, setStandardMasterArray] = useState([])
  const [allStandards, setAllStandards] = useState([])
  const [selectedStandards, setSelectedStandards] = useState('')
  const [lc, setLC] = useState('')
  const [dcNo, setDCNo] = useState('')
  const [dcDate, setDCDate] = useState('')
  const [ranges, setRanges] = useState('')
  const [deletedTableIds, setDeletedTableIds] = useState(null)
  const [DUCID, setDUCID] = useState([])
  const [calType, setCalType] = useState('')
  const [configuration, setConfiguration] = useState({})
  const [calibrationDate, setCalibrationDate] = useState('')
  const [nextDueDate, setNextDueDate] = useState('')
  const [receiptDate, setReceiptDate] = useState('')
  const [mfgNo, setMfgNo] = useState('')
  const [startTemp, setStartTemp] = useState('')
  const [endTemp, setEndTemp] = useState('')
  const [startHumidity, setStartHumidity] = useState('')
  const [endHumidity, setEndHumidity] = useState('')
  const [identificationNo, setIdentificationNo] = useState('')
  const [rangeDbData, setRangeData] = useState([])
  const [allRangeData, setRangeDataArray] = useState([])
  const [filterRangeData, setFilterRangeData] = useState([])
  const [specificValuesFromTable, setSpecificValuesFromTable] = useState([])
  const [location, setLocation] = useState('')
  const [accuracy, setAccuracy] = useState('')
  const [serialNo, setSerialNo] = useState('')
  const [poDate, setPoDate] = useState(null)
  const [poNo, setPoNo] = useState(null)
  const [dateOfIssue, setDateOfIssue] = useState('')
  const [models, setModel] = useState('')
  const [gaugeData, setGaugeData] = useState({})
  const [ConOfDuc, setConOfDuc] = useState('')
  const [calPoint, setCalPoint] = useState('')
  const [calMethod, setCalMethod] = useState('')
  const [locationOfInstrument, setLocationOfInstrument] = useState('')
  const [srnNo, setSrnNo] = useState('')
  const [jobNumber, setJobNumber] = useState('')
  const [atmosphericPressure, setAtmosphericPressure] = useState('')
  const [cmcReadings, setCmcReadings] = useState([])
  const [cmcReadingRows, setCmcReadingRows] = useState([])
  const [deletedColumns, setDeletedColumns] = useState({})
  const [disciplineName, setDisciplineName] = useState('')
  const [defaultReamrk, setDefaultRemark] = useState(null)
  const [excelFile, setExcelFile] = useState(null)
  const [tableDisplayStatus, setTableDisplayStatus] = useState(true)
  const [activeRowIndex, setActiveRowIndex] = useState(-1)

  // utility functions
  const getCustomColumnCount = (
    tableId,
    isStatic = null,
    isCertificate = null,
    tables = null,
  ) => {
    let tbl = tables
    if (!tbl) {
      tbl =
        isStatic === 'static'
          ? isCertificate === 'certificate'
            ? certificateStaticTables
            : staticTables
          : null
    }

    let count = 0
    if (!tbl) return count
    for (let i = 0; i < tbl.length; i++) {
      if (tbl[i].id == tableId) {
        for (let j = 0; j < MAX_COLS; j++) {
          if (tbl[i]['c' + (j + 1)] != null) {
            count++
          } else {
            break
          }
        }
      }
    }
    return count
  }

  function clearPreviousReadings(table, ids = '') {
    if (ids === '' || !ids) return null

    axios
      .delete(
        BASE_URL +
          `${table}/bulk?_ids=${
            ids.toString() + (ids.length > 0 ? ',' : '') + deletedIds.toString()
          }`,
      )
      .then((res) => {})
      .catch((err) => {
        console.error(`error - ${table} - clearPreviousReadings - `, err)
        props.setLoader(false)
      })
  }

  // api calls
  function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let remark = null,
        settings = res.data
      settings.some((setting) => {
        if (setting.keyName === 'remark') {
          remark = setting.value
        }
      })
      // updateRemark(remark);
      setDefaultRemark(remark)
    })
  }
  function fetchRangesData(instrumentId) {
    let url = BASE_URL
    axios
      .get(url + `ranges?_where=(instrumentId,eq,${parseInt(instrumentId)})`)
      .then((res) => {
        setRangeData(res.data)
      })
  }

  function fetchDatasheet() {
    let url = BASE_URL
    axios
      .get(
        url +
          `xjoin?_join=ds.datasheets,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(ds.instrumentId,eq,ins.id)&_on2=(cust.id,eq,ds.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=ds.clientId,cust.companyName,ins.instrumentName,ds.id,ds.make,ds.dcNumber,ds.dcDate,ds.lc,ds.instrumentId,ds.deletedTableIds,ins.standardMasterIds,ds.ranges,ins.calibrationProcedureNo,ins.referenceStandards,cust.address,ds.dcNumber,ds.calibrationDate,ds.dcDate,ds.nextDueDate,ds.receiptDate,ds.calProcRefNo,ds.mfgNo,ds.identificationNo,ds.startTemp,ds.endTemp,ds.additionalStandardIds,ds.DUCID,ds.calibrationType,ds.specificValues,ds.location,ds.serialNumber,ds.accuracy,ds.poNumber,ds.poDate,ds.dateOfIssue,ds.model,ds.ConOfDuc,ds.calPoint,ds.calMethod,ds.locationOfInstrument,ds.srnNo,ds.configuration,ds.atmosphericPressure,ds.tableDisplayStatus,ds.startHumidity,ds.endHumidity,disc.name&_where=(ds.id,eq, ${datasheetId})`,
      )
      // xjoin?_join=ds.datasheets,_j,si.srfInstruments&_on1=(ds.srfInstrumentId,eq,si.id)&_fields=ds.id,ins.make,si.lc&_where=(ds.id,eq, ${datasheetId})
      // xjoin?_join=ds.instruments,_j,ins.srfInstruments&_on1=(ds.srfInstrumentId,eq,ins.id)&_fields=ds.id,ins.make,ins.lc
      .then((res) => {
        if (res.data.length && res.data[0] != null) {
          setDatasheet({
            // extracting numbers
            range_low: (res.data[0]?.ds_ranges
              .split('-')[0]
              ?.match(/[\d\.]+/g) || [])[0],
            range_high: (res.data[0]?.ds_ranges
              .split('-')[1]
              ?.match(/[\d\.]+/g) || [])[0],
            lc: (res.data[0].ds_lc?.match(/[\d\.]+/g) || [])[0],
            accuracy: (res.data[0]?.ds_accuracy?.match(/[\d\.]+/g) || [])[0],
          })

          setClientId(res.data[0].cust_companyName)
          setInstrumentName(res.data[0].ins_instrumentName)
          setInstrumentId(res.data[0].ds_instrumentId)
          setMake(res.data[0].ds_make)
          setLC(res.data[0].ds_lc)
          res.data[0].ds_additionalStandardIds &&
            setSelectedStandards(res.data[0].ds_additionalStandardIds)
          setRanges(res.data[0].ds_ranges)
          setDCDate(res.data[0].ds_dcDate ? res.data[0].ds_dcDate : new Date())
          setDCNo(res.data[0].ds_dcNumber)
          setDeletedTableIds(res.data[0].ds_deletedTableIds)
          setreferenceStandards(res.data[0].ins_referenceStandards)
          setCalibrationProcedureNo(res.data[0].ins_calibrationProcedureNo)
          setCalibrationDate(
            res.data[0].ds_calibrationDate
              ? res.data[0].ds_calibrationDate
              : new Date(),
          )
          setNextDueDate(
            res.data[0].ds_nextDueDate
              ? res.data[0].ds_nextDueDate
              : new Date(),
          )
          setReceiptDate(
            res.data[0].ds_receiptDate
              ? res.data[0].ds_receiptDate
              : new Date(),
          )
          setMfgNo(res.data[0].ds_mfgNo)
          setStartTemp(res.data[0].ds_startTemp)
          setEndTemp(res.data[0].ds_endTemp)
          setStartHumidity(res.data[0].ds_startHumidity)
          setEndHumidity(res.data[0].ds_endHumidity)
          setIdentificationNo(res.data[0].ds_identificationNo)
          setDUCID(res.data[0].ds_DUCID)
          setCalType(res.data[0].ds_calibrationType)
          res.data[0].ds_specificValues
            ? setSpecificValuesFromTable(
                res.data[0].ds_specificValues.split(','),
              )
            : setSpecificValuesFromTable([])
          setLocation(res.data[0].ds_location)
          setAccuracy(res.data[0].ds_accuracy)
          setSerialNo(res.data[0].ds_serialNumber)
          setPoNo(res.data[0].ds_poNumber)
          setPoDate(res.data[0].ds_poDate)
          setDateOfIssue(res.data[0].ds_dateOfIssue)
          setModel(res.data[0].ds_model)
          setConOfDuc(res.data[0].ds_ConOfDuc)
          setCalPoint(res.data[0].ds_calPoint)
          setCalMethod(res.data[0].ds_calMethod)
          setLocationOfInstrument(res.data[0].ds_locationOfInstrument)
          setSrnNo(res.data[0].ds_srnNo)
          setConfiguration(JSON.parse(res.data[0].ds_configuration) || {})
          setAtmosphericPressure(res.data[0].ds_atmosphericPressure)
          setDisciplineName(res.data[0].disc_name)
          setTableDisplayStatus(res.data[0].ds_tableDisplayStatus)
        }

        fetchDatasheetStaticTables(
          res.data[0].ds_instrumentId,
          res.data[0].ds_deletedTableIds, //TODO: pass static tables deleted ids only
        )
        fetchCertificateStaticTables(
          res.data[0].ds_instrumentId,
          res.data[0].ds_deletedTableIds, // considered as certificated table id as both have same table ids
        )
        fetchInstrument(res.data[0].ds_instrumentId)

        fetchRangesData(res.data[0].ds_instrumentId)

        fetchCmcReadings(res.data[0].ds_instrumentId)

        if (res.data[0].ds_additionalStandardIds) {
          let standardMasterIds = res.data[0].ds_additionalStandardIds

          var splitStData = standardMasterIds.split(',')
          var combineArray = []
          splitStData.forEach(function (element) {
            var splitByCol = element.split(':')[0]
            combineArray.push(splitByCol)
          })
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data)
            })
        } else if (res.data[0].ins_standardMasterIds) {
          let standardMasterIds = res.data[0].ins_standardMasterIds

          let splitStData = standardMasterIds.split(',')
          let combineArray = []
          splitStData.forEach(function (element) {
            var splitByCol = element.split(':')[0]
            combineArray.push(splitByCol)
          })
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data)
            })
        }
      })
      .catch((err) => {
        console.error('datasheet data fetching error: ', err)
      })
  }

  function fetchDatasheetStaticTables(instrumentId, deletedTableIds) {
    let url = BASE_URL
    deletedTableIds = deletedTableIds?.split(',')?.map((id) => Number(id))
    axios
      .get(
        url + `datasheetStaticTables?_where=(instrumentId,eq,${instrumentId})`,
      )
      .then((res) => {
        if (deletedTableIds) {
          res.data = res.data.filter((row) => !deletedTableIds.includes(row.id))
        }
        setStaticTables(res.data)
      })
      .catch((err) => {
        console.error('datasheet static tables data fetching error: ', err)
      })
  }

  function fetchCertificateStaticTables(instrumentId, deletedTableIds = '') {
    let url = BASE_URL
    axios
      .get(
        url +
          `certificateStaticTables?_where=(instrumentId,eq,${instrumentId})`,
      )
      .then((res) => {
        setCertificateStaticTables(res.data)
      })
      .catch((err) => {
        console.error('certificate static tables data fetching error: ', err)
      })
  }

  function fetchInstrument(instrumentId) {
    let url = BASE_URL
    axios
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        if (res.data.length > 0) {
          setInstrument({ ...res.data[0] })
          console.log('instrument:', res.data[0])

          // extract standards
          let rawStandardIds = res.data[0]?.standardMasterIds || ""
          let rawSupportiveInstrumentMasterData = res.data[0]?.supportiveInstrumentMasterData || ""
          let standardIds = rawStandardIds.split(",").map(value => value.split(":")[0])
          let supportiveStandardIds = rawSupportiveInstrumentMasterData.split(",").map(value => value.split(":")[0])
          if(supportiveStandardIds.length > 0)
            standardIds.push(...supportiveStandardIds)
          if(standardIds.length > 0)
            fetchStandards(standardIds.join(","))

        }
      })
      .catch((err) => {
        console.error('Instrument data fetching error: ', err)
      })
  }

  function fetchStaticReadingRows() {
    let url = BASE_URL

    // for static datasheet
    axios
      .get(
        url + `datasheetStaticReadings?_where=(datasheetId,eq,${datasheetId})`,
      )
      .then((res) => {
        setDatasheetStaticReadings(res.data)
      })
      .catch((err) => {
        console.error('static datasheet tables reading fetching error: ', err)
      })

    // for static certificate
    axios
      .get(
        url +
          `certificateStaticReadings?_where=(certificateId,eq,${datasheetId})`, // daaseetId would be same as certificateId
      )
      .then((res) => {
        seCertificateStaticReadings(res.data)
      })
      .catch((err) => {
        console.error('static certificate tables reading fetching error: ', err)
      })
  }

  // simplify this function
  function fetchOtherStaticReadingRows(tableId) {
    axios
      .get(
        BASE_URL +
          `datasheets?_where=(instrumentId,eq,${instrumentId})~and(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(model,eq,${models})~or((instrumentId,eq,${instrumentId})~and(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(ranges,eq,${ranges})~and(lc,eq,${lc}))`,
      )
      .then((res) => {
        let datasheetIds = res.data?.map((row) => row.id)
        if (datasheetIds?.length > 0) {
          let _datasheetId =
            datasheetIds[Math.floor(Math.random() * (datasheetIds.length - 1))]
          axios
            .get(
              BASE_URL +
                `datasheetStaticReadings?_where=(tableId,eq,${tableId})~and(datasheetId,in,${_datasheetId})`,
            )
            .then((res) => {
              let data = res.data.map((row) => ({
                ...row,
                id: 0,
                tableId: tableId,
                datasheetId: datasheetId,
              }))
              setDatasheetStaticReadings((current) => [...data])
            })
            .catch((err) => {
              console.error(
                'other datasheetStaticReadings fetching error: ',
                err,
              )
            })
        }
      })
      .catch((err) => {
        console.error('fetchOtherStaticReadingRows error: ', err)
      })
  }

  function fetchStandards(standardIds = "") {
    axios
      .get(
        BASE_URL +
          `xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId&_where=(pl.status,eq,1)~and(pl.id,in,${standardIds})`,
      )
      .then((res) => {
        setAllStandards(res.data)
      })
      .catch((err) => {
        console.error('standards data fetching error: ', err)
      })
  }

  function fetchUnits() {
    axios
      .get(BASE_URL + 'unit?_size=100')
      .then((res) => {
        let units = res.data.reduce((prev, current) => {
          return [...prev, current.symbol]
        }, [])
        setUnits(units)
      })
      .catch((err) => {
        console.error('unit fetching error: ', err)
      })
  }

  function fetchCmcReadings(instrumentId) {
    axios
      .get(BASE_URL + `cmc?_where=(instrumentId,eq,${instrumentId})`)
      .then((res) => {
        setCmcReadings(res.data)
      })
      .catch((error) => {
        console.error('Something Went Wrong while fetching standardRanges!')
      })
  }

  function submitDatasheet() {
    // prepare data
    let data = {
      calibrationDate: moment(calibrationDate).format('YYYY-MM-DD'),
      nextDueDate: moment(nextDueDate).format('YYYY-MM-DD'),
      receiptDate: moment(receiptDate).format('YYYY-MM-DD'),
      DCDate: moment(dcDate).format('YYYY-MM-DD'),
      identificationNo: identificationNo,
      dateOfIssue: dateOfIssue
        ? moment(dateOfIssue).format('YYYY-MM-DD')
        : null,
      make: make,
      ranges: ranges,
      lc: lc,
      DUCID: DUCID,
      mfgNo: mfgNo,
      startTemp: startTemp,
      endTemp: endTemp,
      startHumidity: startHumidity,
      endHumidity: endHumidity,
      configuration: JSON.stringify(configuration),
      totalReadings: staticReadingRows?.length || 0,
      tableDisplayStatus: tableDisplayStatus,
    }
    if (filterRangeData.length) {
      delete filterRangeData.splice(filterRangeData.length - 2, 1)
      data.specificValues = filterRangeData.toString()
    }
    // patch req
    axios
      .patch(BASE_URL + `datasheets/${datasheetId}`, data)
      .then((res) => {
        submitCertificate()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  function submitCertificate() {
    let data = {
      calibrationDate: moment(calibrationDate).format('YYYY-MM-DD'),
      nextDueDate: moment(nextDueDate).format('YYYY-MM-DD'),
      receiptDate: moment(receiptDate).format('YYYY-MM-DD'),
      DCDate: moment(dcDate).format('YYYY-MM-DD'),
      additionalStandardIds: selectedStandards,
      identificationNo: identificationNo,
      dateOfIssue: dateOfIssue
        ? moment(dateOfIssue).format('YYYY-MM-DD')
        : null,
      make: make,
      ranges: ranges,
      lc: lc,
      DUCID: DUCID,
      mfgNo: mfgNo,
      startTemp: startTemp,
      endTemp: endTemp,
      tempDiff: String(
        Math.abs(Number(startTemp) - Number(endTemp)).toFixed(2),
      ),
      startHumidity: startHumidity,
      endHumidity: endHumidity,
      humDiff: String(
        Math.abs(Number(startHumidity) - Number(endHumidity)).toFixed(2),
      ),
      configuration: JSON.stringify(configuration),
      tableDisplayStatus: tableDisplayStatus,
    }

    if (filterRangeData.length) {
      // delete filterRangeData.splice(filterRangeData.length - 2, 1)
      data.specificValues = filterRangeData.toString()
    }

    // patch req
    axios
      .patch(BASE_URL + `certificates/${datasheetId}`, data)
      .then((res) => {
        createAutomatedTemplates()
      })
      .catch((err) => {
        console.error(err)
        props.setLoader(false)
      })
  }

  const createDatasheetTable = async (table) => {
    // remove unwanted
    let data = { ...table }
    delete data.id
    delete data?.lastModified
    table.isUncertainty = false
    table.isAutomated = 1
    let res = await axios.post(BASE_URL + `datasheetStaticTables`, data)
    return res.data.insertId
  }
  const createCertificateTable = async (table) => {
    // add necessary

    // remove unwanted
    let data = { ...table }
    delete data.id
    delete data?.lastModified

    // hide uncertainty by default
    table.isUncertainty = false
    table.isAutomated = 1
    let res = await axios.post(BASE_URL + `certificateStaticTables`, data)
    return res.data.insertId
  }

  const createAutomatedTemplates = async () => {
    // tmp table id mappings
    let tableIdMappings = {}
    let _staticTables = [...staticTables]

    for (let i = 0; i < _staticTables.length; i++) {
      let table = _staticTables[i]
      if (String(table.id).includes('tmp')) {
        let tmpTableId = table.id
        const [dsTableId, certTableId] = await Promise.all([
          createDatasheetTable(table),
          createCertificateTable(table),
        ])
        if (dsTableId === certTableId) {
          tableIdMappings[tmpTableId] = dsTableId
          _staticTables[i].id = dsTableId
        }
      }
    }

    // replace all tmp table ids with actual ids
    let updatedReadingRows = staticReadingRows.map((row) => {
      if (String(row[0])?.includes('tmp')) row[0] = tableIdMappings[row[0]]
      return row
    })

    submitStaticDatasheetReadings(updatedReadingRows, _staticTables)
  }

  const submitStaticDatasheetReadings = async (
    updatedReadingRows,
    staticTables,
  ) => {
    // prepare readings
    let config = staticTables.map((table) => {
      let defaultConfiguration = JSON.parse(table.defaultConfiguration)
      return {
        tableId: table.id,
        formulas: defaultConfiguration.formulas,
      }
    })
    updatedReadingRows = prepareDatasheetReadings({
      readings: updatedReadingRows,
      config: config,
      precisionCount: precisionCount,
      datasheet: datasheet,
    })

    // create JSON array of readings
    let readings = []
    let datasheetReadingIds = []
    for (const updatedReadingRow of updatedReadingRows) {
      let row = {}
      row['tableId'] = updatedReadingRow[0]
      row['datasheetId'] = updatedReadingRow[1]
      let base = 2

      let customColumnCount = getCustomColumnCount(
        updatedReadingRow[0],
        'static',
      )
      for (let j = 0; j < customColumnCount; j++) {
        row[`c${j + 1}`] = updatedReadingRow[base + j]
          ? updatedReadingRow[base + j]
          : 'null'
      }

      for (let j = customColumnCount; j < MAX_COLS; j++) {
        row[`c${j + 1}`] = null
      }

      // set uncertainty unit only
      if (
        String(updatedReadingRow[base + customColumnCount]).includes(
          '_unit_',
        )
      ) {
        row['uncertainty'] = updatedReadingRow[base + customColumnCount]
      } else {
        row['uncertainty'] =
          updatedReadingRow[base + customColumnCount] || 'null'
        row['uncertainty'] = String(row['uncertainty'])?.replaceAll('$', '')
      }

      // set standard range ids
      row["standardRanges"] = updatedReadingRow[base + customColumnCount + 1]

      // resolve typeB values
      let table = staticTables.find((table) => table.id == row['tableId'])
      let defaultConfiguration = JSON.parse(table.defaultConfiguration)

      if (defaultConfiguration?.typeb?.relations) {
        row['typeBValues'] = prepareTypeBValues(
          defaultConfiguration.typeb?.relations,
          row,
        )
      }

      updatedReadingRow[updatedReadingRow.length - 1] != 0 &&
        datasheetReadingIds.push(
          updatedReadingRow[updatedReadingRow.length - 1],
        )

      readings.push(row)
    }

    // clear previous readings
    if (datasheetReadingIds?.length > 0)
      await clearPreviousReadings(
        'datasheetStaticReadings',
        datasheetReadingIds,
      )
    // update datasheet readings
    axios
      .post(BASE_URL + 'datasheetStaticReadings/bulk', readings)
      .then((res) => {
        submitStaticCertificateReadings(
          updatedReadingRows,
          readings,
          staticTables,
        )
      })
      .catch((err) => {
        console.error(err)
        props.setLoader(false)
      })
  }

  const submitStaticCertificateReadings = async (
    updatedReadingRows,
    dsReadings,
    staticTables,
  ) => {
    // sync automated tables with datasheet
    let _certificateStaticTables = [...certificateStaticTables]
    staticTables.forEach((table) => {
      if (
        !_certificateStaticTables.some((certTable) => certTable.id == table.id)
      ) {
        _certificateStaticTables.push(table)
      }
    })

    // prepare readings
    let config = _certificateStaticTables.map((table) => {
      let defaultConfiguration = JSON.parse(table.defaultConfiguration)
      return {
        tableId: table.id,
        formulas: defaultConfiguration.formulas,
        relations: defaultConfiguration.relations,
        totalColumns: defaultConfiguration.totalColumns,
      }
    })
    updatedReadingRows = prepareCertificateReadings({
      datasheetReadings: updatedReadingRows,
      config: config,
      precisionCount: precisionCount,
    })

    // create json array of readings
    let readings = []
    let certificateReadingIds = []
    for (let i = 0; i < updatedReadingRows?.length; i++) {
      let row = {}
      row['tableId'] = updatedReadingRows[i][0]
      row['certificateId'] = Number(updatedReadingRows[i][1])
      let base = 2

      let customColumnCount = getCustomColumnCount(
        updatedReadingRows[i][0],
        'static',
        'certificate',
        _certificateStaticTables,
      )

      for (let j = 0; j < customColumnCount; j++) {
        row[`c${j + 1}`] = updatedReadingRows[i][base + j]
          ? updatedReadingRows[i][base + j]
          : 'null'
      }

      for (let j = customColumnCount; j < MAX_COLS; j++) {
        row[`c${j + 1}`] = null
      }

      updatedReadingRows[i][updatedReadingRows[i].length - 1] != 0 &&
        certificateReadingIds.push(
          updatedReadingRows[i][updatedReadingRows[i].length - 1],
        )

      row['uncertainty'] = dsReadings[i]['uncertainty']
      readings.push(row)
    }

    // clear previous readings
    certificateReadingIds = certificateStaticReadings.map((row) => row.id)
    // clear previous readings
    if (certificateReadingIds?.length > 0)
      await clearPreviousReadings(
        'certificateStaticReadings',
        certificateReadingIds,
      )
    axios
      .post(BASE_URL + 'certificateStaticReadings/bulk', readings)
      .then((res) => {
        enqueueSnackbar('Datasheet updated successfully!', {
          variant: 'success',
        })
        props.setLoader(false)
      })
      .catch((err) => {
        console.error(err)
        props.setLoader(false)
      })
  }

  const initiateCmcReadingRows = () => {
    let rows = []
    let rowsSpace = []
    for (let i = 0; i < cmcReadings.length; i++) {
      rowsSpace.push([null, null, null, null])
      rows.push([
        cmcReadings[i].id,
        cmcReadings[i].ranges,
        cmcReadings[i].lowerCmc,
        cmcReadings[i].higherCmc,
      ])

      setCmcReadingRows(rows)
      // setCmcReadingRangeValue(rowsSpace);
    }
  }

  const initiateStaticReadingRows = () => {
    if (!(datasheetStaticReadings && staticTables)) return null
    let rows = {}
    datasheetStaticReadings.forEach((reading, index) => {
      let row = []
      row.push(reading.tableId)
      row.push(reading.datasheetId)

      for (let i = 0; i < MAX_COLS; i++) {
        // check for default value
        if (reading['c' + (i + 1)] != null) {
          row.push(
            reading['c' + (i + 1)] === 'NA' ? '' : reading['c' + (i + 1)],
          )
        } else {
          break
        }
      }
      row.push('$' + reading?.uncertainty)
      row.push(reading.standardRanges)
      row.push(reading.id)
      if (rows[reading.tableId]?.length > 0) {
        rows[reading.tableId] = [...rows[reading.tableId], row]
      } else {
        rows[reading.tableId] = [row]
      }
    })

    // set default values
    staticTables?.forEach((table) => {
      let defaultConfiguration = JSON.parse(table.defaultConfiguration)
      let defaultValues = defaultConfiguration['values']
      let totalColumns = Number(defaultConfiguration['totalColumns'])
      let _rows = rows[[table.id]] ? rows[[table.id]] : []

      for (let i = 0; i < totalColumns; i++) {
        let dv = defaultValues['c' + (i + 1)]?.split(',')
        for (let j = 0; j < dv?.length; j++) {
          if (!_rows?.length || _rows.length <= j) {
            _rows.push(Array(totalColumns + 4).fill(''))
            _rows[j][0] = table.id
            _rows[j][1] = datasheetId
            _rows[j][totalColumns + 4 - 1] = 0 // adding 0 row index for new records
          }
          _rows[j][i + 2] = '$' + dv[j]
        }
      }

      rows[table.id] = _rows
    })

    let allRows = []
    for (const tableId in rows) {
      if (rows[tableId]) allRows.push(...rows[tableId])
    }
    setStaticReadingRows([...allRows])
  }

  const getTableStructure = (tmpTableId) => {
    return {
      id: tmpTableId,
      instrumentId: instrumentId,
      tableName: '',
      // add columnd e.g: c1: null,
      defaultConfiguration: {
        values: {},
        formulas: {},
        totalColumns: null,
        hasCommonHeader: false,
        commonHeaderConfig: null,
        typeb: {
          values: {},
          formulas: {},
          totalColumns: 0,
          hasCommonHeader: false,
          commonHeaderConfig: null,
          typeb: { relations: {} },
        },
      },
      conditionFormatting: '{"relations":{}}',
      isUncertainty: 0,
      status: 1,
      lastModified: '2022-12-30T10:59:30.000Z',
    }
  }

  const processAutomatedRows = (allRows) => {
    console.log('all rows', allRows)
    let tmpTables = []
    let tmpReadings = []

    let table = getTableStructure(`tmp${tmpTables?.length + 1}`)
    let reading = []

    allRows.forEach((row, index) => {
      // is table name
      if (row[0] === 'table name') {
        table.tableName = row[1]
        // add new tmp table
      } else if (row[0] === 'columns') {
        if (index + 1 < allRows.length && allRows[index + 1][0] === 'columns') {
          // 1. I am common header!
          // common header format: "$:1, Standard Instrument Reading :3,$:1,$:1,$:1$:1"
          let commonHeader = []
          let columnName = '$'
          let length = 0
          for (let i = 1; i < allRows[index + 1].length; i++) {
            if (allRows[index + 1][i] == '') {
              // column after last column
              if (length > 0) {
                commonHeader.push(columnName + ':' + length)
              }
              break
            }
            if (row[i] !== '') {
              if (length > 0) {
                commonHeader.push(columnName + ':' + length)
              }
              // reset column name and length
              columnName = row[i]
              length = 1
            }
            if (row[i] === '') length += 1

            // if last column
            if (i + 1 === allRows[index + 1].length) {
              if (length > 0) {
                commonHeader.push(columnName + ':' + length)
              }
            }
          }
          table.defaultConfiguration.hasCommonHeader = true
          table.defaultConfiguration.commonHeaderConfig = commonHeader.join(',')
        } else {
          for (let i = 1; i < row.length; i++) {
            if (row[i] == '') break
            table['c' + i] = row[i]

            if (i + 1 == row.length || row[i + 1] == '') {
              table.defaultConfiguration.totalColumns = i
            }
          }
        }
      } else if (row[0] === 'units') {
        // add unit row
        reading = {}
        reading['tableId'] = table.id
        reading['datasheetId'] = datasheetId

        let length = table.defaultConfiguration.totalColumns + 1
        for (let i = 1; i < length; i++) {
          reading['c' + i] = '_unit_' + row[i]
        }
        reading['uncertainty'] = '_unit_' // for uncertainty
        tmpReadings.push(reading)
      } else if (row[0] === 'row header') {
        // add row header
        reading = {}
        reading['tableId'] = table.id
        reading['datasheetId'] = datasheetId

        let length = table.defaultConfiguration.totalColumns + 1
        for (let i = 1; i < length; i++) {
          reading['c' + i] = '_rh_' + row[i]
        }
        reading['uncertainty'] = '_rh_' // for uncertainty
        tmpReadings.push(reading)
      } else {
        // only process non empty row else skip
        if (row.some((val) => val !== '')) {
          reading = {}
          reading['tableId'] = table.id
          reading['datasheetId'] = datasheetId
          let relations = {}
          let length = table.defaultConfiguration.totalColumns + 1
          for (let i = 1; i < length; i++) {
            reading['c' + i] = String(row[i])
            relations['c' + i] = 'c' + i
          }
          reading['uncertainty'] = 'null' // for uncertainty
          tmpReadings.push(reading)

          table.defaultConfiguration.relations = relations
        }
      }

      // what is next?
      // 1. if new table
      if (
        index + 1 < allRows.length &&
        allRows[index + 1][0] === 'table name'
      ) {
        table.defaultConfiguration = JSON.stringify(table.defaultConfiguration)
        table.isAutomated = 1
        tmpTables.push(table)
        table = getTableStructure(`tmp${tmpTables?.length + 1}`)
      }

      // is end of rows
      if (index + 1 == allRows.length) {
        // 1. add table
        table.defaultConfiguration = JSON.stringify(table.defaultConfiguration)
        table.isAutomated = 1
        tmpTables.push(table)
      }
    })

    setStaticTables([...staticTables, ...tmpTables])
    setDatasheetStaticReadings([...datasheetStaticReadings, ...tmpReadings])
  }

  const processExcelFile = () => {
    if (!excelFile) return

    const reader = new FileReader()
    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
      let rows = data.split('\n')
      if (rows.length > 2) rows.shift()
      let finalRows = []
      rows.forEach((row) => {
        finalRows.push(row.split(','))
      })
      processAutomatedRows(finalRows)
    }
    reader.readAsBinaryString(excelFile)
  }

  const addStaticReadingRow = (
    tableIndex,
    tableId,
    rowIndex = null,
    type = null,
  ) => {
    let newReadingRows = [...staticReadingRows]
    let totalColumns = getCustomColumnCount(tableId, 'static') + 1 // for custom + uncertainty

    let row = [tableId, datasheetId].concat(Array(totalColumns).fill(''))
    row.push(null) // standard range
    row.push(0)

    if (type === 'copy') {
      row = [...staticReadingRows[rowIndex]]
    }

    newReadingRows.push(row)
    setStaticReadingRows([...newReadingRows])
  }

  const addStaticUnitRow = (tableIndex, tableId) => {
    let newReadingRows = [...staticReadingRows]
    let totalColumns = getCustomColumnCount(tableId, 'static') + 1 // for custom + uncertainty

    let row = [tableId, datasheetId].concat(
      Array(totalColumns).fill(units ? '_unit_' + units[0] : ''),
    )
    row.push(null) // standard range
    row.push(0)
    newReadingRows.push(row)
    setStaticReadingRows([...newReadingRows])
  }

  const addStaticRowHeader = (tableIndex, tableId) => {
    let newReadingRows = [...staticReadingRows]
    let totalColumns = getCustomColumnCount(tableId, 'static') + 1 // for custom + uncertainty

    let row = [tableId, datasheetId].concat(Array(totalColumns).fill('_rh_')) //_rh_ : row header
    row.push(null) // standard range
    row.push(0)
    newReadingRows.push(row)
    setStaticReadingRows([...newReadingRows])
  }

  const deleteStaticReadingRow = (rowIndex) => {
    let newReadingRows = [...staticReadingRows]

    setDeletedIds([
      ...deletedIds,
      newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1],
    ])

    newReadingRows.splice(rowIndex, 1)
    setStaticReadingRows([...newReadingRows])
  }

  const updateStaticCellValue = (rowIndex, cellIndex, value) => {
    let newReadingRows = [...staticReadingRows]
    newReadingRows[rowIndex][cellIndex + 2] = value
    setStaticReadingRows([...newReadingRows])
    // setActiveCell(value)
    // setActiveRow(rowIndex)
    // setActiveCellIndex(cellIndex)
  }

  const updateStandardRangesOfRow = (value) =>{
    let newReadingRows = [...staticReadingRows]
    // TODO: correct below logic, as it is highly coupled to index which it should not at all !!!
    newReadingRows[activeRowIndex][newReadingRows[activeRowIndex].length - 2] = value
    setStaticReadingRows([...newReadingRows]) 
  }

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  })

  const deleteTable = (tableId) => {
    // append into previoud ids
    let data = {
      deletedTableIds: (deletedTableIds ? deletedTableIds + ',' : '') + tableId,
    }
    axios
      .patch(BASE_URL + `datasheets/${datasheetId}`, data)
      .then((res) => {})
      .catch((err) => {
        console.error(err)
      })
    axios
      .patch(BASE_URL + `certificates/${datasheetId}`, data)
      .then((res) => {
        window.location.reload(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const initiateRangesList = () => {
    let rangeDataArray = []
    for (let i = 0; i < rangeDbData.length; i++) {
      rangeDataArray.push(
        rangeDbData[i].specificPoint1,
        rangeDbData[i].specificPoint2,
        rangeDbData[i].specificPoint3,
        rangeDbData[i].specificPoint4,
        rangeDbData[i].specificPoint5,
        rangeDbData[i].specificPoint6,
        rangeDbData[i].specificPoint7,
        rangeDbData[i].specificPoint8,
      )
    }
    var filtered = rangeDataArray.filter(function (el) {
      return el != null && el != ''
    })
    setRangeDataArray(filtered)
  }

  const prepareGaugeTable = () => {
    // calc. T
    let a = gaugeData?.size + gaugeData?.ht
    let b = gaugeData?.size + gaugeData?.lt
    let [g, k] = a > b ? [a, b] : [b, a]
    g = Number(g.toFixed(4))
    k = Number(k.toFixed(4))

    let t = Number((g - k).toFixed(3))

    let instrumentId = instrument?.isGauge == 5 ? 2 : 1

    axios
      .get(
        BASE_URL +
          `gauges?_where=(nominalSizeUpto,gte,${gaugeData?.size})~and(t,gte,${
            t * 1000
          })~and(instrumentId,eq,${instrumentId})`,
      )
      .then((res) => {
        if (res.data?.length <= 0) return
        // calculate values for go, no go, wear limit
        let data = res.data[0] // for largest T
        data['Z'] = data.Z / 1000
        data['H2'] = data.H2 / 1000
        data['Y'] = data.Y / 1000
        data['alpha'] = data.alpha / 1000

        let specifiedSize = { go: '', nogo: '', wl: null }
        let go1, go2, nogo1, nogo2

        // for plain gauge
        if (instrument?.isGauge != 5) {
          if (gaugeData.size <= 180) {
            // less than 180
            go1 = (k + data.Z + data.H2).toFixed(4)
            go2 = (k + data.Z - data.H2).toFixed(4)
            ;[go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1]
            specifiedSize.go = go1 + ' / ' + go2

            nogo1 = (g + data.H2).toFixed(4)
            nogo2 = (g - data.H2).toFixed(4)
            ;[nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1]
            specifiedSize.nogo = nogo1 + ' / ' + nogo2

            specifiedSize.wl = (k - data.Y).toFixed(4)
          } else {
            // more than 180
            go1 = (k + data.Z + data.H2).toFixed(4)
            go2 = (k + data.Z - data.H2).toFixed(4)
            ;[go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1]
            specifiedSize.go = go1 + ' / ' + go2

            nogo1 = (g - data.alpha + data.H2).toFixed(4)
            nogo2 = (g - data.alpha - data.H2).toFixed(4)
            ;[nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1]
            specifiedSize.nogo = nogo1 + ' / ' + nogo2

            specifiedSize.wl = (k - data.Y + data.alpha).toFixed(4)
          }
        } else {
          // for snap gauge
          if (gaugeData.size <= 180) {
            // less than 180
            go1 = (g - data.Z + data.H2).toFixed(4)
            go2 = (g - data.Z - data.H2).toFixed(4)
            ;[go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1]
            specifiedSize.go = go1 + ' / ' + go2

            nogo1 = (k + data.H2).toFixed(4)
            nogo2 = (k - data.H2).toFixed(4)
            ;[nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1]
            specifiedSize.nogo = nogo1 + ' / ' + nogo2

            specifiedSize.wl = (g + data.Y).toFixed(4)
          } else {
            // more  than 180
            go1 = (g - data.Z + data.H2).toFixed(4)
            go2 = (g - data.Z - data.H2).toFixed(4)
            ;[go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1]
            specifiedSize.go = go1 + ' / ' + go2

            nogo1 = (k + data.alpha + data.H2).toFixed(4)
            nogo2 = (k + data.alpha - data.H2).toFixed(4)
            ;[nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1]
            specifiedSize.nogo = nogo1 + ' / ' + nogo2

            specifiedSize.wl = (g + data.Y - data.alpha).toFixed(4)
          }
        }

        specifiedSize = [specifiedSize.go, specifiedSize.nogo, specifiedSize.wl]

        let _staticReadingRows = staticReadingRows
        specifiedSize.forEach((val, index) => {
          _staticReadingRows[index][3] = val
        })
        setStaticReadingRows([..._staticReadingRows])

        setGaugeData({
          ...gaugeData,
          isPrepared: true,
          g: g,
          k: k,
          t: t,
          h2: data.H2,
          alpha: data.alpha,
          z: data.Z,
          y: data.Y,
        })
      })
  }

  useEffect(() => {
    initiateCmcReadingRows()
  }, [cmcReadings])

  useEffect(() => {
    initiateRangesList()
  }, [rangeDbData])

  useEffect(() => {
    initiateStaticReadingRows()
  }, [datasheetStaticReadings, staticTables])

  useEffect(() => {
    fetchDatasheet()
    fetchStaticReadingRows()
    fetchUnits()
    fetchSettings()
  }, [])

  const renderRangeData = () => {
    return allRangeData.map((rdata, index) => {
      return (
        <Grid item xs={2} key={index}>
          <TextField
            id="outlined-basic"
            label={rdata}
            value={specificValuesFromTable[index]}
            InputLabelProps={{ shrink: true }}
            // onChange={(e) =>
            //     updateSpecificDataName(e.target.value, index)
            // }
            onChange={(e) => {
              setFilterRangeData([
                ...specificValuesFromTable,
                (specificValuesFromTable[index] = e.target.value),
              ])
            }}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
      )
    })
  }

  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null
    defaultConfiguration = JSON.parse(defaultConfiguration)
    let hasCommonHeader = defaultConfiguration.hasCommonHeader
    if (hasCommonHeader && defaultConfiguration.commonHeaderConfig) {
      let commonHeaderConfig = defaultConfiguration.commonHeaderConfig?.split(
        ',',
      )
      let cols = []
      commonHeaderConfig.forEach((col) => {
        let [name, size] = col?.split(':')
        let classNames =
          'header-border ' + (name != '$' ? 'header-border-bottom' : '')
        cols.push(
          <TableCell
            className={classNames}
            align="center"
            style={{ textAlign: 'center' }}
            colSpan={Number(size)}
          >
            {name != '$' ? name : ''}
          </TableCell>,
        )
      })

      return (
        <TableRow>
          {/* for SN */}
          <TableCell className="header-border" align="center"></TableCell>
          <TableCell className="header-border" align="center"></TableCell>
          {cols}
        </TableRow>
      )
    }
    return null
  }

  const renderUnit = (value, onChange) => {
    return (
      <select name="units" id="units" onChange={onChange}>
        <option value={''} key={''} selected>
          {''}
        </option>
        {units?.map((unit) => {
          if (unit == value) {
            return (
              <option value={unit} key={unit} selected>
                {unit}
              </option>
            )
          } else {
            return (
              <option value={unit} key={unit}>
                {unit}
              </option>
            )
          }
        })}
      </select>
    )
  }

  const getConditionalFormattedColor = (value, cellIndex, table) => {
    let { relations } = JSON.parse(table?.conditionalFormatting || '{}')
    if (relations && 'c' + cellIndex in relations) {
      let config = relations['c' + cellIndex]
      if (config) {
        if (config.dsCol == 4) {
          if (
            config.condition?.toLowerCase() == 'lt' &&
            value < datasheet.accuracy
          ) {
            return config.color
          } else if (
            config.condition?.toLowerCase() == 'gt' &&
            value > datasheet.accuracy
          ) {
            return config.color
          }
        }
        if (config.dsCol == 3) {
          if (config.condition?.toLowerCase() == 'lt' && value < datasheet.lc) {
            return config.color
          } else if (
            config.condition?.toLowerCase() == 'gt' &&
            value > datasheet.lc
          ) {
            return config.color
          }
        }
      }
    } else {
      return ''
    }
  }

  const renderTableCell = (
    table,
    row,
    rowIndex,
    cell,
    cellIndex,
    onChange = {},
  ) => {
    let field = null

    if (deletedColumns[table.id]?.includes('c' + (cellIndex + 2))) return null

    if (String(cell)?.includes('_unit_')) {
      if (table['c' + (cellIndex + 1)]?.toLowerCase()?.match(/m+\d+/g)) {
        field = <div></div>
      } else {
        let value = cell.split('_unit_')[1]
        field = renderUnit(value, (e) => {
          updateStaticCellValue(rowIndex, cellIndex, '_unit_' + e.target.value)
        })
      }
    } else if (cell && cell[0] == '$') {
      field = (
        <span>
          {!isNaN(cell.slice(1))
            ? Number(cell.slice(1)).toFixed(4)
            : cell.slice(1)}
        </span>
      )
    } else if (cell == 'null') {
      field = (
        <TextField
          size="small"
          value={''}
          InputProps={{
            style: {
              fontSize: '12px',
              margin: '0px',
              padding: '0px',
            },
          }}
          onChange={(e) => {
            updateStaticCellValue(rowIndex, cellIndex, e.target.value)
          }}
          variant="standard"
        ></TextField>
      )
    } else {
      field = (
        <TextField
          size="small"
          sx={{
            input: {
              color: getConditionalFormattedColor(
                row[cellIndex + 2],
                cellIndex + 2,
                table,
              ),
            },
          }}
          value={row[cellIndex + 2]}
          InputProps={{
            style: {
              fontSize: '12px',
              margin: '0px',
              padding: '0px',
            },
          }}
          onChange={(e) => {
            updateStaticCellValue(rowIndex, cellIndex, e.target.value)
          }}
          variant="standard"
        ></TextField>
      )
    }
    return (
      <TableCell
        key={cellIndex}
        style={{
          padding: '4px',
        }}
        variant="standard"
      >
        {field}
      </TableCell>
    )
  }

  const renderActions = (
    row,
    rowIndex,
    range,
    tableId,
    tableIndex,
    hasActions = false,
  ) => {
    return (
      <ButtonGroup size="small" aria-label="small button group">
        {hasActions && (
          <>
            <Button
              onClick={(e) => {
                addStaticReadingRow(tableIndex, tableId, rowIndex, 'copy')
              }}
            >
              <ContentCopyIcon />
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={""}
              label="Age"
              size="small"
              onChange={(e) => {}}
            >
              <MenuItem
                value={20}
                component={Link}
                to={
                  datasheetStaticReadings
                    ? `/typeA/edit/${
                        row[row.length - 1]
                      }/${datasheetId}/${tableId}`
                    : `/typeA/edit/0/0/0`
                }
              >
                Type-A
              </MenuItem>
              <MenuItem
                value={30}
                component={Link}
                to={
                  datasheetStaticReadings
                    ? `/typeB/edit/${tableId}/${
                        row[row.length - 1]
                      }/${datasheetId}/${instrumentId}/${lc ? lc : 0}/${row[2]}/${row[row.length - 2]}`
                    : `/typeB/edit/0/0/0/0/0/0/0`
                }
              >
                Type-B
              </MenuItem>
              <MenuItem
                value={30}
                component={Link}
                to={
                  datasheetStaticReadings
                    ? `/budget/edit/${instrumentId}/${lc ? lc : 0}/${
                        range - 1
                      }/${datasheetId}/${tableId}/${row[2]}/${
                        row[row.length - 1]
                      }/${row[row.length - 2]}`
                    : `/budget/edit/0/0/0/0/0/0`
                }
              >
                Budget
              </MenuItem>
            </Select>
          </>
        )}
      </ButtonGroup>
    )
  }
  const renderRows = (table, tableIndex, staticReadingRows, range) => {
    let i = 1
    let rows = []
    let rowType = ''
    staticReadingRows?.forEach((row, rowIndex) => {
      let _row = []
      if (row[0] == table.id) {
        if (String(row[2])?.includes('_rh_')) {
          rowType = 'header'
          i = 1
          _row = (
            <TableRow key={rowIndex}>
              <TableCell>
                <DeleteIcon
                  style={{
                    color: '#dc3545',
                  }}
                  onClick={(e) => {
                    deleteStaticReadingRow(rowIndex)
                  }}
                />
              </TableCell>
              <TableCell colSpan={row.length - 3}>
                <TextField
                  size="small"
                  fullWidth
                  value={row[0 + 2].split('_rh_')[1]} // 2: row_base
                  InputProps={{
                    style: {
                      fontSize: '14px',
                      margin: 'auto',
                      padding: '0px',
                    },
                  }}
                  onChange={(e) => {
                    updateStaticCellValue(rowIndex, 0, '_rh_' + e.target.value)
                  }}
                ></TextField>
              </TableCell>
              <TableCell>
                {renderActions(row, rowIndex, range, table.id, tableIndex)}
              </TableCell>
            </TableRow>
          )
        } else if (String(row[2])?.includes('_unit_')) {
          rowType = 'unit'
          _row = (
            <TableRow key={rowIndex}>
              <TableCell>
                <DeleteIcon
                  style={{
                    color: '#dc3545',
                  }}
                  onClick={(e) => {
                    deleteStaticReadingRow(rowIndex)
                  }}
                />
              </TableCell>
              <TableCell></TableCell>
              {row
                .slice(2, row.length - 2) // 2: tableId + datasheetId, 1: uncertainty, stdRanges
                .map((cell, cellIndex) =>
                  renderTableCell(table, row, rowIndex, cell, cellIndex),
                )}
              <TableCell>
                {renderActions(row, rowIndex, range, table.id, tableIndex)}
              </TableCell>
            </TableRow>
          )
        }
        // else normal row
        else {
          rowType = 'data'
          _row = (
            <TableRow key={rowIndex}>
              <TableCell>
                <DeleteIcon
                  style={{
                    color: '#dc3545',
                  }}
                  onClick={(e) => {
                    deleteStaticReadingRow(rowIndex)
                  }}
                />
              </TableCell>
              <TableCell>
                <button
                  onClick={() => {
                    setActiveRowIndex(rowIndex)
                  }}
                >
<ExpandCircleDownOutlinedIcon/>
                </button>
              </TableCell>
              {row
                .slice(2, row.length - 2) // 2: tableId + datasheetId, 2: standardRanges, rowId
                .map((cell, cellIndex) =>
                  renderTableCell(table, row, rowIndex, cell, cellIndex),
                )}
              <TableCell>
                {renderActions(
                  row,
                  rowIndex,
                  range,
                  table.id,
                  tableIndex,
                  true,
                )}
              </TableCell>
            </TableRow>
          )
        }
      }
      if (rowIndex == activeRowIndex && rowType == 'data') {
        let standardRow = (
          <tr>
            <td></td>
            <td></td>
            <TableCell colSpan={row.length - 2}>
              {' '}
              <Autocomplete
                multiple
                fullWidth
                id="checkboxes-tags-demo"
                size="small"
                options={
                  allStandards != null
                    ? allStandards.map((item) => {
                        return {
                          id: item.pl_id,
                          stId: item.pl_stId,
                          title: item.pl_standardName,
                          rangeId: item.pr_id,
                          range: item.pr_rangeName,
                        }
                      })
                    : []
                }
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.stId} <b>:</b> {option.title}, {option.range}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Standard  Master"
                    placeholder="select multiple standards"
                  />
                )}
                onChange={(event, value) => {
                  if (value.length) {
                    var arrToStr = Array.prototype.map
                      .call(value, function (item) {
                        return item.rangeId
                      })
                      .join(',')
                    setSelectedStandards(arrToStr)
                    updateStandardRangesOfRow(arrToStr)
                  }
                }}
              />
              <br />
            </TableCell>
          </tr>
        )
        rows.push(_row, standardRow)
      } else {
        rows.push(_row)
      }
    })

    return rows
  }

  const renderStaticTables = () => {
    if (!staticTables) return null
    let range = 0

    return staticTables?.map((table, tableIndex) => {
      if (tableDisplayStatus === 1 && table.isAutomated !== 0) return null
      else if (tableDisplayStatus === 2 && table.isAutomated !== 1) return null

      range = 0
      return (
        <div key={tableIndex} sx={{ mb: 5 }} style={{ marginBottom: '60px' }}>
          <Typography variant="h6" component="div" style={{ float: 'left' }}>
            {tableIndex + 1 + '. '}
            <TextField
              id="outlined-basic"
              size="small"
              variant="standard"
              type="text"
              value={
                configuration?.customTableNames?.[table.id] || table.tableName
              }
              onChange={(e) => {
                setConfiguration({
                  ...configuration,
                  customTableNames: {
                    ...configuration.customTableNames,
                    [table.id]: e.target.value,
                  },
                })
              }}
            />
            |
            <Button size="sm">
              <DeleteIcon
                style={{
                  color: '#8d7a7c',
                }}
                onClick={(e) => {
                  deleteTable(table.id)
                }}
              />
            </Button>{' '}
            |
            <Button
              size="sm"
              onClick={(e) => {
                fetchOtherStaticReadingRows(table.id)
              }}
            >
              Autoload
            </Button>
          </Typography>
          <Button
            variant="outlined"
            size="small"
            style={{ float: 'right' }}
            onClick={(e) => {
              addStaticReadingRow(tableIndex, table.id)
            }}
          >
            <AddIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            style={{ float: 'right', marginRight: '7px' }}
            onClick={(e) => {
              addStaticUnitRow(tableIndex, table.id)
            }}
          >
            <AddIcon /> Unit
          </Button>
          <Button
            variant="outlined"
            size="small"
            style={{ float: 'right', marginRight: '7px' }}
            onClick={(e) => {
              addStaticRowHeader(tableIndex, table.id)
            }}
          >
            <AddIcon /> Row header
          </Button>
          <div style={{ width: '100%', overflow: 'auto' }}>
            <ClassicTable>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  {renderCommonHeader(table?.defaultConfiguration)}
                  <TableRow>
                    <TableCell
                      className="header-border"
                      align="center"
                      style={{ textAlign: 'center' }}
                    ></TableCell>
                    <TableCell
                      className="header-border"
                      align="center"
                      style={{ textAlign: 'center' }}
                    >
                      <Typography noWrap style={{ fontSize: '12px' }}>
                        S.N.
                      </Typography>
                    </TableCell>
                    {/* render columns*/}
                    {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                      if (!deletedColumns[table.id]?.includes('c' + i)) {
                        if (table['c' + i]?.toLowerCase()?.match(/m+\d+/g)) {
                          range++
                        }
                        if (table['c' + i] != null) {
                          return (
                            <TableCell
                              className="header-border"
                              align="center"
                              style={{ textAlign: 'center' }}
                            >
                              <div>
                                <Typography
                                  noWrap
                                  style={{
                                    fontSize: '12px',
                                  }}
                                >
                                  {table['c' + i]}
                                </Typography>
                              </div>
                            </TableCell>
                          )
                        }
                      }
                    })}
                    <TableCell>
                      <Typography
                        noWrap
                        style={{
                          fontSize: '12px',
                        }}
                      >
                        Uncertainty
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          fontSize: '12px',
                        }}
                        component={Link}
                        to={`/viewAll/${instrumentId}/${lc ? lc : 0}/${
                          range - 1
                        }/${datasheetId}/${table.id}`}
                      >
                        View All
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows(table, tableIndex, staticReadingRows, range)}
                </TableBody>
              </Table>
            </ClassicTable>
          </div>
        </div>
      )
    })
  }

  const renderGaugeForm = () => {
    if (instrument?.isGauge) {
      return (
        <>
          <div>
            <Typography variant="h6" component="div">
              Prepare gauge data
            </Typography>
            <div>
              <TextField
                id="outlined-basic"
                label="Size"
                size="small"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  setGaugeData({ ...gaugeData, size: Number(e.target.value) })
                }}
              />
              <TextField
                id="outlined-basic"
                label="Higher Tolerance"
                size="small"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  setGaugeData({ ...gaugeData, ht: Number(e.target.value) })
                }}
              />
              <TextField
                id="outlined-basic"
                label="Lower Tolerance"
                size="small"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  setGaugeData({ ...gaugeData, lt: Number(e.target.value) })
                }}
              />
              <Button
                variant="contained"
                size="small"
                className="m-1"
                sx={{ m: 0 }}
                onClick={() => {
                  prepareGaugeTable()
                }}
              >
                Prepare
              </Button>
            </div>
            <div>
              {gaugeData.isPrepared != 0 && (
                <div className="m-2">
                  <Button className="m-1 p-1" variant="outlined">
                    G : {gaugeData.g ?? '-'}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    K: {gaugeData.k ?? '-'}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    T: {gaugeData.t?.toFixed(4) ?? '-'}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    Y: {gaugeData.y ?? '-'}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    Z: {gaugeData.z ?? '-'}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    H/2: {gaugeData.h2 ?? '-'}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    Aplha: {gaugeData.alpha ?? '-'}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <hr />
        </>
      )
    }
  }

  const renderCmcTable = () => {
    if (cmcReadingRows.length < 1) return

    let readingColumns = [
      {
        field: 'srNo',
        headerName: 'Sr. No.',
      },
      {
        field: 'range',
        headerName: 'Range',
      },
      {
        field: 'lowerCMC',
        headerName: 'Lower CMC',
      },
      {
        field: 'higherCMC',
        headerName: 'Higher CMC',
      },
    ]
    return (
      <div>
        <h4 style={{ 'margin-bottom': '0px' }}>CMC</h4>
        <div style={{ width: '100%', overflow: 'auto' }}>
          <ClassicTable>
            <Table sx={{ minWidth: 660 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {readingColumns.map((column) => (
                    <TableCell key={column.field}>
                      {' '}
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cmcReadingRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {row.map((cell, cellIndex) => {
                      if (cellIndex > 0) {
                        return <TableCell>{cell}</TableCell>
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ClassicTable>

          <br />
        </div>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Customer Name"
              size="small"
              value={clientId}
              fullWidth
              variant="outlined"
              disabled
              onChange={(e) => setClientId(e.target.value)}
            />
          </Grid>
          {calType === 0 ? (
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="ULR NO"
                size="small"
                value={(Math.random() + 1).toString(36).substring(7)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled
                variant="outlined"
              />
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Work order number"
              size="small"
              value={(Math.random() + 1).toString(36).substring(7)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Certificate Number"
              size="small"
              value={(Math.random() + 1).toString(36).substring(7)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="PO Number"
              className="textfield"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={poNo}
              onChange={(e) => setPoNo(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="PO Date"
                inputFormat="MM/dd/yyyy"
                value={poDate ? poDate : new Date()}
                onChange={(e) => setPoDate(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Service Request No."
              className="textfield"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={srnNo}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Job Number"
              className="textfield"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={jobNumber}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="DC Number"
              InputLabelProps={{ shrink: true }}
              value={dcNo}
              onChange={(e) => setDCNo(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date Of Issue"
                inputFormat="dd/MM/yyyy"
                value={dateOfIssue ? dateOfIssue : new Date()}
                onChange={(e) => setDateOfIssue(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date of calibration"
                inputFormat="dd/MM/yyyy"
                value={calibrationDate ? calibrationDate : new Date()}
                onChange={(e) => setCalibrationDate(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Next due date"
                value={nextDueDate ? nextDueDate : new Date()}
                onChange={(e) => setNextDueDate(e)}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date Of Receipt"
                value={receiptDate ? receiptDate : new Date()}
                onChange={(e) => setReceiptDate(e)}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date of DC"
                inputFormat="dd/MM/yyyy"
                value={dcDate ? dcDate : new Date()}
                onChange={(e) => setDCDate(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Instrument Name"
              size="small"
              disabled
              value={instrumentName}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Calibration Procedure No."
              size="small"
              value={calibrationProcedureNo}
              onChange={(e) => setCalibrationProcedureNo(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Referenecer Standards"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={referenceStandards}
              onChange={(e) => setreferenceStandards(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Make"
              value={make}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setMake(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Model"
              value={models}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setModel(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Con Of Duc"
              value={ConOfDuc}
              className="textfield"
              onChange={(e) => setConOfDuc(e.target.value)}
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Range"
              value={ranges}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setRanges(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="LC"
              value={lc}
              onChange={(e) => setLC(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Serial Number"
              value={serialNo}
              onChange={(e) => setSerialNo(e.target.value)}
              className="textfield"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="DUC ID"
              value={DUCID}
              onChange={(e) => setDUCID(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Location"
              value={location}
              className="textfield"
              onChange={(e) => setLocation(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Accuracy"
              value={accuracy}
              className="textfield"
              onChange={(e) => setAccuracy(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Cal Point"
              value={calPoint}
              disabled
              className="textfield"
              onChange={(e) => setCalPoint(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Cal Method"
              value={calMethod}
              disabled
              className="textfield"
              onChange={(e) => setCalMethod(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Location Of Instrument"
              value={locationOfInstrument}
              disabled
              className="textfield"
              onChange={(e) => setLocationOfInstrument(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Start temprature"
              value={startTemp}
              onChange={(e) => setStartTemp(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="End temprature"
              value={endTemp}
              onChange={(e) => setEndTemp(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Start Humidity"
              value={startHumidity}
              onChange={(e) => setStartHumidity(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="End Humidity"
              value={endHumidity}
              onChange={(e) => setEndHumidity(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Atmospheric Pressure"
              value={atmosphericPressure}
              onChange={(e) => setAtmosphericPressure(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Discipline"
              disabled
              value={disciplineName}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div>
          <Typography variant="h6" component="div" sx={{ mb: 2 }}>
            Master Equipments Used
          </Typography>
          <ClassicTable>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Name of Standard</TableCell>
                  <TableCell>Make/Model</TableCell>
                  <TableCell>Certificate No.</TableCell>
                  <TableCell>Tracability</TableCell>
                  <TableCell>Cal.dt</TableCell>
                  <TableCell>Due Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {standardMasterArray.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.standardName}</TableCell>
                    <TableCell>{row.make}</TableCell>
                    <TableCell>{row.certificateNumber}</TableCell>
                    <TableCell>{row.traceability}</TableCell>
                    <TableCell>
                      {row.calDate
                        ? moment(row.calDate).format('DD-MM-YYYY')
                        : ''}
                    </TableCell>
                    <TableCell>
                      {moment(row.validUpto).format('DD-MM-YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ClassicTable>
        </div>
        <br />
        <Grid container spacing={2} sx={{ my: 2 }}>
          {renderRangeData()}
        </Grid>
        <hr />
        {renderGaugeForm()}
        <div>
          <Typography variant="h6" component="div">
            Observed Readings
          </Typography>

          <div style={{ float: 'right', width: '100%' }}>
            <ButtonGroup
              aria-label="outlined primary button group"
              size="small"
              sx={{ m: 0 }}
              style={{ float: 'left' }}
            >
              <Button
                variant={tableDisplayStatus == 1 ? 'contained' : 'outlined'}
                onClick={(e) => {
                  setTableDisplayStatus(1)
                }}
              >
                Manual
              </Button>
              <Button
                variant={tableDisplayStatus == 2 ? 'contained' : 'outlined'}
                onClick={(e) => {
                  setTableDisplayStatus(2)
                }}
              >
                Automated
              </Button>
              <Button
                variant={tableDisplayStatus == 3 ? 'contained' : 'outlined'}
                onClick={(e) => {
                  setTableDisplayStatus(3)
                }}
              >
                Both
              </Button>
            </ButtonGroup>

            <TextField
              style={{ float: 'right' }}
              id="outlined-basic"
              label="Precision Count"
              size="small"
              variant="outlined"
              type="number"
              onChange={(e) => {
                if (Number(e.target.value) > 0)
                  setPrecisionCount(Number(e.target.value))
              }}
            />
          </div>
        </div>
        <hr style={{ border: '1px solid black' }} />
        <div className="ds-tables">{renderStaticTables()}</div>
        {/* {complianceStatus === true && 
          <h5 style={{color: "red"}}>
            Compliance status: failed
          </h5>
        }
         {complianceStatus === false && 
          <h5 style={{color: "green"}}>
            Compliance status: passed
          </h5>
        } */}
        <hr />
        <div style={{ display: 'flex' }}>
          <h5>Upload observations excel file:&nbsp;&nbsp; </h5>
          <Input
            type="file"
            onChange={(e) => {
              let [file] = e.target.files
              setExcelFile(file)
            }}
          />
          <Button
            variant="outlined"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              processExcelFile()
            }}
          >
            process
          </Button>
        </div>
        <hr />
        {renderCmcTable()}
        <hr />
        {/* <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          component={Link}
          to={`/viewAll/${instrumentId}/${lc ? lc : 0}/${
            range - 1
          }/${datasheetId}/${table.id}`}
        >
          View All
        </Button> */}
        <div style={{ marginBottom: '50px' }}>
          <div style={{ float: 'left' }}>
            <h5>Remarks :</h5>
          </div>
          <br />
          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            placeholder="Enter remark here"
            style={{ width: 900, float: 'left' }}
            value={configuration?.remark || defaultReamrk}
            onChange={(e) => {
              setConfiguration({
                ...configuration,
                remark: e.target.value,
              })
            }}
          />
        </div>
        <hr
          style={{ float: 'left', border: '1px solid black', width: '100%' }}
        />

        <Grid container spacing={2} sx={{ my: 3 }}>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Calibrated by"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Checked / Approved by"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Issue Number"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Rev. Number"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Form Number"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Revision Date"
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <Paper sx={{ mx: 5, mt: 2, p: 2 }} ref={printComponentRef}>
      <Typography variant="h5" component="div" sx={{ mb: 2 }}>
        Datasheet of {instrumentName}
      </Typography>
      <hr />
      {renderForm()}
      <Toolbar style={{ padding: '0px', width: '100%' }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true)
            submitDatasheet()
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Toolbar>
    </Paper>
  )
}
