import React, { useEffect } from "react"
import { BASE_URL } from "../../../global"
import { ToastContainer, toast } from "react-toastify"
import { ClassicTable } from "../../../utils/components/Styles"
import { useParams } from "react-router-dom"
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
const axios = require("axios")

export default function UpdateSupportiveInstruments(props) {
  const [srfObject, createSrfObject] = React.useState({})
  const [unitArray, setUnitArray] = React.useState([])
  const [standardName, setStandardName] = React.useState()
  const [standardRanges, setStandardRanges] = React.useState([])
  const [unitFilter, setUnitFilter] = React.useState([])
  const params = useParams()
  var [readingRows, setReadingRows] = React.useState([["", "", "", "", "", ""]])

  const [readingRangeValue, setReadingRangeValue] = React.useState([
    ["", "", "", "", "", ""]
  ])

  var readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr No",
        editable: false
      },
      {
        field: "id",
        headerName: "id",
        editable: false
      },
      {
        field: "range",
        headerName: "Range",
        editable: true
      },
      {
        field: "percentOfOutput",
        headerName: "% Of Output",
        editable: true
      },
      {
        field: "plusValue",
        headerName: "+ Value",
        editable: true
      },
      {
        field: "axialUniformity",
        headerName: "Axial Uniformity",
        editable: true
      },
      {
        field: "radialUniformity",
        headerName: "Radial Uniformity",
        editable: true
      },
      {
        field: "stability",
        headerName: "Stability",
        editable: true
      },

      {
        field: "Action"
      }
    ]
  ]
  var refresh = () => {
    window.location.reload(false)
  }

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows]
    newRows[rowIndex][colIndex] = value
    setReadingRows([...newRows])
  }
  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRowss = [...readingRangeValue]
    newRowss[rowIndex][colIndex] = value
    setReadingRangeValue([...newRowss])
  }

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows]
    newReadingRows.push(["", "", "", "", "", ""])
    setReadingRows([...newReadingRows])
    let newReadingRowss = [...readingRangeValue]
    newReadingRowss.push([null, null, null, null, null, null])
    setReadingRangeValue([...newReadingRowss])
  }

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows]
    newReadingRows.splice(rowIndex, 1)
    setReadingRows([...newReadingRows])
  }

  const onSubmit = (event) => {
    let url = BASE_URL
    var data = {
      query: `UPDATE standards
      SET standardName = '${standardName}'
      WHERE id = ${params.id};`
    }
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        debugger
        console.log(res.data)
        toast("Supportive Instruments updated successfully !")
        console.log(readingRows)
        var rows = readingRows.map((row, i) => ({
          rangeName:
            readingRangeValue[i][1] != null && row[0] != ""
              ? row[0] + "," + readingRangeValue[i][0]
              : row[0],
          percentOfOutput:
            readingRangeValue[i][2] != null && row[1] != ""
              ? row[1] + "," + readingRangeValue[i][1]
              : row[1],
          plusValue:
            readingRangeValue[i][3] != null && row[2] != ""
              ? row[2] + "," + readingRangeValue[i][2]
              : row[2],
          axialUniformity:
            readingRangeValue[i][4] != null && row[3] != ""
              ? row[3] + "," + readingRangeValue[i][3]
              : row[3],
          radialUniformity:
            readingRangeValue[i][5] != null && row[4] != ""
              ? row[4] + "," + readingRangeValue[i][4]
              : row[4],
          stability:
            readingRangeValue[i][6] != null && row[5] != ""
              ? row[5] + "," + readingRangeValue[i][5]
              : row[5]
        }))
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].rangeName == "") {
            rows[i].rangeName = null
          }
          if (rows[i].stability == "") {
            rows[i].stability = null
          }
          if (rows[i].radialUniformity == "") {
            rows[i].radialUniformity = null
          }
          if (rows[i].percentOfOutput == "") {
            rows[i].percentOfOutput = null
          }
          if (rows[i].axialUniformity == "") {
            rows[i].axialUniformity = null
          }
          if (rows[i].plusValue == "") {
            rows[i].plusValue = null
          }
        }
        console.log(rows)
        var data = {
          query: `UPDATE standardRanges
          SET axialUniformity = '${rows[0].axialUniformity}',
          percentOfOutput = '${rows[0].percentOfOutput}',
          plusValue = '${rows[0].plusValue}',
          radialUniformity = '${rows[0].radialUniformity}',
          rangeName = '${rows[0].rangeName}',
          stability = '${rows[0].stability}'
          WHERE standardId = ${params.id};`
        }

        axios
          .post(BASE_URL + `dynamic`, data)
          .then((res) => {
            console.log("submited")
            props.setLoader(false)
            setTimeout(refresh, 500)
          })
          .catch((error) => {
            props.setLoader(false)
            toast.error("Something Went Wrong!")
          })
      })
      .catch((error) => {
        props.setLoader(false)
        toast.error("Something Went Wrong!")
      })
  }

  const submitDescriptionRows = (readingRows, id) => {}

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject
    }
    newSrfObject[key] = value
    createSrfObject(newSrfObject)
  }
  const getStandard = () => {
    let url = BASE_URL
    var data = {
      query: `SELECT * FROM standards WHERE type=2 and id=${params.id}`
    }
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setStandardName(res.data[0].standardName)
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!")
      })
  }
  const getStandardReqading = () => {
    axios
      .get(BASE_URL + `standardRanges?_where=(standardId,eq,${params.id})`)
      .then((res) => {
        setStandardRanges(res.data)
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!")
      })
  }
  const initiateRanges = () => {
    let rows = []
    let rowsSpace = []
    for (let i = 0; i < standardRanges.length; i++) {
      rowsSpace.push([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ])
      rows.push([
        standardRanges[i].id,
        standardRanges[i].rangeName,
        standardRanges[i].percentOfOutput,
        standardRanges[i].plusValue,
        standardRanges[i].axialUniformity,
        standardRanges[i].radialUniformity,
        standardRanges[i].stability
      ])
    }
    setReadingRows(rows)
    setReadingRangeValue(rowsSpace)
  }
  const getUnitMasterArray = () => {
    let url = BASE_URL
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray(res.data)
      })
      .catch((error) => {
        toast.error("Something Went Wrong!")
      })
  }

  const UnitList = () => {
    var unitArr = []
    for (let i = 0; i < unitArray.length; i++) {
      unitArr.push({
        label: unitArray[i].symbol
      })
    }
    setUnitFilter(unitArr)
  }
  useEffect(() => {
    initiateRanges()
  }, [standardRanges])
  useEffect(() => {
    getUnitMasterArray()
    getStandard()
    getStandardReqading()
  }, [])

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Supportive Instrument{" "}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Name"
            size="small"
            fullWidth
            variant="outlined"
            value={standardName}
            onChange={(e) => {
              setStandardName(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <br />

      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0)
        }}>
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    return (
                      <TableCell>
                        <TextField
                          size="small"
                          value={cell}
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value)
                          }}></TextField>

                        <select
                          styles={{ width: "100%" }}
                          defaultValue={"select unit"}
                          onChange={(e) => {
                            updateRangeCellValue(
                              index,
                              cellIndex,
                              e.target.value
                            )
                          }}>
                          {unitArray.map((option) => (
                            <option value={option.symbol}>
                              {option.symbol}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                    )
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index)
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true)
            onSubmit()
          }}>
          Save Supportive Instruments
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  )
}
