import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import { ClassicTable } from "../../utils/components/Styles";
import AddIcon from "@mui/icons-material/Add";

const axios = require("axios");

export default function EditCustomInvoice() {
  const [companyName, setCompanyName] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState("");
  const [totalD, setTotalD] = React.useState("");
  const [gstType, setGstType] = React.useState(null);
  const [address, setAddress] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [invoiceDate, setInvoiceDate] = React.useState("");
  const [conveyanceCharges, setConveyanceCharges] = React.useState("");
  const [instrumentIds, setInstrumentIds] = React.useState([]);

  const [invoiceNumber, setInvoiceNumber] = React.useState(
    (Math.random() + 1).toString(36).substring(7)
  );
  const [contactNumber, setContactNumber] = React.useState("");
  const [poNumber, setPoNumber] = React.useState("");
  const [sGst, setSGST] = React.useState(0);
  const [cGst, setCGST] = React.useState(0);
  const [poDate, setPoDate] = React.useState("");
  const [instrumentDuplicateCount, setInstrumentDuplicateCount] =
    React.useState("");
  const [products, setProducts] = React.useState([]);
  const params = useParams();
  const userType = localStorage.getItem("type");
  const [customInvoiceReadingsData, setCustomInvoiceReadingsData] =
    React.useState([]);
  const [productsArray, setProductsArray] = React.useState([]);
  const [invoiceType, setInvoiceType] = React.useState(1);
  const [productArray, setProductArray] = React.useState([]);
  var [clientArray, setClientArray] = React.useState([]);

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "productName",
        headerName: "Product Name *",
        editable: true,
      },
      {
        field: "discription",
        headerName: "Discription",
        editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity *",
        editable: true,
      },
      {
        field: "rate",
        headerName: "Rate *",
        editable: true,
      },
      {
        field: "amount",
        headerName: "Amount *",
        editable: true,
      },
    ],
  ];
  const [readingRows, setReadingRows] = React.useState([]);
  const getInvoiceData = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=invoice.invoices,_j,client.clients&_on1=(invoice.clientId,eq,client.id)&_fields=invoice.invoiceType,invoice.gstType,client.companyName,invoice.clientId,invoice.subject,client.address,client.contact,invoice.poNumber,invoice.poDate&_where=(invoice.clientId,eq,${params.id})~and(invoice.poNumber,eq,${params.poNumber})~and(invoice.status,eq,1)`
      )
      .then((res) => {
        setGstType(res.data[0].invoice_gstType);
        setInvoiceType(res.data[0].invoice_invoiceType);
        setCompanyName(res.data[0].client_companyName);
        setClientId(res.data[0].invoice_clientId);
        setSubject(res.data[0].invoice_subject);
        setInstrumentArray(res.data);
        setAddress(res.data[0].client_address);
        setContactNumber(res.data[0].client_contact);
        setPoNumber(res.data[0].invoice_poNumber);
        setPoDate(res.data[0].invoice_poDate);
        setInstrumentDuplicateCount(res.data[0].instrumentDuplicateCount);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };

  const initiateInstrumentList = () => {
    var pushIArray = [];
    for (let i = 0; i < productArray.length; i++) {
      pushIArray.push({
        label: productArray[i].id + ", " + productArray[i].productName,
        key: productArray[i].id,
        name: productArray[i].productName,
      });
    }
    setProductsArray(pushIArray);
  };

  const getCustomProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "customProducts")
      .then((res) => {
        setProductArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var pushCArray = [];
  var clientsArrays = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
    });
  }

  const getProductArray = () => {
    let url = BASE_URL;
    axios
      .get(url + `products`)
      //
      .then((res) => {
        setProducts(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push([-1, "", "", "", "", ""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      setDeletedIds([...deletedIds, newReadingRows[rowIndex][0]]);
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  const getCustomReadingsArray = () => {
    axios
      .get(
        BASE_URL +
          `xjoin?_join=mainInvoice.invoices,_j,prR.customProductRanges,_j,custP.customProducts&_on1=(mainInvoice.id,eq,prR.productId)&_on2=(prR.customProductId,eq,custP.id)&_fields=prR.id,custP.productName,prR.id,prR.discription,prR.quantity,prR.rate,prR.amount,prR.totalAmount,prR.sgst,prR.cgst,custP.id&_where=(prR.productId,eq,${params.mainId})`
      )
      .then((res) => {
        setCustomInvoiceReadingsData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };

  const initiateCustomReadingsData = () => {
    try {
      let rows = [];
      for (let i = 0; i < customInvoiceReadingsData.length; i++) {
        rows.push([
          customInvoiceReadingsData[i].prR_id,
          customInvoiceReadingsData[i].custP_id +
            ", " +
            customInvoiceReadingsData[i].custP_productName,
          customInvoiceReadingsData[i].prR_discription,
          customInvoiceReadingsData[i].prR_quantity,
          customInvoiceReadingsData[i].prR_rate,
          customInvoiceReadingsData[i].prR_amount,
        ]);
      }
      setReadingRows(rows);
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoiceDataArray = () => {
    axios
      .get(
        BASE_URL +
          `	invoices?_where=(clientId,eq,${params.id})~and(poNumber,eq,${params.poNumber})~and(status,eq,1)`
      )
      .then((res) => {
        setInvoiceData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };

  const handleSubmit = (event) => {
    let rows = readingRows.map((row) => ({
      id: parseInt(row[0]),
      customProductId: parseInt(row[1]),
      discription: row[2],
      quantity: parseInt(row[3]),
      rate: parseInt(row[4]),
      amount: parseInt(row[5]),
    }));
    axios
      .patch(BASE_URL + `invoices/${params.mainId}`, {
        clientId: clientId,
        poDate: poDate,
        invoiceNumber: invoiceNumber,
        subject: subject,
        invoiceDate: invoiceDate
          ? moment(invoiceDate).format("YYYY-MM-DD")
          : null,
        poNumber: poNumber,
        gstType: gstType,
        invoiceType: invoiceType,
      })
      .then((res) => {
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        // toast.error("Something Went Wrong!");
      });

    console.log(rows);
    rows.map((row) => {
      if (row.id !== -1 && row.id !== "") {
        axios
          .patch(BASE_URL + `customProductRanges/${row.id}`, {
            productId: params.mainId,
            customProductId: row.customProductId,
            discription: row.discription,
            quantity: row.quantity,
            rate: row.rate,
            amount: row.rate * row.quantity,
          })
          .then((res) => {
            // setTimeout(refresh, 1000);
          })
          .catch((error) => {
            // toast.error("Something Went Wrong!");
          });
      } else {
        axios
          .post(BASE_URL + `customProductRanges`, {
            productId: params.mainId,
            customProductId: row.customProductId,
            discription: row.discription,
            quantity: row.quantity,
            rate: row.rate,
            amount: row.rate * row.quantity,
          })
          .then((res) => {
            // setTimeout(refresh, 1000);
          })
          .catch((error) => {
            // toast.error("Something Went Wrong!");
          });
      }
    });
    toast("Custom Invoice Updated successfully !");

    let row = {
      clientId: clientId,
      address: address,
      totalAmount: totalAmount ? totalAmount : 0,
      discount: totalD,
      gstType: gstType ? gstType : 0,
      sgst: sGst ? sGst : 0,
      cgst: cGst ? cGst : 0,
      subject: subject,
      conveyanceCharges: conveyanceCharges,
      invoiceNumber: invoiceNumber,
      contactNumber: contactNumber,
      poNumber: poNumber,
      instrumentId: instrumentIds.toString(),
      invoiceDate: invoiceDate
        ? moment(invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    };
    return;
    const invoiceHistory = {
      invoiceId: 2,
      instrumentId: instrumentIds.toString(),
      amount: 250,
      invoiceDate: invoiceDate
        ? moment(invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      quantity: 1,
      pendingInvoices: 1,
    };
    // console.log('row',row)
    // return
    let url = BASE_URL;
    axios
      .post(url + "paymentMaster", row)
      .then((res) => {
        toast("Payment created successfully!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const counterAmt = () => {
    var count = 0;
    for (let i = 0; i < readingRows.length; i++) {
      if (readingRows[i][2] != "" && readingRows[i][3] != "") {
        count += parseInt(readingRows[i][3] * readingRows[i][4]);
      }
    }
    setTotalAmount(count);
  };

  useEffect(() => {
    counterAmt();
  }, [readingRows]);

  useEffect(() => {
    getProductArray();
    getInvoiceData();
    getInvoiceDataArray();
    getCustomReadingsArray();
    getCustomProductList();
  }, []);

  useEffect(() => {
    initiateInstrumentList();
  }, [productArray]);

  useEffect(() => {
    initiateCustomReadingsData();
  }, [customInvoiceReadingsData]);

  // console.log(readingRows);
  const invoicesData = () => {
    if (gstType === 1) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="SGST"
              InputLabelProps={{ shrink: true }}
              value={(9 * totalAmount) / 100}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="CGST"
              disabled
              value={(9 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else if (gstType === 2) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="IGST"
              value={(18 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else {
    }
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Invoice
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Name *"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={companyName}
            fullWidth
            disabled
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{ shrink: true }}
            label="Address"
            size="small"
            value={address}
            disabled
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Invoice Number"
            size="small"
            value={(Math.random() + 1).toString(36).substring(7)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Subject"
            size="small"
            value={subject}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Invoice Date *"
              value={invoiceDate ? invoiceDate : new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setInvoiceDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="PO Number *"
            size="small"
            value={poNumber}
            InputLabelProps={{ shrink: true }}
            disabled
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPoNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={
              gstType == 1
                ? "State GST"
                : gstType == 2
                ? "Central Gst"
                : "Non Gst"
            }
            options={[
              { value: 1, label: "State GST" },
              { value: 2, label: "Central Gst" },
              { value: 3, label: "Non Gst" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="GST Type *" />
            )}
            onInputChange={(event, newInputValue) => {
              if (newInputValue == "State GST") {
                setGstType(1);
              } else if (newInputValue == "Central Gst") {
                setGstType(2);
              } else {
                setGstType(3);
              }
              if (newInputValue == "State GST") {
                setCGST((9 * totalAmount) / 100);
                setSGST((9 * totalAmount) / 100);
              } else if (newInputValue == "Central Gst") {
                setCGST((18 * totalAmount) / 100);
              } else {
                setCGST(0);
                setSGST(0);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={invoiceType == 1 ? "Profroma" : "Final invoice"}
            options={[
              { value: 1, label: "Profroma" },
              { value: 2, label: "Final invoice" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Invoice Type *" />
            )}
            onInputChange={(event, newInputValue) => {
              setInvoiceType(newInputValue);
            }}
          />
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Product Details </h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addRangeRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex > 0) {
                      if (
                        cellIndex != 0 &&
                        cellIndex != 1 &&
                        cellIndex != 2 &&
                        cellIndex != 3 &&
                        cellIndex != 4 &&
                        cellIndex != 5
                      ) {
                        return (
                          <TableCell>
                            <TextField
                              size="small"
                              value={cell}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            ></TextField>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 1) {
                        console.log(
                          productsArray.find(
                            (element) =>
                              element.key == readingRows[index][cellIndex]
                          )?.name
                        );
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Autocomplete
                              size="small"
                              id="combo-box-demo"
                              options={productsArray}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={
                                readingRows[index][cellIndex] +
                                "," +
                                productsArray.find(
                                  (element) =>
                                    element.key == readingRows[index][cellIndex]
                                )?.name
                              }
                              onInputChange={(event, newInputValue) => {
                                var splArr = newInputValue.split(",");
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  parseInt(splArr[0])
                                );
                              }}
                            />
                          </TableCell>
                        );
                      }
                      if (cellIndex == 2) {
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Grid item xs={3}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={readingRows[index][cellIndex]}
                                onChange={(e) => {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    e.target.value
                                  );
                                }}
                              />
                            </Grid>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 3) {
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Grid item xs={3}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={readingRows[index][cellIndex]}
                                onChange={(e) => {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    parseInt(e.target.value)
                                  );
                                }}
                              />
                            </Grid>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 4) {
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Grid item xs={3}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={readingRows[index][cellIndex]}
                                onChange={(e) => {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    parseInt(e.target.value)
                                  );
                                }}
                              />
                            </Grid>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 5) {
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Grid item xs={3}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={
                                  readingRows[index][3] * readingRows[index][4]
                                }
                                onChange={(e) => {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    e.target.value
                                  );
                                }}
                              />
                            </Grid>
                          </TableCell>
                        );
                      }
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />
      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Conveyance Charges"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              setConveyanceCharges(e.target.value);
            }}
          />
        </Grid> */}
      </Grid>

      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3} justify="flex-end">
          <TextField
            id="outlined-basic"
            label="Discount %"
            value={"10"}
            minvalue="100"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setTotalD((totalAmount * parseFloat(e.target.value)) / 100);
            }}
          />
        </Grid>
      </Grid>
      {invoicesData()}
      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3} justify="flex-end">
          <TextField
            id="outlined-basic"
            label="Total Amount"
            size="small"
            fullWidth
            value={totalAmount - totalD + (18 * totalAmount) / 100}
            InputLabelProps={{ shrink: true }}
            disabled
            variant="outlined"
          />
        </Grid>
      </Grid>
      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        {userType !== "3" ? (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              gstType != null
                ? handleSubmit()
                : toast.error("Please select GST Type!");
            }}
          >
            Save
          </Button>
        ) : (
          console.log("")
        )}
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          component={Link}
          to={`/customInvoice/viewCustomInvoice/${
            invoiceData[0]?.clientId || 0
          }/${invoiceData[0]?.poNumber || 0}/${params.id}`}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
