import React, { useState } from "react"
import { styled, useTheme } from "@mui/material/styles"
import { Link } from "react-router-dom"

import MuiDrawer from "@mui/material/Drawer"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Collapse from "@mui/material/Collapse"
import ListItemText from "@mui/material/ListItemText"
import InboxIcon from "@mui/icons-material/MoveToInbox"
import MailIcon from "@mui/icons-material/Mail"
import DashboardIcon from "@mui/icons-material/Dashboard"
import Settings from "@mui/icons-material/Settings"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import ListSubheader from "@mui/material/ListSubheader"
import ListItemButton from "@mui/material/ListItemButton"
import StarBorder from "@mui/icons-material/StarBorder"
import DraftsIcon from "@mui/icons-material/Drafts"
import SendIcon from "@mui/icons-material/Send"
import BuildIcon from "@mui/icons-material/Build"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing"
import ArchitectureIcon from "@mui/icons-material/Architecture"
import CategoryIcon from "@mui/icons-material/Category"
import GroupIcon from "@mui/icons-material/Group"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import TableViewIcon from "@mui/icons-material/TableView"
import ArticleIcon from "@mui/icons-material/Article"
import TableChartIcon from "@mui/icons-material/TableChart"
import useGlobalStorage from "use-global-storage"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { useNavigate } from "react-router-dom"

const drawerWidth = 220

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}))

export default function Navbar(props) {
  const navigate = useNavigate()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)
  const [masterNested, setMasterNested] = React.useState(false)
  const [srfNested, setSrfNested] = React.useState(false)
  const [paymentNested, setPaymentNested] = React.useState(false)
  const [intermediateProcedure, setIntermediateProcedure] =
    React.useState(false)

  const userName = localStorage.getItem("userName")
  const userType = localStorage.getItem("type")
  const modules = localStorage.getItem("modules")?.split(",")

  const useStorage = useGlobalStorage({
    storageOptions: { name: "test-db" }
  })
  const [title, setTitle] = useStorage("title")

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const logout = () => {
    props.setIsLoggedIn(false)
    window.localStorage.clear()
    navigate("/")
  }

  const hasAccess = (key) => {
    if (userType == 1) return true
    else if (userType == 2) {
      if (modules?.includes(key)) return true
      else return false
    }
    // ToDo: add logic for client login
    else {
      if ("".includes(key)) return true
      else return false
    }
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" })
            }}>
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            align="left">
            {title}
          </Typography>
          <Button color="inherit" onClick={logout}>
            <AccountCircleIcon />
            &nbsp;
            {userName} &nbsp;
            <span style={{ textTransform: "lowercase" }}>
              ({["admin", "Engineer", "client"][userType - 1]})
            </span>
            &nbsp;&nbsp; |&nbsp;&nbsp; Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" gutterBottom component="div">
            Calibration
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper"
          }}
          component="nav"
          aria-labelledby="nested-list-subheader">
          {hasAccess("1") && (
            <ListItemButton
              component={Link}
              to="/dashboard"
              onClick={() => setTitle("DASHBOARD")}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          )}
          {hasAccess("2") && (
            <ListItemButton onClick={(e) => setMasterNested(!masterNested)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Master" />
              {masterNested ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          <Collapse in={masterNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/standard"
                onClick={() => setTitle("STANDARD")}>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Standard" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/instrument"
                onClick={() => setTitle("INSTRUMENT")}>
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Instrument" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/discipline"
                onClick={() => setTitle("DISCIPLINE")}>
                <ListItemIcon>
                  <ArchitectureIcon />
                </ListItemIcon>
                <ListItemText primary="Discipline" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/product"
                onClick={() => setTitle("Product")}>
                <ListItemIcon>
                  <ArchitectureIcon />
                </ListItemIcon>
                <ListItemText primary="Product" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/range"
                onClick={() => setTitle("Ranges")}>
                <ListItemIcon>
                  <ArchitectureIcon />
                </ListItemIcon>
                <ListItemText primary="Range" />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/client"
                onClick={() => setTitle("CLIENT")}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Client" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/users/addUser"
                onClick={() => setTitle("Users")}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="User" />
              </ListItemButton>
              {/* <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/table"
                onClick={() => setTitle("Dynamic Table")}
              >
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Table" />
              </ListItemButton> */}
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/unit/unitList"
                onClick={() => setTitle("Units")}>
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Units" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/staticTable"
                onClick={() => setTitle("Dynamic Table")}>
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Template" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/uncertinity/uncertinityList"
                onClick={() => setTitle("Uncertainty")}>
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Uncertainty" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/supportiveInstruments/supportiveInstrumentsList"
                onClick={() => setTitle("Supportive Instruments")}>
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Supportive Instruments" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/setting"
                onClick={() => setTitle("Settings")}>
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </List>
          </Collapse>
          {hasAccess("3") && (
            <ListItemButton onClick={(e) => setSrfNested(!srfNested)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="SRF" />
              {srfNested ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          <Collapse in={srfNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/srf/createSRF"
                onClick={() => setTitle("SRF")}>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Create New" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/srf"
                onClick={() => setTitle("SRF")}>
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="View List" />
              </ListItemButton>
            </List>
          </Collapse>
          {hasAccess("4") && (
            <ListItemButton
              component={Link}
              to="/datasheet"
              onClick={() => setTitle("DATASHEET")}>
              <ListItemIcon>
                <TableViewIcon />
              </ListItemIcon>
              <ListItemText primary="Datasheets" />
            </ListItemButton>
          )}
          {hasAccess("5") && (
            <ListItemButton
              component={Link}
              to="/certificate"
              onClick={() => setTitle("CERTIFICATE")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Certificates" />
            </ListItemButton>
          )}
          {hasAccess("10") && (
            <ListItemButton
              component={Link}
              to="/challan/challanList"
              onClick={() => setTitle("Challan List")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Challan List" />
            </ListItemButton>
          )}

          {hasAccess("10") && (
            <ListItemButton
              component={Link}
              to="/courier/courierList"
              onClick={() => setTitle("Courier Register")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Courier Register" />
            </ListItemButton>
          )}
          {hasAccess("12") && (
            <ListItemButton
              component={Link}
              to="/master/expense"
              onClick={() => setTitle("EXPENSES")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Expenses" />
            </ListItemButton>
          )}
          {hasAccess("5") && (
            <ListItemButton
              component={Link}
              to="/workReport"
              onClick={() => setTitle("Work Report")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Work Report" />
            </ListItemButton>
          )}
          {hasAccess("11") && (
            <ListItemButton
              component={Link}
              to="/summaryReport"
              onClick={() => setTitle("SUMMARY REPORT")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Summary Report" />
            </ListItemButton>
          )}

          {hasAccess("12") && (
            <ListItemButton
              component={Link}
              to="/adminHistoryCard"
              onClick={() => setTitle("History Card")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="History Card" />
            </ListItemButton>
          )}
          {hasAccess("7") && (
            <ListItemButton
              component={Link}
              to="/renewCertificate"
              onClick={() => setTitle("Due Reports")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Due Reports" />
            </ListItemButton>
          )}
          {hasAccess("7") && (
            <ListItemButton
              component={Link}
              to="/mastersDue"
              onClick={() => setTitle("Masters Due")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Masters Due" />
            </ListItemButton>
          )}
          {hasAccess("5") && (
            <ListItemButton
              component={Link}
              to="/master/enquiry/enquiryList"
              onClick={() => setTitle("ENQUIRY")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Enquiry" />
            </ListItemButton>
          )}
          {hasAccess("8") && (
            <ListItemButton
              component={Link}
              to="/quotation"
              onClick={() => setTitle("Quotation")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Quotation" />
            </ListItemButton>
          )}
          {hasAccess("9") && (
            <ListItemButton
              component={Link}
              to="/invoice"
              onClick={() => setTitle("INVOICE LIST")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Invoice List" />
            </ListItemButton>
          )}
          {hasAccess("5") && (
            <ListItemButton
              component={Link}
              to="/viewCustomInvoice"
              onClick={() => setTitle("Invoice")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Invoice" />
            </ListItemButton>
          )}
          {hasAccess("14") && (
            <ListItemButton onClick={(e) => setPaymentNested(!paymentNested)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Payment" />
              {paymentNested ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          <Collapse in={paymentNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/paymentMaster"
                onClick={() => setTitle("Payment History")}>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Payment History" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/accountStatement"
                onClick={() => setTitle("Account Statement")}>
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Account Statement" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/gstReport"
                onClick={() => setTitle("Gst Report")}>
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Gst Report" />
              </ListItemButton>
            </List>
          </Collapse>
          {hasAccess("15") && (
            <ListItemButton
              component={Link}
              to="/master/purchase/purchaseList"
              onClick={() => setTitle("PURCHASE")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Purchase" />
            </ListItemButton>
          )}
          {hasAccess("13") && (
            <ListItemButton
              onClick={(e) => setIntermediateProcedure(!intermediateProcedure)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Intermediate Procedure" />
              {intermediateProcedure ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          {hasAccess("14") && (
            <ListItemButton
              component={Link}
              to="/master/scope/scopes"
              onClick={() => setTitle("NABLE SCOPE")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Nable Scope" />
            </ListItemButton>
          )}

          {hasAccess("6") && (
            <ListItemButton
              component={Link}
              to="/standardInOutList"
              onClick={() => setTitle("STANDARD IN/OUT")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Standard In/Out" />
            </ListItemButton>
          )}

          {hasAccess("7") && (
            <ListItemButton
              component={Link}
              to="/enquiry/followUpReport"
              onClick={() => setTitle("Enquiry Followup ")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Enquiry Followup" />
            </ListItemButton>
          )}
          {hasAccess("7") && (
            <ListItemButton
              component={Link}
              to="/feedback/feedbackReport"
              onClick={() => setTitle("Feedback Report")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Feedback Report" />
            </ListItemButton>
          )}

          {hasAccess("10") && (
            <ListItemButton
              component={Link}
              to="/master/pickup/pickupList"
              onClick={() => setTitle("PICKUP")}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Pickup" />
            </ListItemButton>
          )}
          <Collapse in={intermediateProcedure} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/intermediateProcedure"
                onClick={() => setTitle("Intermediate Procedure")}>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Intermediate Procedure" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/checkRecords"
                onClick={() => setTitle("Check Records")}>
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Check Records" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </>
  )
}
