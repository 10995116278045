import React, { useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Button from "@mui/material/Button"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import { Grid, TextField, Typography, Autocomplete } from "@mui/material"
import moment from "moment"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import { BASE_URL } from "./../../global"

// datatablse packages
var $ = require("jquery")
$.DataTable = require("datatables.net")

const axios = require("axios")

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 10 },
  {
    id: "companyName",
    label: "Company Name",
    minWidth: 40,
    align: "left"
  },
  {
    id: "srnNo",
    label: "Srn No.",
    minWidth: 40,
    align: "left"
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    minWidth: 40,
    align: "left"
  },
  {
    id: "ranges",
    label: "Range",
    minWidth: 40,
    align: "left"
  },
  {
    id: "lc",
    label: "Lc",
    minWidth: 40,
    align: "left"
  },
  {
    id: "ducID",
    label: "Duc ID",
    minWidth: 40,
    align: "left"
  },
  {
    id: "calibrationDate",
    label: "Calibration Date",
    minWidth: 40,
    align: "left"
  },
  {
    id: "nextDueDate",
    label: "Due Date",
    minWidth: 40,
    align: "left"
  }
]

function createData(
  id,
  companyName,
  srnNo,
  instrumentName,
  ranges,
  lc,
  ducID,
  calibrationDate,
  nextDueDate
) {
  return {
    id,
    companyName,
    srnNo,
    instrumentName,
    ranges,
    lc,
    ducID,
    calibrationDate,
    nextDueDate
  }
}

export default function WorkReport() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [client, setClient] = React.useState(0)
  const [fromDate, setFromDate] = React.useState(0)
  const [toDate, setToDate] = React.useState(0)
  const [dateData, setDateData] = React.useState([])
  const [data, setData] = React.useState([])
  const [userId, setUserId] = React.useState(null)
  const [users, setUsers] = React.useState([])
  const [status, setStatus] = React.useState(1)
  var rowData = []
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getClient = (event) => {
    let url = BASE_URL
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClient(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  var pushArray = []
  for (let i = 0; i < users.length; i++) {
    pushArray.push({
      id: users[i].id,
      label: users[i].userName
    })
  }

  const FilterData = () => {
    let url = BASE_URL
    axios
      .get(
        url +
          `xjoin?_join=ds.datasheets,_j,user.users,_j,inst.instruments,_j,client.clients&_on1=(ds.assignedUser,eq,user.id)&_on2=(ds.instrumentId,eq,inst.id)&_on3=(ds.clientId,eq,client.id)&_fields=user.userName,ds.id,inst.instrumentName,ds.ranges,ds.DUCID,ds.calibrationDate,ds.nextDueDate,ds.totalReadings,client.companyName,ds.srnNo,ds.lc&_where=(ds.assignedUser,eq, ${userId})`
      )
      .then((res) => {
        debugger

        if (res.data.length) {
          if (userId == null) {
            var resultProductData = res.data.filter(
              (data) =>
                new Date(data.ds_calibrationDate) >= new Date(fromDate) &&
                new Date(data.ds_calibrationDate) <= new Date(toDate)
            )
          } else {
            var resultProductData = res.data.filter(
              (data) =>
                new Date(data.ds_calibrationDate) >= new Date(fromDate) &&
                new Date(data.ds_calibrationDate) <= new Date(toDate)
              // &&
              // data.ds_assignedUser == userId
            )
          }
          setDateData(resultProductData)
          // initializeDataTable(resultProductData)
        } else {
          setDateData([])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUserData = (event) => {
    let url = BASE_URL
    axios
      .get(url + "users?_where=(type,eq,2})")
      .then((res) => {
        setUsers(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getData = (event) => {
    rowData = []
    let url = BASE_URL
    axios
      .get(
        url +
          `xjoin?_join=ds.datasheets,_j,user.users,_j,inst.instruments,_j,client.clients&_on1=(ds.assignedUser,eq,user.id)&_on2=(ds.instrumentId,eq,inst.id)&_on3=(ds.clientId,eq,client.id)&_fields=user.userName,ds.id,inst.instrumentName,ds.ranges,ds.DUCID,ds.calibrationDate,ds.nextDueDate,ds.totalReadings,ds.assignedUser,client.companyName,ds.srnNo,ds.lc&_where=(ds.assignedUser,eq, ${userId})`
      )
      .then((res) => {
        debugger
        if (status === 1) setData(res.data)
        else if (status === 2) {
          // completed
          let data = []
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].ds_totalReadings > 0) {
              data.push(res.data[i])
            }
          }
          setData(data)
        } else if (status === 3) {
          let data = []
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].ds_totalReadings <= 0) {
              data.push(res.data[i])
            }
          }
          setData(data)
        }
        setData(res.data)
        if (res.data.length) {
          if (userId == null) {
            var resultProductData = res.data.filter(
              (data) =>
                new Date(data.ds_calibrationDate) >= new Date(fromDate) &&
                new Date(data.ds_calibrationDate) <= new Date(toDate)
            )
          } else {
            var resultProductData = res.data.filter(
              (data) =>
                new Date(data.ds_calibrationDate) >= new Date(fromDate) &&
                new Date(data.ds_calibrationDate) <= new Date(toDate) &&
                data.ds_assignedUser == userId
            )
          }
          if (status === 1) rowData = resultProductData
          else if (status === 2) {
            // completed
            let data = []
            for (let i = 0; i < resultProductData.length; i++) {
              if (resultProductData[i].ds_totalReadings > 0) {
                data.push(resultProductData[i])
              }
            }
            rowData = data
          } else if (status === 3) {
            let data = []
            for (let i = 0; i < resultProductData.length; i++) {
              if (resultProductData[i].ds_totalReadings <= 0) {
                data.push(resultProductData[i])
              }
            }
            rowData = data
          }
          setDateData(rowData)
          console.log("Filterred Data format : ", rowData)
          //initializeDataTable(rowData)
        } else {
          setDateData([])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " "
      },

      buttons: ["copy", "csv", "excel", "print"]
    })
  }

  useEffect(() => {
    getClient()
    FilterData()
    getUserData()
  }, [])

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Work Report{" "}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={pushArray}
              renderInput={(params) => <TextField {...params} label="Users" />}
              onChange={(event, value) => {
                setUserId(value.id)
              }} // prints the selected value
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={[
                { label: "Assign" },
                { label: "Completed Jobs" },
                { label: "Pending" }
              ]}
              renderInput={(params) => <TextField {...params} label="Status" />}
              onInputChange={(e, newInputValue) => {
                if (newInputValue == "Assign") {
                  setStatus(parseInt(1))
                } else if (newInputValue == "Completed Jobs") {
                  setStatus(parseInt(2))
                } else {
                  setStatus(parseInt(3))
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="From Date"
                inputFormat="dd/MM/yyyy"
                value={fromDate ? fromDate : new Date()}
                onChange={(newValue) => {
                  setFromDate(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Due Date"
                inputFormat="dd/MM/yyyy"
                value={toDate ? toDate : new Date()}
                onChange={(newValue) => {
                  setToDate(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              getData()
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}>
            Search
          </Button>
        </Grid>
        <br />
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Assigned: {dateData.length}
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            id="datatable-keytable">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dateData &&
                dateData.map((row, index) => {
                  return (
                    <TableRow key={row.ds_id}>
                      <TableCell>{row.ds_id}</TableCell>
                      <TableCell>{row.client_companyName}</TableCell>
                      <TableCell>{row.ds_srnNo}</TableCell>
                      <TableCell>{row.inst_instrumentName}</TableCell>
                      <TableCell>{row.ds_ranges}</TableCell>
                      <TableCell>{row.ds_lc}</TableCell>
                      <TableCell>{row.ds_DUCID}</TableCell>
                      <TableCell>{row.ds_calibrationDate}</TableCell>
                      <TableCell>{row.ds_nextDueDate}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rowData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </>
  )
}
