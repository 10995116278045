import React, { useEffect, useState} from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Chip,
  Divider,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import CButton from "./../../../utils/components/Button";
const axios = require("axios");

export function CreateTable(props) {
  const [datasheetColumnName, setDatasheetColumnName] = useState([]);
  const [datasheetConfiguration, setDatasheetConfiguration] = useState({
    values: {},
    formulas: {},
    totalColumns: 0,
    hasCommonHeader: false,
    commonHeaderConfig: null,
  });
  const [certificateColumnName, setCertificateColumnName] = useState([]);
  const [certificateConfiguration, setCertificateConfiguration] =
    useState({
      relations: {},
      formulas: {},
      totalColumns: 0,
      hasCommonHeader: false,
      commonHeaderConfig: null,
    });
  const [condConfiguratio, setCondConfiguration] = useState({
    relations: {},
  });
  const [typeBConfiguration, setTypeBConfiguration] = useState({
    relations: {},
  });

  const [selectedValueCol, setSelectedValueCol] = useState(null);
  const [selectedFormulaCol, setSelectedFormulaCol] = useState(null);
  const [selectedFormulaCol1, setSelectedFormulaCol1] = useState(null);
  const [dsCol, setDsCol] = useState(null);
  const [conditionsCol, setConditionsCol] = useState(null);

  const [selectedCertFormulaCol, setSelectedCertFormulaCol] =
    useState(null);
  const [selectedDatasheetRelCol, setSelectedDatasheetRelCol] =
    useState(null);
  const [selectedCertRelCol, setSelectedCertRelCol] = useState(null);
  const [selectedTypeBRelCol, setSelectedTypeBRelCol] = useState(null);

  var [instrumentArray, setInstrumentArray] = useState([]);
  var [uncertaintyFactors, setUncertaintyFactors] = useState([]);
  const [unitType, setUnitType] = useState("");
  const [instrument, setInstruments] = useState("");
  const [newTableName, setNewTableName] = useState("");
  const [parameterName, setParameterName] = useState("");
  const [isUncertainty, setUncertainty] = useState(false);
  const [sketchPickerColor, setSketchPickerColor] = useState("#D12929");
  const [certificateTableStatus, setCertificateTableStatus ] = useState(1);
  // destructuring rgba from state
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const onSubmit = () => {
    var jsonData = {
      tableName: newTableName,
      unitType: unitType,
      instrumentId: instrument,
      parameterName: parameterName,
      defaultConfiguration: JSON.stringify({
        ...datasheetConfiguration,
        typeb: typeBConfiguration,
      }),
      conditionFormatting: JSON.stringify({
        ...condConfiguratio,
      }),
      isUncertainty: isUncertainty,
    };
    datasheetColumnName.forEach((column, index) => {
      jsonData["c" + (index + 1)] = column;
    });
    
    let url = BASE_URL;
    axios
      .post(url + "datasheetStaticTables", jsonData)
      .then((res) => {
        submitCertificate();
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong3!");
      });
  };

  const submitCertificate = () => {
    var jsonData = {
      tableName: newTableName,
      unitType: unitType,
      instrumentId: instrument,
      defaultConfiguration: JSON.stringify(certificateConfiguration),
      isUncertainty: isUncertainty,
      status: certificateTableStatus
    };
    certificateColumnName.forEach((column, index) => {
      jsonData["c" + (index + 1)] = column;
    });

    axios
      .post(BASE_URL + "certificateStaticTables", jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("new table added!");
        // setTimeout(refresh, 1000);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong in certificate!");
      });
  };

  const getCertificateTableKeysFromCertificateTables = (event) => {
    var splitArr = [];
    let url = BASE_URL;
    axios
      .get(url + "certificateTables")
      .then((res) => {
        if (res.data.length) {
          var sortedData = res.data.reverse()[0];
          var keys = [];
          for (var k in sortedData) keys.push(k);
          keys.splice(keys.length - 12, 12);
          keys.slice(5).forEach(function (value) {
            splitArr.push(value);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // setCertificateKeys(splitArr);
  };
  const addDatasheetColumn = () => {
    setDatasheetColumnName([...datasheetColumnName, ""]);
    updateDatasheetConfiguration(
      "totalColumns",
      null,
      Number(datasheetConfiguration["totalColumns"]) + 1
    );
  };

  const deleteDatasheetColumn = (index) => {
    let newDatasheetColumnName = [...datasheetColumnName];
    newDatasheetColumnName.splice(index, 1);
    setDatasheetColumnName([...newDatasheetColumnName]);
  };
  const updateDatasheetColumnName = (value, index) => {
    const newColumnName = [...datasheetColumnName];
    newColumnName[index] = value;
    setDatasheetColumnName(newColumnName);
  };

  const updateDatasheetConfiguration = (type, column, value) => {
    let _datasheetConfiguration = { ...datasheetConfiguration };
    if (column != null) {
      _datasheetConfiguration[type][column] = value;
    } else {
      _datasheetConfiguration[type] = value;
    }

    setDatasheetConfiguration(_datasheetConfiguration);
  };

  const updateCertificateConfiguration = (
    type,
    column = null,
    value = null
  ) => {
    let _certificateConfiguration = { ...certificateConfiguration };
    if (column != null) {
      _certificateConfiguration[type][column] = value;
    } else if (
      type === "relations" &&
      selectedCertRelCol &&
      selectedDatasheetRelCol
    ) {
      _certificateConfiguration[type][selectedCertRelCol] =
        selectedDatasheetRelCol;
    } else {
      _certificateConfiguration[type] = value;
    }
    setCertificateConfiguration(_certificateConfiguration);
  };

  const updateConditionConfiguration = (type, column = null, value = null) => {
    let _conditionsConfiguration = { ...condConfiguratio };
    if (column != null) {
      _conditionsConfiguration[type][column] = value;
    } else if (type === "relations" && conditionsCol && selectedFormulaCol1) {
      // Greater than", "Less than","Between
      _conditionsConfiguration[type][selectedFormulaCol1] ={
        condition:conditionsCol === 1
        ? "gt"
        : conditionsCol === 2
        ? "lt"
        : "Between",
        dsCol:dsCol,
        color:sketchPickerColor
      }
        
    } else {
      _conditionsConfiguration[type] = value;
    }
    console.log('final call',_conditionsConfiguration)
    setCondConfiguration(_conditionsConfiguration);
  };

  const updateTypeBConfiguration = (type, column = null, value = null) => {
    let _typeBConfiguration = { ...typeBConfiguration };
    if (
      type === "relations" &&
      selectedTypeBRelCol &&
      selectedDatasheetRelCol
    ) {
      _typeBConfiguration[type][selectedDatasheetRelCol] = selectedTypeBRelCol;
    }
    setTypeBConfiguration(_typeBConfiguration);
  };

  const addCertificateColumn = () => {
    setCertificateColumnName([...certificateColumnName, ""]);
    updateCertificateConfiguration(
      "totalColumns",
      null,
      Number(certificateConfiguration["totalColumns"]) + 1
    );
  };
  const deleteCertificateColumn = (index) => {
    let newCertificateColumnName = [...certificateColumnName];
    newCertificateColumnName.splice(index, 1);
    setCertificateColumnName([...newCertificateColumnName]);
  };
  const updateCertificateColumnName = (value, index) => {
    const newColumnName = [...certificateColumnName];
    newColumnName[index] = value;
    setCertificateColumnName(newColumnName);
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchUncertaintyFactors = (instrumentId) => {
    let uncertaintyFactors = instrumentArray.filter(
      (instrument) => instrument.id == instrumentId
    )[0]?.uncertaintyFactors;
    axios
      .get(BASE_URL + `uncertainty?_where=(id,in,${uncertaintyFactors})`)
      .then((res) => {
        setUncertaintyFactors([...res.data]);
      })
      .catch((error) => {
        toast.error("Failed to uncertainty factors!");
      });
  };

  useEffect(() => {
    getCertificateTableKeysFromCertificateTables();
    getInstrumentsList();
  }, []);

  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

  const renderHelpModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Formula Help
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            same as mathematical expression with following variations
            <hr />
            <ul>
              <li>
                1. multiplication: <code>*</code>
              </li>
              <li>
                2. for power: <code>pow(&lt;base value&gt;, &lt;exponent&gt;)</code>
              </li>
              <li>
                3. for square root : <code>sqrt(&lt;value&gt;)</code>, value: either number of column code (eg. c1)
              </li>
            </ul>
          </Typography>
        </Box>
      </Modal>
    );
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
        create new static table
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushIArray}
            onChange={(event, value) => {
              setInstruments(parseInt(value.label.split(",")[0]));
              fetchUncertaintyFactors(value.label.split(",")[0]);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Instrument" />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="New table name"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setNewTableName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Unit"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUnitType(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="parameter name"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setParameterName(e.target.value)}
          />
        </Grid>
      </Grid>

      {/* datasheet table confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Datasheet table configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>

        <div sx={{ mb: 3 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            1. add columns
            <CButton
              variant="contained"
              size="small"
              label={<AddIcon />}
              sx={{ ml: 3 }}
              onClick={() => {
                addDatasheetColumn();
              }}
            ></CButton>
          </Typography>
          <Grid container spacing={1}>
            {datasheetColumnName.map((column, index) => (
              <Grid item xs={4}>
                <OutlinedInput
                  id="outlined-adornment-password"
                  label={"column " + (index + 1) + "(c" + (index + 1) + ")"}
                  value={column}
                  placeholder={
                    "column " + (index + 1) + " (c" + +(index + 1) + ")"
                  }
                  onChange={(e) => {
                    updateDatasheetColumnName(e.target.value, index);
                  }}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          deleteDatasheetColumn(index);
                        }}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </div>
        <br />
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            2. Define common header
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox />}
                label="Has common header"
                onChange={(e) => {
                  updateDatasheetConfiguration(
                    "hasCommonHeader",
                    null,
                    e.target.value
                  );
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Define common header"
                placeholder="Define common header (, for column separation, $: for empty column)"
                size="small"
                fullWidth
                variant="outlined"
                value={datasheetConfiguration?.commonHeaderConfig || ""}
                onChange={(e) =>
                  updateDatasheetConfiguration(
                    "commonHeaderConfig",
                    null,
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="text"
                size="small"
                label={<HelpCenterIcon />}
                onClick={handleOpen}
              ></CButton>
            </Grid>
          </Grid>
        </div>
        <br />
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          3. Set default values (optional)
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValueCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedValueCol(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="outlined-basic"
              label="Add values ( comma separated )"
              size="small"
              fullWidth
              variant="outlined"
              value={datasheetConfiguration?.values[selectedValueCol] || ""}
              onChange={(e) =>
                updateDatasheetConfiguration(
                  "values",
                  selectedValueCol,
                  e.target.value
                )
              }
            />
          </Grid>
        </Grid>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          4. Set formula (optional)
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedFormulaCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedFormulaCol(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-basic"
              label="Add formula"
              size="small"
              fullWidth
              variant="outlined"
              value={datasheetConfiguration?.formulas[selectedFormulaCol] || ""}
              onChange={(e) =>
                updateDatasheetConfiguration(
                  "formulas",
                  selectedFormulaCol,
                  e.target.value
                )
              }
            />
            <div style={{ display: "flex", marginTop: "5px" }}>
              <Chip
                variant="outlined"
                label="range higher"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol] ||
                      "") + " range_high "
                  );
                }}
              />
              <Chip
                variant="outlined"
                label="range lower"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol] ||
                      "") + " range_low "
                  );
                }}
              />
              <Chip
                variant="outlined"
                label="LC"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol] ||
                      "") + " lc "
                  );
                }}
              />
              <Chip
                variant="outlined"
                label="accuracy"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol] ||
                      "") + " accuracy "
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <CButton
              variant="text"
              size="small"
              label={<HelpCenterIcon />}
              onClick={handleOpen}
            ></CButton>
          </Grid>
        </Grid>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          5. Conditional Formatting
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Datasheet Column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedFormulaCol1}
                label="Select column"
                onChange={(e) => {
                  setSelectedFormulaCol1(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Condition</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={conditionsCol}
                label="Select condition"
                onChange={(e) => {
                  console.log(e.target.value);
                  // setSelectedFormulaCol(e.target.value);
                  setConditionsCol(e.target.value);
                }}
                size="small"
              >
                {["Greater than", "Less than", "Between"].map(
                  (columnName, index) => {
                    return <MenuItem value={index + 1}>{columnName}</MenuItem>;
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Columns
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dsCol}
                label="Select column"
                onChange={(e) => {
                  setDsCol(e.target.value);
                  // setSelectedFormulaCol(e.target.value);
                }}
                size="small"
              >
                {["range higher", "range lower", "LC", "accuracy"].map(
                  (columnName, index) => {
                    return <MenuItem value={index + 1}>{columnName}</MenuItem>;
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <input
              type="color"
              value={sketchPickerColor}
              style={{
                backgroundColor: `${sketchPickerColor}`,
                width: 70,
                height: 42,
                borderRadius: "7px",
              }}
              onChange={(e) => {
                setSketchPickerColor(e.target.value);
              }}
            />          
          </Grid>
          <Grid item xs={1}>
            <CButton
              variant="outlined"
              label={"add"}
              onClick={(e) => updateConditionConfiguration("relations")}
            ></CButton>
          </Grid>
          <Grid item xs={4}>
            <h6>relations</h6>
            <pre>{JSON.stringify(condConfiguratio["relations"])}</pre>
          </Grid>
        </Grid>

        <br />
          <Grid item xs={3} style={{alignItems: "start"}}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{display: "flex"}}
              control={
                <Checkbox
                  onChange={(e) => {
                    setCertificateTableStatus(e.target.checked == true ? 0 : 1);
                  }}
                />
              }
              label="Hide in certificate"
            />
          </Grid>
      </div>

      {/* certificate table confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Certificate table configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>
        <br />

        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          add columns
          <CButton
            variant="contained"
            size="small"
            label={<AddIcon />}
            sx={{ ml: 3 }}
            onClick={() => {
              addCertificateColumn();
            }}
          ></CButton>
        </Typography>

        <Grid container spacing={1} sx={{ mb: 3 }}>
          {certificateColumnName.map((column, index) => (
            <Grid item xs={4}>
              <OutlinedInput
                id="outlined-adornment-password"
                label={"column " + (index + 1)}
                value={column}
                placeholder={"column " + (index + 1)}
                onChange={(e) => {
                  updateCertificateColumnName(e.target.value, index);
                }}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        deleteCertificateColumn(index);
                      }}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          ))}
        </Grid>
        <br />
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            2. Define common header
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox />}
                label="Has common header"
                onChange={(e) => {
                  updateCertificateConfiguration(
                    "hasCommonHeader",
                    null,
                    e.target.value
                  );
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Define common header"
                placeholder="Define common header (, for column separation, $: for empty column)"
                size="small"
                fullWidth
                variant="outlined"
                value={certificateConfiguration?.commonHeaderConfig || ""}
                onChange={(e) =>
                  updateCertificateConfiguration(
                    "commonHeaderConfig",
                    null,
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="text"
                size="small"
                label={<HelpCenterIcon />}
                onClick={handleOpen}
              ></CButton>
            </Grid>
          </Grid>
        </div>
        <br />
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Set Relationship
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select datasheet column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDatasheetRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedDatasheetRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select certificate column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCertRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedCertRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {certificateColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateCertificateConfiguration("relations")}
              ></CButton>
            </Grid>
            <Grid item xs={4}>
              <h6>relations</h6>
              <pre>{JSON.stringify(certificateConfiguration["relations"])}</pre>
            </Grid>
          </Grid>
        </div>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Set formula (optional)
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCertFormulaCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedCertFormulaCol(e.target.value);
                }}
                size="small"
              >
                {certificateColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-basic"
              label="Add formula"
              size="small"
              fullWidth
              variant="outlined"
              value={
                certificateConfiguration?.formulas[selectedCertFormulaCol] || ""
              }
              onChange={(e) =>
                updateCertificateConfiguration(
                  "formulas",
                  selectedCertFormulaCol,
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={1}>
            <CButton
              variant="text"
              size="small"
              label={<HelpCenterIcon />}
              onClick={handleOpen}
            ></CButton>
          </Grid>
          <br />
          <Grid item xs={3} style={{alignItems: "start"}}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{display: "flex"}}
              control={
                <Checkbox
                  onChange={(e) => {
                    setUncertainty(e.target.checked);
                  }}
                />
              }
              label="Expanded Uncertainty"
            />
          </Grid>
        </Grid>
      </div>

      {/* type-b confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Type-B configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Set Relationship(optional)
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Datasheet column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDatasheetRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedDatasheetRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Type-B column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedTypeBRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedTypeBRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {uncertaintyFactors.map((uf, index) => {
                    return (
                      <MenuItem value={uf.id} key={uf.id}>
                        {uf.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateTypeBConfiguration("relations")}
              ></CButton>
            </Grid>
            <Grid item xs={4}>
              <h6>relations</h6>
              <pre>{JSON.stringify(typeBConfiguration["relations"])}</pre>
            </Grid>
          </Grid>
        </div>
        <br />
      </div>

      <hr />
      <Toolbar style={{ padding: "0px" }}>
        <CButton
          variant="contained"
          label="SAVE"
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          SAVE
        </CButton>
      </Toolbar>
      <ToastContainer />
      {renderHelpModal()}
    </Paper>
  );
}
