import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { BASE_URL } from "./../../global";
import { Grid, TextField, Toolbar } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { checkDSStatusByCount } from "./countTester";

// datatablse packages
var $ = require("jquery")
$.DataTable = require("datatables.net")

const axios = require("axios")
const userType = localStorage.getItem("type")
const columns =
  userType !== "2"
    ? [
        {
          id: "checkMark",
          label: "Select",
          align: "center",
          minWidth: 10
        },
        {
          id: "id",
          label: "Sr. No.",
          align: "left",
          minWidth: 40
        },
        {
          id: "progress",
          label: "Progress",
          align: "left",
          minWidth: 40
        },
        {
          id: "customerName",
          label: "Customer Name",
          align: "left",
          minWidth: 40
        },
        {
          id: "instrumentName",
          label: "Instrument Name",
          align: "left",
          minWidth: 40
        },
        {
          id: "range",
          label: "Range",
          align: "left",
          minWidth: 40
        },
        {
          id: "DUCID",
          label: "Duc Id",
          align: "left",
          minWidth: 40
        },
        {
          id: "serialNumber",
          label: "Serial Number",
          align: "left",
          minWidth: 40
        },
        {
          id: "dateOfCalibration",
          label: "Date of Calibration",
          minWidth: 40,
          align: "left"
        },
        {
          id: "nextDueDate",
          label: "Due Date",
          minWidth: 40,
          align: "left"
        },
        {
          id: "assignStatus",
          label: "Assign status",
          minWidth: 40,
          align: "left"
        }
      ]
    : [
        {
          id: "id",
          label: "Sr. No.",
          align: "left",
          minWidth: 40
        },
        {
          id: "progress",
          label: "Progress",
          align: "left",
          minWidth: 40
        },
        {
          id: "customerName",
          label: "Customer Name",
          align: "left",
          minWidth: 40
        },
        {
          id: "instrumentName",
          label: "Instrument Name",
          align: "left",
          minWidth: 40
        },
        {
          id: "range",
          label: "Range",
          align: "left",
          minWidth: 40
        },
        {
          id: "DUCID",
          label: "Duc Id",
          align: "left",
          minWidth: 40
        },
        {
          id: "serialNumber",
          label: "Serial Number",
          align: "left",
          minWidth: 40
        },
        {
          id: "dateOfCalibration",
          label: "Date of Calibration",
          minWidth: 40,
          align: "left"
        },
        {
          id: "nextDueDate",
          label: "Due Date",
          minWidth: 40,
          align: "left"
        },
        {
          id: "assignStatus",
          label: "Assign status",
          minWidth: 40,
          align: "left"
        }
      ]

export default function DatasheetList() {
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [datasheet, setDatasheet] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [enableDisable, setEnableData] = React.useState("");
  const [usersArray, setUsersArray] = React.useState([]);
  const [allUsers, setUsers] = React.useState([]);
  const [datasheetReadings, setDatasheetReadings] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [dBStatus, setDBStatus] = React.useState("")
  const showJobAssign = userType !== "2" ? true : false

  const updateEnableDisable = (value, id) => {
    const newColumnName = [...enableDisable]
    newColumnName[id] = id + "," + value
    setEnableData(newColumnName)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  if (enableDisable.length > 0) {
    var result = enableDisable
      .map(function (item) {
        if (item) {
          let [id, closed] = item.split(",")
          return closed === "true" ? id : null
        }
      })
      .filter(function (item) {
        return item != undefined && item != null
      })
  }
  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1
    }
    let url = BASE_URL
    axios
      .patch(url + `datasheets/${id}`, jsonData)
      .then((res) => {
        toast("datasheet deleted!")
        setTimeout(refresh, 500)
      })
      .catch((error) => {
        toast.error("Something Went Wrong!")
      })

    axios
      .patch(url + `certificates/${id}`, jsonData)
      .then((res) => {
        toast("certificate deleted!")
        setTimeout(refresh, 500)
      })
      .catch((error) => {
        toast.error("Something Went Wrong!")
      })
  }

  // api calls
  function getTotalRows() {
    var data = {
      query: `select count(*) as no_of_rows from datasheets`
    }
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows)
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err)
      })
  }

  function fetchDatasheet() {
    let whereConditions = "where ds.status != -1 "
    let pagination_settings = ""
    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Inst. Name": "ins.instrumentName",
        "Cust. Name": "cust.companyName",
        Range: "ds.ranges",
        DUC: "ds.DUCID",
        "Serial Number": "ds.serialNumber"
      }[searchBy]
      whereConditions += `and ${colName} like '%${searchKey}%' `
    }

    if (to != null && from != null) {
      whereConditions += ` and ds.calibrationDate between '${moment(
        from
      ).format("YYYY-MM-DD")}' and '${moment(to).format("YYYY-MM-DD")}' `
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`
    }

    let data = {
      query: `select ds.clientId,cust.companyName, ins.instrumentName,ins.id as insId,ds.id,ds.workOrderNumber,ds.certificateNumber,ds.calibrationDate,ds.calProcRefNo,ds.ranges,ds.DUCID,ds.identificationNo, ds.nextDueDate,ds.dateOfIssue,ds.assignedUser,ds.serialNumber from datasheets as ds left join instruments as ins on ds.instrumentId = ins.id left join clients as cust on ds.clientId = cust.id  ${whereConditions} order by ds.id DESC ${pagination_settings}`
    }
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setDatasheet([...res.data])
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err)
      })
  }

  const initializeDsTable = () => {
    for (let i = 0; i < datasheet.length; i++) {
      if (datasheet[i].dateOfIssue != null) {
        datasheet[i]["isProgress"] = 90
      } else if (
        datasheetReadings.some((data) => data.datasheetId === datasheet[i].id)
      ) {
        datasheet[i]["isProgress"] = 70
      } else {
        datasheet[i]["isProgress"] = 30
      }
    }
    setFinalData(datasheet)
  }

  useEffect(() => {
    initializeDsTable()
  }, [datasheet])

  // api calls
  function fetchDatasheetReadings() {
    let url = BASE_URL
    axios
      .get(url + `datasheetStaticReadings`)
      .then(async (res) => {
        setDatasheetReadings(res.data)
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err)
      })
  }

  var refresh = () => {
    window.location.reload(false)
    // <Link to="/master/master/standard">Home</Link>
  }

  const getUserList = () => {
    let url = BASE_URL
    axios
      .get(url + "users?_where=(type,eq,2)")
      .then((res) => {
        setUsersArray(res.data)
      })
      .catch((error) => {
        toast.error("Something Went Wrong!")
      })
  }
  const initializeuserData = () => {
    var pushCArray = []
    for (let i = 0; i < usersArray.length; i++) {
      pushCArray.push({
        label: usersArray[i].id + ", " + usersArray[i].userName
      })
    }
    setUsers(pushCArray)
  }

  const saveUserAndDsID = (userId, dsId) => {
    let url = BASE_URL

    var data = {}
    var dsIdArr = dsId.split(",")
    if (userId) {
      dsIdArr.forEach((element) => {
        let data = {
          query: `UPDATE datasheets SET assignedUser = ${userId} WHERE id=${element};`
        }
        axios
          .post(BASE_URL + `dynamic`, data)
          .then((res) => {
            toast("Datasheet Assigned.")
          })
          .catch((error) => {
            toast.error("Something Went Wrong!")
          })
      })
    }
  }

  const search = () => {
    fetchDatasheet()
  }

  useEffect(() => {
    checkDSStatusByCount(setDBStatus)
    getTotalRows();
    getUserList();
    fetchDatasheetReadings();
  }, []);

  useEffect(() => {
    initializeuserData()
  }, [usersArray])

  useEffect(() => {
    fetchDatasheet()
  }, [rowsPerPage, page])

  const TablePaginationActions = (props) => {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0)
    }

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1)
    }

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page">
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page">
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page">
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page">
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    )
  }

  return (
    <>
    {dBStatus === "sick" ? <h1 style={{color: "red"}}> Datasheet count is mismatching.<br/> please contact the administrator!</h1> : null}
    { dBStatus !== "sick" && <TableContainer component={Paper} sx={{ mt: 2 }}>
      <br />
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end" }}>
        {showJobAssign && result && result.length ? (
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginRight: "10px",
              marginLeft: "10px"
            }}
            size="small"
            id="combo-box-demo"
            options={allUsers}
            renderInput={(params) => (
              <TextField {...params} label="Job Assign" />
            )}
            onInputChange={(event, newInputValue) => {
              saveUserAndDsID(
                parseInt(newInputValue.split(",")[0]),
                result ? result.toString() : ""
              )
            }}
          />
        ) : (
          console.log("select is empty")
        )}

        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DesktopDatePicker
              label="From"
              value={from}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="To"
              value={to}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px"
          }}
          size="small"
          id="combo-box-demo"
          options={[
            { key: "customerName", label: "Cust. Name" },
            { key: "instrumentName", label: "Inst. Name" },
            { key: "range", label: "Range" },
            { key: "duc", label: "DUC" },
            { key: "serialNumber", label: "Serial Number" }
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue)
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search()
          }}>
          <SearchIcon />
        </Button>
      </div>

      <div style={{ margin: "10px" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          class="table-responsive"
          size="small"
          id="datatable-keytable">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {finalData &&
              finalData.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {userType != "2" ? (
                      <TableCell>
                        <Grid item xs={2}>
                          <FormControlLabel
                            classes={{
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                            sx={{
                              gap: 2
                            }}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  updateEnableDisable(e.target.checked, row.id)
                                }}
                              />
                            }
                          />
                        </Grid>
                      </TableCell>
                    ) : null}
                    <TableCell>
                      {rowsPerPage * Number(page) + index + 1}
                    </TableCell>

                    <TableCell>{row.isProgress + "%"}</TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell>{row.instrumentName}</TableCell>
                    <TableCell>{row.ranges}</TableCell>
                    <TableCell>{row.DUCID}</TableCell>
                    <TableCell>{row.serialNumber}</TableCell>
                    <TableCell>
                      {row.calibrationDate
                        ? moment(row.calibrationDate).format("DD-MM-YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {row.nextDueDate
                        ? moment(row.nextDueDate).format("DD-MM-YYYY")
                        : ""}
                    </TableCell>
                    <TableCell style={{ color: "green" }}>
                      {row.assignedUser != null ? "Assigned" : ""}
                    </TableCell>
                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Edit Datasheet" placement="top-start">
                          <Button
                            component={Link}
                            to={`/datasheet/edit/${row.id}/${row.insId}`}>
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Print Datasheet" placement="top-start">
                          <Button
                            component={Link}
                            to={`/datasheet/edit/${row.id}`}>
                            <PrintIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete Datasheet" placement="top-start">
                          <Button
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete datasheet?"
                                )
                              ) {
                                submitSiteDelete(row.id)
                              }
                            }}>
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page"
            },
            native: true
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </div>
    </TableContainer>}
    </>
   
  );
}
