const base = 1; // 1 minus actual columns
const maxColumns = 15;

function resolveFormulas(readings, config, precisionCount, datasheet) {
  config.forEach((config) => {
    let tableId = config.tableId;
    let formulas = config.formulas;
    Object.keys(formulas).forEach((column, rIndex) => {
      let index = Number(column.split("c")[1]) + base;
      for (const element of readings) {
        // skip for units
        if (String(element[index])?.includes("_unit_")) continue;

        let formula = formulas[column]?.toLowerCase();

        if (element[0] == tableId) {
          // replace datasheet fields with values
          Object.keys(datasheet || {}).forEach((key) => {
            if (formula.includes(key)) {
              formula = formula.replaceAll(key, (Number(datasheet[key]) || 0));
            }
          });

          // replace column representaitons with values
          for (let j = maxColumns - 1; j >= 0; j--) {
            if (formula?.includes("c" + j)) {
              formula = formula.replaceAll("c" + j, element[j + base]);
            }
          }
          formula = formula.replaceAll("$", "");
          // resolve -- terms
          formula = formula.replaceAll("--", "+");
          // redefine math formulas
          for(const val of ["sqrt", "pow"])
            formula = formula.replaceAll(val, "Math."+val);

          try {
            // eslint-disable-next-line no-eval
            element[index] = Number(eval(formula)).toFixed(precisionCount);
          } catch {
            // do not update value
          }
        }
      }
    });
  });
  return readings;
}

// datasheet ====================
export function prepareDatasheetReadings(props) {
  let { config, readings, precisionCount, datasheet } = props;
  return resolveFormulas(readings, config, precisionCount, datasheet);
}

// certificates =================
function resolveRelations(datasheetReadings, config) {
  let certificateReadings;

  // init cert. readings
  let allCertificateReadings = [];
  config.forEach((config) => {
    let tableId = config.tableId;
    let totalColumns = Number(config.totalColumns);
    let relations = config.relations;

    certificateReadings = datasheetReadings.map((datasheetReading) => {
      if (datasheetReading[0] == tableId) {
        let certificateReading = [
          datasheetReading[0],
          datasheetReading[1],
          ...new Array(totalColumns).fill(null),
          0,
        ];

        Object.keys(relations).forEach((key, rIndex) => {
          let leftIndex = Number(key.split("c")[1]);
          let rightIndex = Number(relations[key].split("c")[1]);
          certificateReading[leftIndex + base] =
            datasheetReading[rightIndex + base];
        });
        return certificateReading;
      }
    });
    allCertificateReadings.push(...certificateReadings);
  });

  certificateReadings = allCertificateReadings.filter(
    (row) => row != undefined
  );

  return certificateReadings;
}

export function prepareCertificateReadings(props) {
  let { config, datasheetReadings, precisionCount } = props;
  //   initiate rows

  let updatedReadingRows = resolveRelations(datasheetReadings, config);
  return resolveFormulas(updatedReadingRows, config, precisionCount);
}

// typeB ==============================
export function prepareTypeBValues(typeBConfiguration, datasheetReading){
  let typeBValues= {};
  Object.keys(typeBConfiguration).forEach(key => {
    // structure whould be {uncertaintyFactor : value, ...}
    typeBValues[typeBConfiguration[key]] = datasheetReading[key];
  })
  return JSON.stringify(typeBValues);

}

