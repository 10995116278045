import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { ClassicTable } from "./../../utils/components/Styles";

const axios = require("axios");

export default function CreateSRF(props) {
  const navigate = useNavigate();
  var [srfInstruments, setSrfInstruments] = React.useState([]);
  var [allInstruments, setInstruments] = React.useState([]);
  const [clientName, setClientName] = React.useState("");
  const [receiptDate, setReceiptDate] = React.useState("");
  const [poNumber, setPoNumber] = React.useState("");
  const [poDate, setpotDate] = React.useState("");
  const [committedDeliveryDate, setCommittedDeliveryDate] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [jobNumber, setJobNumber] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [dcDate, setDcDate] = React.useState("");
  const [entryDate, setEntryDate] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [readingRows, setReadingRows] = React.useState([]);

  const params = useParams();
  const getSrfsList = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=srf.srfs,_j,client.clients&_on1=(srf.clientId,eq,client.id)&_fields=srf.serviceReqNumber,srf.jobNumber,srf.poNumber,srf.poDate,srf.receiptDate,srf.committedDeliveryDate,srf.dcNumber,srf.dcDate,srf.entryDate,srf.description,client.address,client.companyName&_where=(srf.id,eq, ${params.id})`
      )
      .then((res) => {
        setServiceReqNumber(res.data[0].srf_serviceReqNumber);
        setJobNumber(res.data[0].srf_jobNumber);
        setPoNumber(res.data[0].srf_poNumber);
        setpotDate(res.data[0].srf_poDate);
        setReceiptDate(res.data[0].srf_receiptDate);
        setDcNumber(res.data[0].srf_dcNumber);
        setDcDate(res.data[0].srf_dcDate);
        setEntryDate(res.data[0].srf_entryDate);
        setDescription(res.data[0].srf_description);
        setCommittedDeliveryDate(res.data[0].srf_committedDeliveryDate);
        setAddress(res.data[0].client_address);
        setClientName(res.data[0].client_companyName);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})`)
      .then((res) => {
        setSrfInstruments(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const instrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `instruments?_where=(status,eq,1`)
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "description",
        headerName: "Instruments",
        editable: true,
      },
      {
        field: "range",
        headerName: "DUC Range",
        editable: true,
      },
      {
        field: "lc",
        headerName: "Least Count",
        editable: true,
      },
      {
        field: "serialNumber",
        headerName: "Serial number",
        editable: true,
      },
      {
        field: "make",
        headerName: "DUC Make",
        editable: true,
      },
      {
        field: "model",
        headerName: "Model",
        editable: true,
      },
      {
        field: "ducID",
        headerName: "DUCID",
        editable: true,
      },
      {
        field: "conOfDuc",
        headerName: "Con. Of Duc",
        editable: true,
      },
      {
        field: "calibrationFrequency",
        headerName: "Calibration frequency",
        editable: true,
      },
      {
        field: "accuracy",
        headerName: "Accuracy",
        editable: true,
      },
      {
        field: "calibrationType",
        headerName: "Calibration Type",
        editable: true,
      },
      {
        field: "location",
        headerName: "Location",
        editable: true,
      },
      {
        field: "locationOfInstrument",
        headerName: "Location Of Instrument",
        editable: true,
      },
      {
        field: "calPoint",
        headerName: "Calibration Point",
        editable: true,
      },
      {
        field: "calMethod",
        headerName: "Calibration Method",
        editable: true,
      },
      {
        field: "labCapabilities",
        headerName: "Lab Capabilities",
        editable: true,
      },
    ],
  ];

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < srfInstruments.length; i++) {
      rows.push([
        srfInstruments[i].id,
        srfInstruments[i].instrumentId,
        srfInstruments[i].ranges,
        srfInstruments[i].lc,
        srfInstruments[i].serialNumber,
        srfInstruments[i].make,
        srfInstruments[i].model,
        srfInstruments[i].DUCID,
        srfInstruments[i].ConOfDuc,
        srfInstruments[i].calFrequency,
        srfInstruments[i].accuracy,
        srfInstruments[i].calibrationType,
        srfInstruments[i].location,
        srfInstruments[i].locationOfInstrument,
        srfInstruments[i].calPoint,
        srfInstruments[i].calMethod,
        srfInstruments[i].labCapabilities,
      ]);
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < allInstruments.length; j++) {
        if (allInstruments[j].id === rows[i][1]) {
          rows[i][1] = rows[i][0] + ", " + allInstruments[j].instrumentName;
          // delete rows[i][0];
        }
      }
    }
    console.log(rows);
    setReadingRows(rows);
  };

  useEffect(() => {
    instrumentsList();
  }, []);

  useEffect(() => {
    srfInstrumentsList();
    getSrfsList();
  }, []);

  useEffect(() => {
    initiateRanges();
  }, [srfInstruments, allInstruments]);

  const submitDescriptionRows2 = (readingRows, insertedID, id) => {
    let rows = [];
    for (let i = 0; i < readingRows.length; i++) {
      rows.push({
        // srfsId: parseInt(params.id),
        srfInstrumentId: parseInt(readingRows[i][1].split(",")[0]),
        ranges: readingRows[i][2],
        lc: readingRows[i][3],
        serialNumber: readingRows[i][4],
        make: readingRows[i][5],
        model: readingRows[i][6],
        DUCID: readingRows[i][7],
        ConOfDuc: readingRows[i][8],
        // calFrequency: readingRows[i][9],
        accuracy: readingRows[i][10],
        calibrationType: readingRows[i][11],
        location: readingRows[i][12],
        locationOfInstrument: readingRows[i][13],
        calMethod: readingRows[i][14],
        // labCapabilities: readingRows[i][16],
        id: readingRows[i][0],
      });
      Number(readingRows[i][0]);
    }
    console.log(rows);
    for (let i = 0; i < rows.length; i++) {
      axios
        .patch(BASE_URL + `datasheets/${rows[i].id}`, rows[i])
        .then((res) => {
          props.setLoader(false);
          // toast("standards Updated successfully !");
          // setTimeout(refresh, 1000);
        })
        .catch((error) => {
          props.setLoader(false);
          toast.error("Something Went Wrong!");
        });
    }

    // //create datasheet
    // rows.map((rows) => {
    //   let url = BASE_URL;
    //   axios
    //     .post(url + "datasheets", rows)
    //     .then((res) => {
    //       // toast("datasheets created successfully !");
    //       // setTimeout(refresh, 1000);
    //     })
    //     .catch((error) => {
    //       toast.error("Something Went Wrong!");
    //     });
    // });

    for (let i = 0; i < rows.length; i++) {
      axios
        .patch(BASE_URL + `certificates/${rows[i].id}`, rows[i])
        .then((res) => {
          props.setLoader(false);
          // toast("standards Updated successfully !");
          // setTimeout(refresh, 1000);
        })
        .catch((error) => {
          props.setLoader(false);
          toast.error("Something Went Wrong!");
        });
    }

    //create certificate
    // rows.map((rows) => {
    //   let url = BASE_URL;
    //   axios
    //     .post(url + "certificates", rows)
    //     .then((res) => {
    //       // toast("datasheets created successfully !");
    //       // setTimeout(refresh, 1000);
    //     })
    //     .catch((error) => {
    //       toast.error("Something Went Wrong!");
    //     });
    // });
  };

  const handleSubmit = (event) => {
    let rows = [];
    for (let i = 0; i < readingRows.length; i++) {
      console.log(readingRows[i][1])
      rows.push({
        srfsId: parseInt(params.id),
        instrumentId: parseInt(readingRows[i][1]),
        ranges: readingRows[i][2],
        lc: readingRows[i][3],
        serialNumber: readingRows[i][4],
        make: readingRows[i][5],
        model: readingRows[i][6],
        DUCID: readingRows[i][7],
        ConOfDuc: readingRows[i][8],
        calFrequency: readingRows[i][9],
        accuracy: readingRows[i][10],
        calibrationType: readingRows[i][11],
        location: readingRows[i][12],
        locationOfInstrument: readingRows[i][13],
        calMethod: readingRows[i][14],
        labCapabilities: readingRows[i][16],
        id: readingRows[i][0],
      });
      Number(readingRows[i][0]);
    }

    // insert new records
    if (rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        axios
          .patch(BASE_URL + `srfInstruments/${rows[i].id}`, rows[i])
          .then((res) => {
            props.setLoader(false);
            // toast("standards Updated successfully !");
            // setTimeout(refresh, 1000);
          })
          .catch((error) => {
            props.setLoader(false);
            toast.error("Something Went Wrong!");
          });
      }
      // toast("standards Updated successfully !");
    }

    let row = {
      receiptDate: moment(receiptDate).format("YYYY-MM-DD"),
      dcNumber: dcNumber,
      dcDate: moment(dcDate).format("YYYY-MM-DD"),
      entryDate: moment(entryDate).format("YYYY-MM-DD"),
      committedDeliveryDate: moment(committedDeliveryDate).format("YYYY-MM-DD"),
      serviceReqNumber: serviceReqNumber,
      jobNumber: jobNumber,
      description: description,
      address: address,
      poNumber: poNumber,
      poDate: moment(poDate).format("YYYY-MM-DD"),
      // billingId:srfObject.billingId,
    };

    let url = BASE_URL;
    axios
      .patch(url + `srfs/${params.id}`, row)
      .then((res) => {
        toast("SRF updated successfully !");
        var insertedID = res.data.insertId;
        // submitDescriptionRows(readingRows, insertedID);
        // submitDescriptionRows2(readingRows, insertedID, row.clientId);
        props.setLoader(false);
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push([
      -1,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
  };
  var refresh = () => {
    window.location.reload(false);
    navigate("/srf");
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Update SRF
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Company Name *"
            size="small"
            fullWidth
            disabled
            value={clientName}
            variant="outlined"
            onChange={(e) => setClientName(e.target.value)}
          />
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Receipt Date *"
              inputFormat="dd/MM/yyyy"
              value={receiptDate ? receiptDate : new Date()}
              // onChange={(newValue) => {
              //   updateSrfObject("receiptDate", newValue);
              // }}
              onChange={(newValue) => setReceiptDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Dc No./RGP No. *"
            size="small"
            fullWidth
            value={dcNumber}
            variant="outlined"
            onChange={(e) => setDcNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="DC Dt./RGP Dt. *"
              value={dcDate ? dcDate : new Date()}
              inputFormat="dd/MM/yyyy"
              // onChange={(newValue) => {
              //   updateSrfObject("poDate", newValue);
              // }}
              onChange={(newValue) => setDcDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Entry Date *"
              value={entryDate ? entryDate : new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => setEntryDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Committed delivery date *"
              value={committedDeliveryDate ? committedDeliveryDate : new Date()}
              inputFormat="dd/MM/yyyy"
              // onChange={(newValue) => {
              //   updateSrfObject("committedDeliveryDate", newValue);
              // }}
              onChange={(newValue) => setCommittedDeliveryDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Service request number *"
            size="small"
            fullWidth
            value={serviceReqNumber}
            variant="outlined"
            // onChange={(e) => {
            //   updateSrfObject("serviceReqNumber", e.target.value);
            // }}
            onChange={(e) => setServiceReqNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Job number"
            size="small"
            fullWidth
            value={jobNumber}
            variant="outlined"
            // onChange={(e) => {
            //   updateSrfObject("serviceReqNumber", e.target.value);
            // }}
            onChange={(e) => setJobNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            fullWidth
            value={address}
            disabled
            variant="outlined"
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="PO Number *"
            size="small"
            fullWidth
            value={poNumber}
            variant="outlined"
            onChange={(e) => setPoNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="PO Date *"
              value={poDate ? poDate : new Date()}
              inputFormat="dd/MM/yyyy"
              // onChange={(newValue) => {
              //   updateSrfObject("poDate", newValue);
              // }}
              onChange={(newValue) => setpotDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Committed delivery date *"
              value={committedDeliveryDate ? committedDeliveryDate : new Date()}
              inputFormat="dd/MM/yyyy"
              // onChange={(newValue) => {
              //   updateSrfObject("committedDeliveryDate", newValue);
              // }}
              onChange={(newValue) => setCommittedDeliveryDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <hr />
      <h4 style={{ "margin-bottom": "0px", "margin-top": "12px" }}>
        Description as below
      </h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addRangeRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 1 &&
                      cellIndex != 9 &&
                      (cellIndex != 11) &
                        (cellIndex != 0) &
                        (cellIndex != 12) &&
                      cellIndex != 14 &&
                      cellIndex != 15
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 1) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            disabled
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 9) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "3 Months" },
                                { id: 2, label: "6 Months" },
                                { id: 3, label: "12 Months" },
                                { id: 6, label: "18 Months" },
                                { id: 5, label: "24 Months" },
                              ]}
                              value={cell}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 11) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              defaultValue={cell == 1 ? "NABL" : "NON NABL"}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                {
                                  id: 1,
                                  label: "NABL",
                                },
                                {
                                  id: 2,
                                  label: "NON NABL",
                                },
                                {
                                  id: 3,
                                  label: "OFF RECORD",
                                },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "NABL") {
                                  updateCellValue(index, cellIndex, 0);
                                } else if (newInputValue === "NON NABL") {
                                  updateCellValue(index, cellIndex, 1);
                                } else {
                                  updateCellValue(index, cellIndex, 2);
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 12) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              defaultValue={cell == 1 ? "NABL" : "NON NABL"}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                {
                                  id: 1,
                                  label: "Onsite",
                                },
                                {
                                  id: 2,
                                  label: "In house",
                                },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "Onsite") {
                                  updateCellValue(index, cellIndex, "Onsite");
                                } else {
                                  updateCellValue(index, cellIndex, "In house");
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 14) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "As per proc" },
                                { id: 2, label: "As per customer" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={
                                cell == "As per proc"
                                  ? "As per proc"
                                  : "As per customer"
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "As per proc") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "As per proc"
                                  );
                                  // changeCertificateStatus(3 + ":" + result.toString());
                                } else if (
                                  newInputValue === "As per customer"
                                ) {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "As per customer"
                                  );
                                  // changeCertificateStatus(4 + ":" + result.toString());
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 15) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "Direct" },
                                { id: 2, label: "Comparison" },
                                { id: 3, label: "Direct/Comparison" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={
                                cell == "Direct"
                                  ? "Direct"
                                  : cell == "Comparison"
                                  ? "Comparison"
                                  : "Direct/Comparison"
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "Direct") {
                                  updateCellValue(index, cellIndex, "Direct");
                                } else if (newInputValue === "Comparison") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "Comparison"
                                  );
                                } else {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "Direct/Comparison"
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <Stack style={{ "margin-top": "20px" }} justifyContent="start">
        <Typography align="left">Description</Typography>
        <TextareaAutosize
          minRows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter your description here"
          style={{ width: 900 }}
        />
      </Stack>
      <div style={{ "margin-top": "20px" }}>
        <Typography align="left">
          Conformity of Statement Requirement:
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Typography>
      </div>
      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            handleSubmit();
          }}
        >
          Update
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
