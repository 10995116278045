import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from "@mui/x-data-grid-generator";
import { ClassicTable } from "../../utils/components/Styles";
import { API_URL } from "../../../src/global";
import { insert, view } from "../../components/common/api";
import { integerPropType } from "@mui/utils";

const axios = require("axios");

export default function CreateInvoice() {
  var [ProductsList, setProductList] = React.useState("");
  const [customer, setCustomer] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [rate, setRate] = React.useState("");
  const [name, setProductName] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [address, setAddress] = React.useState("");

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "Product Name",
        headerName: "Product Name",
        editable: true,
      },
      {
        field: "Rate",
        headerName: "Rate",
        editable: true,
      },
      {
        field: "Quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "Discount",
        headerName: "Discount",
        editable: true,
      },
      {
        field: "Total Amount",
        headerName: "Total Amount",
        editable: true,
      },
    ],
  ];
  const [readingRows, setReadingRows] = React.useState([
    ["", `${rate}`, "", ""],
  ]);

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", `${rate}`, "", ""]);
    setReadingRows([...newReadingRows]);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var pushArray = [];

  for (let i = 0; i < customer.length; i++) {
    pushArray.push({
      label: customer[i].companyName,
      contact: customer[i].contact,
      address: customer[i].address,
    });
  }

  const getProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "products?_where=(status,eq,1)")
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  var productArray = [];

  for (let i = 0; i < ProductsList.length; i++) {
    productArray.push({
      label: ProductsList[i].id + "," + ProductsList[i].name,
      rate: ProductsList[i].rate,
    });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    getCustomer();
    getProductList();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Invoice
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushArray}
            renderInput={(params) => (
              <TextField {...params} label="Company Name" />
            )}
            onChange={(event, value) => {
              if (value != null) {
                setContact(value.contact);
                setAddress(value.address);
              } else {
                setContact("");
                setAddress("");
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Number"
            size="small"
            value={contact}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              //   updateSrfObject("standardName", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            value={address}
            fullWidth
            variant="outlined"
            onChange={(e) => {}}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Invocie Number"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {}}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Subject"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {}}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="yyyy/MM/dd"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Conveyance Charges"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {}}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="PO Number"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {}}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Final Discount"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {}}
          />
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Product Details </h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex != 0 && cellIndex != 1 && cellIndex != 3) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 0) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Autocomplete
                            size="small"
                            id="combo-box-demo"
                            options={productArray}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event, value) => {
                              if (value != null) {
                                setRate(value.rate);
                                setProductName(value.name);
                              } else {
                                setRate("");
                                setProductName("");
                              }
                            }}
                          />
                        </TableCell>
                      );
                    }
                    if (cellIndex == 1) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              // disabled
                              InputLabelProps={{ shrink: true }}
                              value={readingRows[index][cellIndex]}
                              variant="outlined"
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  3,
                                  parseInt(200) + parseInt(e.target.value)
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={readingRows[index][cellIndex]}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            // handleSubmit();
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            // handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
