import moment from "moment"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import html2pdf from "html2pdf.js"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography
} from "@mui/material"

import { Image, Tab } from "react-bootstrap"
import { useSnackbar } from "notistack"
import QRCode from "react-qr-code"
import { useReactToPrint } from "react-to-print"
import { generateNumber } from "../../utils/utils"
import { BASE_URL, MAX_COLS, DOMAIN, ID_BASE } from "./../../global"
import { ClassicTable } from "./../../utils/components/Styles"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Global from "../image/globalLetterHead.png"
import MuiTableCell from "@material-ui/core/TableCell"

import "./certificate.css"
const TableCell = withStyles({
  root: {
    border: "none",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "13px",
    paddingLeft: "5px"
  }
})(MuiTableCell)
const axios = require("axios")
const useStyles = makeStyles({
  table: {
    border: "1px solid black",
    padding: "1px"
  },
  infoCell: {
    maxHeight: "5px"
  },
  innerCell: {
    padding: "0px",
    maxWidth: "40%"
  },
  formatedCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px"
  },
  certificateCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
    width: "20%"
  }
})
export default function EditCertificate(props) {
  const printComponentRef = React.useRef()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { certificateId } = useParams()
  const [staticReadingRows, setStaticReadingRows] = React.useState([])
  const [staticCertificateReadings, setStaticCertificateReadings] =
    React.useState([])

  const [instrument, setInstrument] = React.useState(null)
  const [tables, setTables] = React.useState(null)
  const [staticTables, setStaticTables] = React.useState(null)
  const [configuration, setConfiguration] = React.useState({})
  const [clientId, setClientId] = React.useState("")
  const [instrumentArray, setInstrumentArray] = React.useState([])
  const [standardMasterArray, setStandardMasterArray] = React.useState([])
  const [instrumentName, setInstrumentName] = React.useState("")
  const [make, setMake] = React.useState("")
  const [calibrationProcedureNo, setCalibrationProcedureNo] = React.useState("")
  const [referenceStandards, setReferenceStandards] = React.useState("")
  const [lc, setLC] = React.useState("")
  const [dcNumber, setDcNumber] = React.useState("")
  const [ranges, setRanges] = React.useState("")
  const [address, setAddress] = React.useState("")
  const [calibrationDate, setCalibrationDate] = React.useState(null)
  const [dcDate, setDcDate] = React.useState(null)
  const [nextDueDate, setNextDueDate] = React.useState(null)
  const [receiptDate, setReceiptDate] = React.useState(null)
  const [poDate, setPoDate] = React.useState(null)
  const [dateOfIssue, setDateOfIssue] = React.useState("")

  const [calProcRefNo, setCalProcRefNo] = React.useState("")
  const [mfgNo, setMfgNo] = React.useState("")
  const [rangeDbData, setRangeData] = React.useState([])
  const [allRangeData, setRangeDataArray] = React.useState([])
  const [specificValuesFromTable, setSpecificValuesFromTable] = React.useState(
    []
  )
  const [identificationNo, setIdentificationNo] = React.useState("")
  const [DUCID, setDUCID] = React.useState([])
  const [calType, setCalType] = React.useState("")
  const [tableName, setTableName] = React.useState("")
  const [unitName, setUnitName] = React.useState("")
  const [location, setLocation] = React.useState("")
  const [accuracy, setAccuracy] = React.useState("")
  const [filterRangeData, setFilterRangeData] = React.useState([])
  const [serialNo, setSerialNo] = React.useState("")
  const [selectedStandards, setSelectedStandards] = React.useState("")
  const [ulrNumber, setUlrNumber] = React.useState(null)
  const [certificateNumber, setCertificateNumber] = React.useState(null)
  const [serviceReqNumber, setServiceReqNumber] = React.useState("")
  const [jobNumber, setJobNumber] = React.useState("")
  const [workOrderNumber, setWorkOrderNumber] = React.useState("")
  const [poNo, setPoNo] = React.useState(null)
  const [startTemp, setStartTemp] = React.useState("")
  const [endTemp, setEndTemp] = React.useState("")
  const [tempDiff, setTempDiff] = React.useState("")
  const [startHumidity, setStartHumidity] = React.useState("")
  const [endHumidity, setEndHumidity] = React.useState("")
  const [humDiff, setHumDiff] = React.useState("")
  const [models, setModel] = React.useState("")
  const [ConOfDuc, setConOfDuc] = React.useState("")
  const [calPoint, setCalPoint] = React.useState("")
  const [calMethod, setCalMethod] = React.useState("")
  const [locationOfInstrument, setLocationOfInstrument] = React.useState("")
  const [srnNo, setSrnNo] = React.useState("")
  const [atmosphericPressure, setAtmosphericPressure] = React.useState("")
  const [disciplineName, setDisciplineName] = React.useState("")
  const [defaultReamrk, setDefaultRemark] = React.useState(null)
  const userType = localStorage.getItem("type")
  const [tableDisplayStatus, setTableDisplayStatus] = React.useState(1)
  const [settingList, setSettingList] = React.useState({})
  const [isPrinting, setisPrinting] = React.useState(false)

  // utils
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current
  })

  // api calls
  function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let remark = null,
        settings = res.data
      let _settingList = {}
      settings.forEach((setting) => {
        if (setting?.keyName?.toLowerCase() === "remarks") {
          remark = setting.value
        }
        _settingList[setting.keyName] = setting.value
      })
      setSettingList(_settingList)
      setDefaultRemark(remark)
    })
  }

  function fetchCertificate() {
    let url = BASE_URL
    axios
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(cer.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cer.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=cer.clientId,cer.id,cer.id,cer.instrumentId,cer.lc,cer.make,cer.model,cer.ConOfDuc,cer.ranges,cer.mfgNo,cer.deletedTableIds,cust.companyName,ins.instrumentName,ins.standardMasterIds,ins.calibrationProcedureNo,ins.referenceStandards,cust.address,cer.dcNumber,cer.calibrationDate,cer.dcDate,cer.nextDueDate,cer.receiptDate,cer.calProcRefNo,cer.mfgNo,cer.identificationNo,cer.DUCID,cer.DUCID,cer.calibrationType,cer.specificValues,cer.location,cer.serialNumber,cer.additionalStandardIds,cer.serviceReqNumber,cer.jobNumber,cer.accuracy,cer.poNumber,cer.poDate,cer.startTemp,cer.endTemp,cer.tempDiff,cer.startHumidity,cer.endHumidity,cer.humDiff,cer.dateOfIssue,cer.model,cer.calPoint,cer.calMethod,cer.locationOfInstrument,cer.srnNo,cer.atmosphericPressure,cer.configuration,cer.workOrderNumber,cer.certificateNumber,cer.ULRNo,cer.tableDisplayStatus,disc.name&_where=(cer.id,eq, ${certificateId})`
      )
      .then((res) => {
        if (res.data.length && res.data[0] != null) {
          setClientId(res.data[0].cust_companyName)
          setInstrumentName(res.data[0].ins_instrumentName)
          setMake(res.data[0].cer_make)
          setModel(res.data[0].cer_model)
          setLC(res.data[0].cer_lc)
          setRanges(res.data[0].cer_ranges)
          setReferenceStandards(res.data[0].ins_referenceStandards)
          setSelectedStandards(res.data[0].cer_additionalStandardIds)
          setCalibrationProcedureNo(res.data[0].ins_calibrationProcedureNo)
          setAddress(res.data[0].cust_address)
          setDcNumber(res.data[0].cer_dcNumber)
          setUlrNumber(res.data[0].cer_ULRNo)
          setCertificateNumber(res.data[0].cer_certificateNumber)
          setCalibrationDate(res.data[0].cer_calibrationDate)
          setDcDate(res.data[0].cer_dcDate)
          setNextDueDate(res.data[0].cer_nextDueDate)
          setReceiptDate(res.data[0].cer_receiptDate)
          setCalProcRefNo(res.data[0].cer_calProcRefNo)
          setMfgNo(res.data[0].cer_mfgNo)
          setIdentificationNo(res.data[0].cer_identificationNo)
          setDUCID(res.data[0].cer_DUCID)
          setCalType(res.data[0].cer_calibrationType)
          res.data[0].cer_specificValues
            ? setSpecificValuesFromTable(
                res.data[0].cer_specificValues.split(",")
              )
            : setSpecificValuesFromTable([])
          setLocation(res.data[0].cer_location)
          setAccuracy(res.data[0].cer_accuracy)
          setSerialNo(res.data[0].cer_serialNumber)
          setServiceReqNumber(res.data[0].cer_serviceReqNumber)
          setJobNumber(res.data[0].cer_jobNumber)
          setWorkOrderNumber(res.data[0].cer_workOrderNumber)
          setPoNo(res.data[0].cer_poNumber)
          setPoDate(res.data[0].cer_poDate)
          setStartTemp(res.data[0].cer_startTemp)
          setEndTemp(res.data[0].cer_endTemp)
          setTempDiff(res.data[0].cer_tempDiff)
          setStartHumidity(res.data[0].cer_startHumidity)
          setEndHumidity(res.data[0].cer_endHumidity)
          setHumDiff(res.data[0].cer_humDiff)
          setDateOfIssue(res.data[0].cer_dateOfIssue)
          setModel(res.data[0].cer_model)
          setConOfDuc(res.data[0].cer_ConOfDuc)
          setCalPoint(res.data[0].cer_calPoint)
          setCalMethod(res.data[0].cer_calMethod)
          setLocationOfInstrument(res.data[0].cer_locationOfInstrument)
          setSrnNo(res.data[0].cer_srnNo)
          setAtmosphericPressure(res.data[0].cer_atmosphericPressure)
          setConfiguration(JSON.parse(res.data[0].cer_configuration))
          setDisciplineName(res.data[0].disc_name)
          setTableDisplayStatus(res.data[0].cer_tableDisplayStatus)
        }

        fetchCertificateStaticTables(
          res.data[0].cer_instrumentId,
          res.data[0].cer_deletedTableIds
        )
        fetchRangesData(res.data[0].cer_instrumentId)

        fetchInstrument(res.data[0].cer_instrumentId)
        if (res.data[0].cer_additionalStandardIds) {
          let standardMasterIds = res.data[0].cer_additionalStandardIds

          var splitStData = standardMasterIds.split(",")
          var combineArray = []
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0]
            combineArray.push(splitByCol)
          })
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data)
            })
        } else if (res.data[0].ins_standardMasterIds) {
          var splitStData = res.data[0].ins_standardMasterIds.split(",")
          var combineArray = []
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0]
            combineArray.push(splitByCol)
          })
          console.log(combineArray.toString())
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data)
              console.log("standards", res.data)
            })
        }
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err)
      })
  }

  function fetchRangesData(instrumentId) {
    let url = BASE_URL
    axios
      .get(url + `ranges?_where=(instrumentId,eq,${parseInt(instrumentId)})`)
      .then((res) => {
        setRangeData(res.data)
      })
  }

  function fetchCertificateStaticTables(instrumentId, deletedTableIds) {
    deletedTableIds = deletedTableIds?.split(",")?.map((id) => Number(id))
    axios
      .get(
        BASE_URL +
          `certificateStaticTables?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        if (deletedTableIds) {
          res.data = res.data.filter((row) => !deletedTableIds.includes(row.id))
        }
        setStaticTables(res.data)
      })
      .catch((err) => {
        console.log("datasheet static tables data fetching error: ", err)
      })
  }

  function fetchStaticReadingRows() {
    let url = BASE_URL
    axios
      .get(
        url +
          `certificateStaticReadings?_where=(certificateId,eq,${certificateId})`
      )
      .then((res) => {
        setStaticCertificateReadings(res.data)
      })
      .catch((err) => {
        console.log("static certificate tables data fetching error: ", err)
      })
  }

  function fetchInstrument(instrumentId) {
    let url = BASE_URL
    axios
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        setInstrument(res.data)
      })
      .catch((err) => {
        console.log("certificate tables data fetching error: ", err)
      })
  }

  const getTableIndex = (tableId, isStatic = false) => {
    if (!isStatic) {
      for (let i = 0; i < tables.length; i++) {
        if (tables[i]["id"] == tableId) {
          return i
        }
      }
    } else {
      for (let i = 0; i < staticTables.length; i++) {
        if (staticTables[i]["id"] == tableId) {
          return i
        }
      }
    }
    return -1
  }

  const initiateStaticReadingRows = () => {
    if (!(staticCertificateReadings && staticTables)) return null

    let colsToSkip = [
      "id", // avoide duplicate
      "unitType",
      "instrumentId",
      "tableName",
      "parameterName",
      "defaultConfiguration",
      "conditionFormatting",
      "lastModified",
      "status",
      "isUncertainty"
    ]

    let readingRows = []
    let tempStaticCertReadings = [...staticCertificateReadings]

    tempStaticCertReadings.forEach((reading, readingIndex) => {
      let tableIndex = getTableIndex(reading.tableId, true)

      if (tableIndex == -1) return null
      let row = []
      row.push(staticTables[tableIndex]["id"])
      row.push(reading["id"])

      for (var col in staticTables[tableIndex]) {
        if (
          !(
            staticTables[tableIndex][col] == 0 ||
            staticTables[tableIndex][col] == null
          ) &&
          !colsToSkip.includes(col)
        ) {
          let data = reading[col]
          row.push(data)
        }
      }
      if (staticTables[tableIndex]["isUncertainty"] == 1) {
        row.push(reading["uncertainty"] ?? "null")
      }

      readingRows.push(row)

      setTableName(staticTables[tableIndex]["tableName"])
      setUnitName(staticTables[tableIndex]["unitType"])
    })
    setStaticReadingRows(readingRows)
  }

  const getInstrumentsList = () => {
    let url = BASE_URL
    axios
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data)
      })
      .catch((error) => {
        console.log("Something Went Wrong!")
      })
  }

  const submitCertificate = () => {
    props.setLoader(true)

    let data = {
      certificateNumber: certificateNumber,
      dcNumber: dcNumber,
      workOrderNumber: workOrderNumber,
      serviceReqNumber: serviceReqNumber,
      ULRNo: ulrNumber,
      poNumber: poNo,
      poDate: poDate
        ? moment(poDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      dateOfIssue: dateOfIssue
        ? moment(dateOfIssue).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      calibrationDate: calibrationDate
        ? moment(calibrationDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      nextDueDate: nextDueDate
        ? moment(nextDueDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      receiptDate: receiptDate
        ? moment(receiptDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      dcDate: dcDate
        ? moment(dcDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD")
    }

    axios
      .patch(BASE_URL + "certificates/" + certificateId, data)
      .then((res) => {
        enqueueSnackbar("Certificate updated successfully!", {
          variant: "success"
        })

        props.setLoader(false)
      })
      .catch((err) => {
        props.setLoader(false)
      })
  }

  const initiateRangesList = () => {
    let rangeDataArray = []
    for (let i = 0; i < rangeDbData.length; i++) {
      rangeDataArray.push(
        rangeDbData[i].specificPoint1,
        rangeDbData[i].specificPoint2,
        rangeDbData[i].specificPoint3,
        rangeDbData[i].specificPoint4,
        rangeDbData[i].specificPoint5,
        rangeDbData[i].specificPoint6,
        rangeDbData[i].specificPoint7,
        rangeDbData[i].specificPoint8
      )
    }
    var filtered = rangeDataArray.filter(function (el) {
      return el != null && el != ""
    })
    setRangeDataArray(filtered)
  }

  useEffect(() => {
    initiateRangesList()
  }, [rangeDbData])

  useEffect(() => {
    initiateStaticReadingRows()
  }, [staticCertificateReadings, staticTables])

  useEffect(() => {
    fetchRangesData()
    getInstrumentsList()
    fetchCertificate()
    fetchStaticReadingRows()
    fetchSettings()
   
   
   if(props.isPrint){
      setTimeout(() => {
       setisPrinting(true)
      }, 8000)
   }
    
  }, [])

  //
  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null
    defaultConfiguration = JSON.parse(defaultConfiguration)
    let hasCommonHeader = defaultConfiguration.hasCommonHeader
    if (hasCommonHeader) {
      let commonHeaderConfig =
        defaultConfiguration.commonHeaderConfig?.split(",")
      let cols = []
      commonHeaderConfig.forEach((col) => {
        let [name, size] = col?.split(":")
        let classNames =
          "header-border " + (name != "$" ? "header-border-bottom" : "")
        cols.push(
          <TableCell
            className={classNames + " printOnlytableColumn "}
            style={{ textAlign: "center" }}
            colSpan={Number(size)}>
            {name != "$" ? name : ""}
          </TableCell>
        )
      })

      return (
        <TableRow>
          {/* for SN */}
          <TableCell
            className="header-border printOnlytableColumn"
            align="center"></TableCell>
          {cols}
        </TableRow>
      )
    }
    return null
  }

  const renderTableCell = (table, row, rowIndex, cell, cellIndex, onChange) => {
    let field = null
    if (String(cell)?.includes("_unit_")) {
      if (table["c" + (cellIndex + 1)]?.toLowerCase()?.match(/m+\d+/g)) {
        field = <div></div>
      } else {
        let unit = cell.split("_unit_")[1]
        field = (
          <span>
            <b>{unit}</b>
          </span>
        )
      }
    } else if (cell && cell[0] == "$") {
      field = (
        <span>
          {!isNaN(cell.slice(1))
            ? Number(cell.slice(1)).toFixed(2)
            : cell.slice(1)}
        </span>
      )
    } else if (cell && cell == "null") {
      field = (
        <span>
          {!isNaN(cell.slice(1)) ? Number(cell.slice(1)).toFixed(2) : ""}
        </span>
      )
    } else {
      field = <span>{row[cellIndex + 2]}</span>
    }
    return (
      <td
        key={cellIndex}
        className="tableCss"
        style={{
          textAlign: "center"
        }}>
        {field}
      </td>
    )
  }
  // render static table
  const renderRows = (table, staticReadingRows) => {
    let i = 1
    return staticReadingRows?.map((row, rowIndex) => {
      if (row[0] == table.id) {
        if (row[2]?.includes("_rh_")) {
          i = 1
          return (
            <tr key={rowIndex} height="14px">
              <td
                className="tableCss"
                colSpan={row.length - 2 + 1}
                style={{ textAlign: "center", fontWeight: "400" }}>
                {row[0 + 2].split("_rh_")[1]}
              </td>
            </tr>
          )
        } else if (row[2]?.includes("_unit_")) {
          return (
            <tr key={rowIndex} height="14px">
              <td className="tableCss"></td>
              {row
                .slice(2, row.length) // 2: tableId + datasheetId
                .map((cell, cellIndex) =>
                  renderTableCell(table, row, rowIndex, cell, cellIndex)
                )}
            </tr>
          )
        }
        // else normal row
        return (
          <tr key={rowIndex} height="14px">
            <td className="tableCss">{i++}</td>
            {row
              .slice(2, row.length) // 2: tableId + datasheetId
              .map((cell, cellIndex) =>
                renderTableCell(table, row, rowIndex, cell, cellIndex)
              )}
          </tr>
        )
      }
    })
  }
  const renderStaticTables = () => {
    if (!staticTables) return null

    return staticTables
      .filter((table) => table.status == 1)
      .map((table, tableIndex) => {
        if (tableDisplayStatus === 1 && table.isAutomated !== 0) return null
        else if (tableDisplayStatus === 2 && table.isAutomated !== 1)
          return null

        let i = 0
        return (
          <div key={tableIndex} sx={{ mb: 5 }} s>
            <Typography variant="h6" component="div" style={{ float: "left" }}>
              {tableIndex +
                1 +
                ". " +
                (configuration?.customTableNames?.[table.id] ||
                  table.tableName)}
            </Typography>
            <div style={{ width: "100%", overflow: "auto" }}>
              <ClassicTable>
                <table
                  style={{ padding: "0px" }}
                  sx={{ minWidth: 650 }}
                  aria-label="simple table">
                  <thead>
                    {renderCommonHeader(table?.defaultConfiguration)}

                    <tr>
                      <th className="header-border tableCss" align="center">
                        <Typography noWrap style={{ textAlign: 'center', color: 'black' }}>
                          <b>S.N.</b>
                        </Typography>
                      </th>
                      {/* render columns*/}
                      {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                        if (table["c" + i] != null) {
                          return (
                            <TableCell
                              className="header-border tableCss"
                              align="center"
                              style={{ textAlign: "center" }}>
                              <Typography
                                noWrap
                                style={{
                                  textAlign: "center",
                                  color: "black"
                                }}>
                                <b>{table["c" + i]}</b>
                              </Typography>
                            </TableCell>
                          )
                        }
                      })}
                      {table["isUncertainty"] == 1 && (
                        <TableCell
                          className="header-border tableCss black"
                          align="center"
                          style={{ textAlign: "center", fontWeight: "600" }}>
                          <Typography
                            noWrap
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "black"
                            }}>
                            {"Expanded Uncertainty"}
                          </Typography>
                        </TableCell>
                      )}
                    </tr>
                  </thead>
                  <tbody>{renderRows(table, staticReadingRows)}</tbody>
                </table>
              </ClassicTable>
            </div>
            <br />
          </div>
        )
      })
  }

  const renderRangeData = () => {
    return allRangeData.map((rdata, index) => {
      return (
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label={rdata}
            value={specificValuesFromTable[index]}
            InputLabelProps={{ shrink: true }}
            className="textfield"
            // onChange={(e) =>
            //     updateSpecificDataName(e.target.value, index)
            // }
            onChange={(e) => {
              setFilterRangeData([
                ...specificValuesFromTable,
                (specificValuesFromTable[index] = e.target.value)
              ])
            }}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
      )
    })
  }

  function spaceStr(n) {
    return " ".repeat(n)
  }

  const generatePDF = () => {
    const element = printComponentRef.current
    // const element = insertBreaks(printComponentRef.current)
    html2pdf()
      .from(element)
      .set({
        margin: [155, 1, 140, 1],
        filename: `certificate_${certificateId}.pdf`,
        // pagebreak: {
        //   mode: ['avoid-all', 'css', 'legacy'],
        // },
        html2canvas: {
          dpi: 192,
          scale:4,
          letterRendering: true,
          useCORS: true
        },
        pagebreak: { after: 'section', mode: [ 'css', 'legacy'] },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4"
        }
      })
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(9)
          pdf.setTextColor(0, 0, 0)
          pdf.setFont("Courier")

          // header section
          let fieldMaxLen = 23
          let fieldGap = 0
          let certNumber =
            certificateNumber ||
            generateNumber(
              settingList["Certificate Number"],
              ID_BASE + Number(certificateId)
            )

          let fields = [
            "ULR NO",
            "Cal. Cert. No",
            "Cert. Issue Date",
            "Cal. Date",
            "Cal. Due Date",
            "Page No."
          ]
          let headerText =
            "\n_______________________________________________________________________________________________________\n"
          headerText += spaceStr(1)
          if (ulrNumber != null)
            headerText += fields[0] + spaceStr(fieldMaxLen - fields[0].length)

          headerText +=
            fields[1] + spaceStr(fieldMaxLen - fields[1].length + fieldGap)
          headerText += fields[2] + spaceStr(18 - fields[2].length + fieldGap)
          headerText += fields[3] + spaceStr(15 - fields[3].length + fieldGap)
          headerText += fields[4] + spaceStr(18 - fields[4].length + fieldGap)
          headerText += fields[5] + spaceStr(8 - fields[5].length + fieldGap)
          headerText += spaceStr(4)

          headerText += "\n"

          headerText += spaceStr(1)
          if (ulrNumber != null)
            headerText +=
              ulrNumber +
              spaceStr(fieldMaxLen - ulrNumber.length || 0 + fieldGap)
          headerText +=
            (certNumber || "") +
            spaceStr(fieldMaxLen - (certNumber?.length || 0) + fieldGap)
          headerText +=
            moment(dateOfIssue || new Date()).format("DD-MM-YYYY") +
            spaceStr(
              18 -
                moment(dateOfIssue || new Date()).format("DD-MM-YYYY")
                  ?.length || 0 + fieldGap
            )
          headerText +=
            moment(calibrationDate || new Date()).format("DD-MM-YYYY") +
            spaceStr(
              15 -
                moment(calibrationDate || new Date()).format("DD-MM-YYYY")
                  ?.length || 0 + fieldGap
            )
          headerText +=
            moment(nextDueDate || new Date()).format("DD-MM-YYYY") +
            spaceStr(
              18 -
                moment(nextDueDate || new Date()).format("DD-MM-YYYY")
                  ?.length || 0 + fieldGap
            )
          headerText +=
            "" +
            i +
            " / " +
            totalPages +
            spaceStr(8 - fields[5].length + fieldGap)

          //  ${certNumber}      ${ulrNumber || ""}         ${moment(dateOfIssue).format('DD-MM-YYYY')}      |    ${moment(calibrationDate).format('DD-MM-YYYY')}   |      ${i} / ${totalPages}     |
          headerText +=
            "\n________________________________________________________________________________________________________"

          pdf.text(headerText, 17, 111)

          // footer section
          let footerText =
            "\n________________________________________________________________________________________________________"
          footerText +=
            "\n\n     Calibrated by" + spaceStr(60) + "Quality manager"
          footerText += "\n" + spaceStr(78) + "(J Malhotra)"
          pdf.text(footerText, 18, 690)
        }
      })
      .save()
  }

  useEffect(() => {
    isPrinting && generatePDF()
  }, [isPrinting])

  // print view
  const printView = () => {
    return (
      <>
        <div id="printOnly2" ref={printComponentRef}>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ border: "none" }}>
                  <div
                    class="content"
                    style={{
                      fontSize: "12px",
                      margin: "20px",
                      marginTop: "0px",
                      padding: "10px",
                      border: "1px solid black"
                    }}>
                    <h5 style={{ textAlign: "center", marginBottom: "10px" }}>
                      <b>
                        <u>Calibration Certificate</u>
                      </b>
                    </h5>
                    <Table>
                      {customerDetailsTable()}
                      <br></br>
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <b>
                            <u>DETAILS OF UNIT UNDER CALIBRATION (UUC)</u>
                          </b>
                        </TableCell>
                      </TableRow>
                      {unitCalibrationDetails()}
                      <Table className={classes.table}>
                        <TableRow>
                          <TableCell>
                            <b>Environmental Conditions: </b>
                          </TableCell>
                          <TableCell>
                            <span>
                              <b>Ambient Temp:</b>
                              {startTemp}°
                            </span>
                          </TableCell>
                          <TableCell>
                            <span>
                              <b>Humidity:</b>
                              {startHumidity} %RH
                            </span>
                          </TableCell>
                        </TableRow>
                      </Table>
                      <br></br>
                      {masterDataTable()}
                    </Table>
                    <div style={{ padding: "10px" }}>
                      <div style={{ fontSize: "10px" }}>
                        <div className="row">{renderStaticTables()}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="">
                            <h5 style={{ float: "left" }}>Remarks :</h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-start" style={{}}>
                      <pre
                        style={{
                          fontFamily: " Calibri, sans-serif",
                          fontSize: "12px"
                        }}>
                        {configuration?.remark || defaultReamrk}
                      </pre>
                    </div>
                    <div className="row mt-2 pt-5">
                      <h5
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          width: "100%",
                          textAlign: "center"
                        }}>
                        ----------END OF CALIBRATION CERTIFICATE----------
                      </h5>
                    </div>
                    <div className="row mt-2">
                      <div
                        className="col col-12"
                        style={{ textAlignLast: "center" }}>
                        <QRCode
                          value={`${DOMAIN}/#/certificate/print/${certificateId}`}
                          align="center"
                          size={80}
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
    function customerDetailsTable() {
      return (
        <Table className={classes.table}>
          <TableRow className={classes.row}>
            <TableCell className={classes.infoCell}>
              <span>
                <b>Customer Name & Address</b> : {clientId}
              </span>
              {/* <br></br> */}
              <span>, {address}</span>
            </TableCell>
          </TableRow>
        </Table>
      )
    }
    function unitCalibrationDetails() {
      return (
        <Table className={classes.table} style={{ fontSize: "12px" }}>
          <TableRow className={classes.row}>
            <TableCell className={classes.infoCell} align="center">
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px"
                }}>
                <b>Name </b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{instrumentName}</TableCell>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Job no. </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{jobNumber}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>make</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{make}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Identification No.</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {identificationNo}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Model</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{models}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Range</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{ranges}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Serial no. </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{serialNo}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Resolution/Least Count</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{lc}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Accuracy</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{accuracy}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Location</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{location}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Department </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}> A-BLOCK</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Calibration Location </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{location}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Condition of Item On received </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>Satisfactory</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell} colSpan={2}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Cal. proc. used</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{calibrationProcedureNo}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
        </Table>
      )
    }
    function masterDataTable() {
      return (
        <div align="center">
          <b>
            DETAILS OF STANDARD USED FOR CALIBRATION
          </b>
          <br></br>
          <table className="standardUnitTable"  align="center">
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td>
                <b>ID. NO. </b>
              </td>
              <td>
                <b>Certificate No.</b>
              </td>
              <td>
                <b>CALIBRATED BY</b>
              </td>
              <td>
                <b>Due Date</b>
              </td>
            </tr>
            {standardMasterArray.map((row, index) => (
              <tr key={row.name}>
                <td>{row.standardName}</td>
                <td>{row.make}</td>
                <td>{row.certificateNumber}</td>
                <td>{row.traceability}</td>
                <td>{moment(row.validUpto).format("DD-MM-YYYY")}</td>
              </tr>
            ))}
          </table>
        </div>
      )
    }
  }

  const printViewClient = () => {
    return (
      <>
        <div id="printOnly2" ref={printComponentRef} className="printWrapper">
          <div style={{ display: "flex" }}>
            <table style={{ padding: "10px", border: "none" }}>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "yellow" }}>
                    {/* <div
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                      }}
                      className="flipedText"
                    >
                      CAL INDIAN LABORATORY
                    </div> */}
                    <img
                      src={window.location.origin + "/leftPanel.PNG"}
                      style={{ width: "100%", height: "100%" }}
                    />
                    {/* <img src="https://app.3schools.in/img/bg.png" style="height: 100%;width: 100%"/> */}
                  </td>
                  <td>
                    <tr>
                      <td style={{ border: "none" }}>
                        <div
                          class="content"
                          style={{
                            fontSize: "15px",
                            margin: "20px",
                            marginTop: "0px",
                            padding: "10px"
                          }}>
                          <div className="row text-start">
                            <div>
                              CAlibration Certificate ID : {certificateId}
                            </div>
                            <div> ULR No.: {ulrNumber}</div>
                          </div>
                          <div>Image 1</div>
                          <div>
                            Cal India Laboratory. Address: Flat No: 201, 2nd
                            Floor, Vishal House, Chandragiri Colony,
                            Tirumalgherry,<br></br> Hyderabad, Telangana 500015.
                          </div>
                          <hr style={{ margin: "5px" }}></hr>
                          <div className="labCertification">
                            ISO/IEC 17025 Accredited Calibration Laboratory
                          </div>
                          <hr style={{ margin: "5px" }}></hr>
                          <div style={{ padding: "0px" }}>
                            <div className="certificateTitle">
                              Calibration Certificate
                            </div>
                            {dateInfo()}
                            <div
                              className="row text-start"
                              style={{ marginTop: "5px" }}>
                              Customer Details
                            </div>
                            <hr style={{ marginBottom: "15px" }}></hr>
                            {customerDetails()}
                            <br></br>
                            <div
                              className="row text-start"
                              style={{ marginTop: "5px" }}>
                              <b>Device Under Calibration: Weight 500g</b>
                            </div>
                            <hr style={{ marginBottom: "5px" }}></hr>
                            {unitUnderCalibrationReading()}
                            <br></br>
                            <div
                              className="row text-start"
                              style={{ marginTop: "5px" }}>
                              <b> Procedure</b>
                            </div>
                            <hr style={{ marginBottom: "5px" }}></hr>
                            {procedureData()}
                            <div style={{ fontSize: "10px" }}>
                              <div className="row">{renderStaticTables()}</div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="col-md-6">
                                <div className="">
                                  <h5 style={{ float: "left" }}>Remarks :</h5>
                                </div>
                              </div>
                            </div>

                            <div className="text-start" style={{}}>
                              <pre
                                style={{
                                  fontFamily: " Calibri, sans-serif",
                                  fontSize: "12px"
                                }}>
                                {configuration?.remark || defaultReamrk}
                              </pre>
                            </div>
                            <div className="row mt-2 pt-5">
                              <h5
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  width: "100%",
                                  textAlign: "center"
                                }}>
                                ----------END OF CALIBRATION
                                CERTIFICATE----------
                              </h5>
                            </div>
                            <div className="row mt-2">
                              <div
                                className="col col-12"
                                style={{ textAlignLast: "center" }}>
                                <QRCode
                                  value={`${DOMAIN}/#/certificate/viewCertificate/${certificateId}`}
                                  align="center"
                                  size={80}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
    function procedureData() {
      return (
        <table style={{ border: "none" }}>
          <tr>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}>
              Calibration Guideline:
            </td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left",
                marginLeft: "50px"
              }}>
              EURAMET cg-18 v. 4.0 (11/2015)
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}>
              Additional Guidelines referred:
            </td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}>
              OIML R76-1 & NABL 129
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}>
              CIL Work Instruction:
            </td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}>
              CIL/WI/7.6.1-1
            </td>
          </tr>
        </table>
      )
    }

    function unitUnderCalibrationReading() {
      return (
        <table style={{ border: "none" }}>
          <tr>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Maximum Capacity : 2
            </td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left",
                marginLeft: "50px"
              }}>
              Room : 2
            </td>
          </tr>
          <tr>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Readability (d) : 2
            </td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Instrument Type : 2
            </td>
          </tr>
          <tr>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Manufacturer : 2
            </td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Terminal Model: 2
            </td>
          </tr>
          <tr>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Serial No. : 2
            </td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Calibration At : Onsite
            </td>
          </tr>
          <tr>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Class : 2
            </td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Asset No.: 2
            </td>
          </tr>
          <tr>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Building : 2
            </td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td
              style={{
                border: "none",
                width: "150px",
                textAlign: "left"
              }}></td>
            <td style={{ border: "none", width: "150px", textAlign: "left" }}>
              Discipline/Group:
            </td>
          </tr>
        </table>
      )
    }

    function customerDetails() {
      return (
        <Table className={classes.table}>
          <TableRow>
            <TableCell className={classes.certificateCell}>Company: </TableCell>
            <TableCell colSpan={3}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.certificateCell}>Address: </TableCell>
            <TableCell colSpan={3}>{address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.certificateCell}> City: </TableCell>
            <TableCell></TableCell>
            <TableCell className={classes.certificateCell}>
              State/Province:
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.certificateCell}>
              Zip/Postal:
            </TableCell>
            <TableCell></TableCell>
            <TableCell className={classes.certificateCell}>Contact: </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.certificateCell}>
              Order Number:
            </TableCell>
            <TableCell></TableCell>
            <TableCell className={classes.certificateCell}>Email:</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </Table>
      )
    }

    function dateInfo() {
      return (
        <Table className={classes.table}>
          <TableRow>
            <TableCell className={classes.certificateCell}>
              Received Date :
            </TableCell>
            <TableCell className={classes.certificateCell}></TableCell>
            <TableCell style={{ width: "40%" }}>Issue Date :</TableCell>
            <TableCell>
              {/* {dateOfIssue || new Date().format("DD-MM-YYYY")} */}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.certificateCell}>
              Calibration Date :
            </TableCell>
            <TableCell className={classes.certificateCell}></TableCell>
            <TableCell style={{ width: "40%" }}>
              Recommended Next Calibration Date:
            </TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </Table>
      )
    }

    function customerDetailsTable() {
      return (
        <Table className={classes.table}>
          <TableRow className={classes.row}>
            <TableCell className={classes.infoCell}>
              <span>
                <b>Customer Name & Address</b> : {clientId}
              </span>
              {/* <br></br> */}
              <span>, {address}</span>
            </TableCell>
          </TableRow>
        </Table>
      )
    }
    function unitCalibrationDetails() {
      return (
        <Table className={classes.table}>
          <TableRow style={{ borderTop: "0px" }}>
            <TableCell colSpan={2} align="center">
              <b>
                <u>DETAILS OF UNIT UNDER CALIBRATION (UUC)</u>
              </b>
            </TableCell>
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={classes.infoCell}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px"
                }}>
                <b>Name </b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{instrumentName}</TableCell>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Job no. </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {serviceReqNumber}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>make</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{make}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Identification No.</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {identificationNo}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Model</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{models}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Range</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{ranges}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Serial no. </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{serialNo}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Resolution/Least Count</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{lc}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Accuracy</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{accuracy}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Location</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{location}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Department </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}> A-BLOCK</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Calibration Location </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{location}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell} colSpan={2}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px"
                  }}>
                  <b>Condition of Item On received </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>Satisfactory</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
        </Table>
      )
    }
    function masterDataTable() {
      return (
        <Table className={classes.table} sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow style={{ borderTop: "0px" }}>
              <TableCell colSpan={2} align="center">
                <b>DETAILS OF UNIT UNDER CALIBRATION (UUC)</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>ID. NO. </TableCell>
              <TableCell>Certificate No.</TableCell>
              <TableCell>CALIBRATED BY</TableCell>
              <TableCell>Due Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {standardMasterArray.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell>{row.standardName}</TableCell>
                <TableCell>{row.make}</TableCell>
                <TableCell>{row.certificateNumber}</TableCell>
                <TableCell>{row.traceability}</TableCell>
                <TableCell>
                  {moment(row.validUpto).format("DD-MM-YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    }
  }
  return (
    <>
      {!isPrinting && (
        <div id="screenOnly" sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Customer Name"
                className="textfield"
                size="small"
                value={clientId}
                disabled
                fullWidth
                variant="outlined"
                onChange={(e) => setClientId(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Work order number"
                className="textfield"
                size="small"
                value={workOrderNumber}
                onChange={(e) => setWorkOrderNumber(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="ULR Number"
                className="textfield"
                size="small"
                value={ulrNumber}
                onChange={(e) => setUlrNumber(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Certificate Number"
                className="textfield"
                size="small"
                value={
                  certificateNumber ||
                  generateNumber(
                    settingList["Certificate Number"],
                    ID_BASE + Number(certificateId)
                  )
                }
                onChange={(e) => setCertificateNumber(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Address"
                className="textfield"
                value={address}
                InputLabelProps={{ shrink: true }}
                disabled
                onChange={(e) => setAddress(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                InputLabelProps={{ shrink: true }}
                label="PO Number"
                className="textfield"
                size="small"
                value={poNo}
                onChange={(e) => setPoNo(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="PO Date"
                  inputFormat="MM/dd/yyyy"
                  InputLabelProps={{ shrink: true }}
                  value={poDate ? poDate : new Date()}
                  onChange={(e) => setPoDate(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Service Request No."
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                value={serviceReqNumber}
                onChange={(e) => setServiceReqNumber(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Job Number."
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                value={jobNumber}
                onChange={(e) => setJobNumber(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="DC Number"
                className="textfield"
                size="small"
                value={dcNumber}
                onChange={(e) => setDcNumber(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date Of Issue"
                  inputFormat="dd/MM/yyyy"
                  value={dateOfIssue ? dateOfIssue : new Date()}
                  onChange={(e) => setDateOfIssue(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date of calibration"
                  InputLabelProps={{ shrink: true }}
                  inputFormat="dd/MM/yyyy"
                  value={calibrationDate ? calibrationDate : new Date()}
                  onChange={(e) => setCalibrationDate(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Next due date"
                  inputFormat="dd/MM/yyyy"
                  value={nextDueDate ? nextDueDate : new Date()}
                  onChange={(e) => setNextDueDate(e)}
                  InputLabelProps={{ shrink: true }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date of receipt"
                  inputFormat="dd/MM/yyyy"
                  value={receiptDate ? receiptDate : new Date()}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setReceiptDate(e.target.value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date of DC"
                  inputFormat="dd/MM/yyyy"
                  value={dcDate ? dcDate : new Date()}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setDcDate(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <hr className="textfield" />
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Instrument Name"
                disabled
                size="small"
                className="textfield"
                InputLabelProps={{ shrink: true }}
                value={instrumentName}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Calibration Procedure No."
                value={calibrationProcedureNo}
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Referencer Standards"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={referenceStandards}
                className="textfield"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="DUC ID"
                value={DUCID}
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Make"
                value={make}
                className="textfield"
                onChange={(e) => setMake(e.target.value)}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Model"
                value={models}
                className="textfield"
                onChange={(e) => setModel(e.target.value)}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Con Of Duc"
                value={ConOfDuc}
                className="textfield"
                onChange={(e) => setConOfDuc(e.target.value)}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Range"
                value={ranges}
                onChange={(e) => setRanges(e.target.value)}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                className="textfield"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="LC"
                value={lc}
                onChange={(e) => setLC(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Serial Number"
                value={serialNo}
                onChange={(e) => setSerialNo(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Accuracy"
                value={accuracy}
                onChange={(e) => setAccuracy(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Cal Point"
                value={calPoint}
                disabled
                className="textfield"
                onChange={(e) => setCalPoint(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Cal Method"
                value={calMethod}
                disabled
                className="textfield"
                onChange={(e) => setCalMethod(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Location Of Instrument"
                value={locationOfInstrument}
                disabled
                className="textfield"
                onChange={(e) => setLocationOfInstrument(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                InputLabelProps={{ shrink: true }}
                label="Start Temperature"
                value={startTemp}
                size="small"
                className="textfield"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                label="End temprature"
                value={endTemp}
                onChange={(e) => setEndTemp(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                label="Start Humidity"
                value={startHumidity}
                onChange={(e) => setStartHumidity(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                label="End Humidity"
                value={endHumidity}
                onChange={(e) => setEndHumidity(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Atmospheric Pressure"
                value={atmosphericPressure}
                onChange={(e) => setAtmosphericPressure(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Discipline"
                disabled
                value={disciplineName}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>

          <hr />
          <div>
            <Typography
              variant="h6"
              className="textfield"
              component="div"
              sx={{ mb: 2 }}>
              Master Equipments Used
            </Typography>
            <ClassicTable className="textfield">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell>Name of Standard</TableCell>
                    <TableCell>Make/Model</TableCell>
                    <TableCell>Certificate No.</TableCell>
                    <TableCell>Tracability</TableCell>
                    <TableCell>Cal.dt</TableCell>
                    <TableCell>Due Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {standardMasterArray.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.standardName}</TableCell>
                      <TableCell>{row.make}</TableCell>
                      <TableCell>{row.certificateNumber}</TableCell>
                      <TableCell>{row.traceability}</TableCell>
                      <TableCell>
                        {row.calDate
                          ? moment(row.calDate).format("DD-MM-YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {moment(row.validUpto).format("DD-MM-YYYY")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ClassicTable>
          </div>
          <br />
          <Grid container spacing={2} sx={{ my: 2 }}>
            {renderRangeData()}
          </Grid>
          <hr className="textfield" />
          <div className="textfield">
            <Typography variant="h6" className="textfield" component="div">
              Observed Readings
            </Typography>

            {renderStaticTables()}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div>
                <h5 style={{ float: "left" }}>Remarks :</h5>

                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={5}
                  placeholder="Enter remark here"
                  style={{
                    width: 900,
                    float: "left",
                    border: "none",
                    fontSize: "12px"
                  }}
                  value={configuration?.remark || defaultReamrk}
                />
              </div>
            </div>
          </div>
          <hr className="textfield" />
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Calibrated by"
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                className="textfield"
                label="Checked / Approved by"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Issue Number"
                size="small"
                className="textfield"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Rev. Number"
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Form Number"
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Issue Date"
                  inputFormat="MM/dd/yyyy"
                  onChange={(e) => {
                    // console.log(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Revision Date"
                  inputFormat="MM/dd/yyyy"
                  onChange={(e) => {
                    // console.log(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <div
            align="right footer"
            className="textfield"
            style={{ marginTop: "20px" }}>
            <hr className="textfield" />
            <QRCode
              value={`${DOMAIN}/#/certificate/viewCertificate/${certificateId}`}
              align="center"
              size={80}
            />
          </div>
        </div>
      )}
      <Toolbar style={{ padding: "0px", width: "100%" }}>
        {userType != 3 && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              submitCertificate()
            }}>
            Save
          </Button>
        )}
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={() => {
            setisPrinting(true)
          }}>
          Print
        </Button>
      </Toolbar>

      {isPrinting && printView()}
      {/* {isPrinting && printViewClient()} */}
    </>
  )
}
