import React, { useEffect } from "react";
import { BASE_URL, BANK_DETAILS, GST_NUMBER } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "./viewInvoice.css";
import { makeStyles } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
const { ToWords } = require("to-words");
const axios = require("axios");
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "2px solid black",
    },
  },
  tableWithoutBorder: {
    "& .MuiTableCell-root": {
      border: "2px solid black",
      borderBottom: "none !important",
    },
  },
  row: {
    height: 15,
  },
  gstCell: {
    height: 5,
    border: "none",
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 100,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 250,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 40,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
});
function InstrumentTable(props) {
  const toWords = new ToWords();
  let productDetails = props.productDetails;
  console.log("Product Details " + props.productDetails);
  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    grandTotal: 0,
  };
  if (productDetails.length > 0) {
    for (let i = 0; i < productDetails.length; i++) {
      amounts.totalAmountBeforeTax += productDetails[i].prR_amount;
    }
    amounts.gst = (amounts.totalAmountBeforeTax * 18) / 100;
    amounts.grandTotal = amounts.gst + amounts.totalAmountBeforeTax;
    console.log(productDetails);
  }
  return (
    <Table mt={5} className={props.classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={props.classes.srCell} align="center">
            <b>Sr. No</b>
          </TableCell>
          <TableCell className={props.classes.nameCell} align="center">
            <b>Product Name </b>
          </TableCell>
          <TableCell className={props.classes.makeCell} align="center">
            <b>Discription</b>
          </TableCell>
          <TableCell className={props.classes.rangeCell} align="center">
            <b>Rate</b>
          </TableCell>
          <TableCell className={props.classes.qntCell} align="center">
            <b>Quantity</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>Amount</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productDetails.length > 0 &&
          productDetails.map((product, i) => (
            <TableRow className={props.classes.row}>
              <TableCell className={props.classes.srCell} align="center">
                {product.prR_id}
              </TableCell>
              <TableCell className={props.classes.nameCell} align="left">
                {product.custP_productName}
              </TableCell>
              <TableCell
                className={props.classes.nameCell}
                align="center"
                style={{ minWidth: "170px" }}
              >
                {product.prR_discription}
              </TableCell>
              <TableCell className={props.classes.rangeCell} align="center">
                {product.prR_rate.toFixed(2)}
              </TableCell>
              <TableCell className={props.classes.qntCell} align="center">
                {product.prR_quantity}
              </TableCell>
              <TableCell className={props.classes.rateCell} align="center">
                {product.prR_amount.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell
            className={props.classes.rateCell}
            align="left"
            colSpan={4}
          >
            <b>GST NO : {GST_NUMBER}</b>
          </TableCell>
          <TableCell className={props.classes.qntCell} align="center">
            <b>Sub Total</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.totalAmountBeforeTax.toFixed(2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.rateCell}
            align="left"
            colSpan={4}
          >
            <b>
              <table style={{ border: "none" }}>
                <tr>
                  <td style={{ border: "none", minWidth: "60px" }}>
                    {" "}
                    Bank Name
                  </td>
                  <td style={{ border: "none" }}>:</td>
                  <td style={{ border: "none" }}>
                    {BANK_DETAILS["Bank Name"]}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none", minWidth: "60px" }}>
                    Bank A/c. No.
                  </td>
                  <td style={{ border: "none" }}>:</td>
                  <td style={{ border: "none" }}>
                    {BANK_DETAILS["Bank A/c. No."]}
                  </td>
                </tr>
                <tr style={{ border: "none", minWidth: "60px" }}>
                  <td style={{ border: "none" }}>IFSC Code</td>
                  <td style={{ border: "none" }}>:</td>
                  <td style={{ border: "none" }}>
                    {BANK_DETAILS["IFSC Code"]}
                  </td>
                </tr>
              </table>
            </b>
          </TableCell>
          <TableCell className={props.classes.qntCell} align="center">
            <b>GST</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.gst}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.rateCell}
            align="left"
            colSpan={4}
          >
            <b>
              <table style={{ border: "none" }}>
                <tr>
                  <td style={{ border: "none", minWidth: "100px" }}>
                    BILL AMOUNT :{" "}
                  </td>
                  <td style={{ border: "none" }}>
                    {toWords
                      .convert(amounts.grandTotal, {
                        currency: true,
                      })
                      .toUpperCase()}
                  </td>
                </tr>
              </table>
            </b>
          </TableCell>
          <TableCell className={props.classes.qntCell} align="center">
            <b>Grand Total</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.grandTotal.toFixed(2)}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
export default function ViewCustomInvoice() {
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  const [productList, setProductList] = React.useState([]);
  const [productDetails, setProductDetails] = React.useState({});
  const [termsAndConditions, setTermAndCondition] = React.useState();
  const [invoiceData, setInvoiceData] = React.useState({});
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });
  const getCustomReadingsArray = () => {
    axios
      .get(
        BASE_URL +
          `xjoin?_join=maininvoice.invoices,_j,prR.customProductRanges,_j,custP.customProducts&_on1=(maininvoice.id,eq,prR.productId)&_on2=(prR.customProductId,eq,custP.id)&_fields=prR.id,custP.productName,prR.id,prR.discription,prR.quantity,prR.rate,prR.amount,prR.totalAmount,prR.sgst,prR.cgst,custP.id&_where=(prR.productId,eq,${params.mainId})`
      )
      .then((res) => {
        setProductDetails(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };
  function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let termsAndCondition = null,
        settings = res.data;
      let _settingList = {};
      settings.forEach((setting) => {
        if (setting?.keyName?.toLowerCase() === "terms and condition") {
          termsAndCondition = setting.value;
        }
        _settingList[setting.keyName] = setting.value;
      });
      setTermAndCondition(termsAndCondition);
    });
  }
  const getProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "customProducts")
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  const getInvoiceData = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=invoice.invoices,_j,client.clients&_on1=(invoice.clientId,eq,client.id)&_fields=client.companyName,invoice.clientId,invoice.subject,client.address,client.contact,invoice.poNumber,invoice.poDate,invoice.gstType,invoice.challan_no,invoice.challan_date,invoice.party_challan_no,invoice.party_challan_date&_where=(invoice.clientId,eq,${params.id})~and(invoice.poNumber,eq,${params.poNumber})~and(invoice.status,eq,1)`
      )
      .then((res) => {
        debugger;
        setInvoiceData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };
  useEffect(() => {
    getCustomReadingsArray();
    getInvoiceData();
    fetchSettings();
    getProductList();
  }, []);

  return (
    <>
      <Paper sx={{ mx: 5, mt: 2, p: 2 }} style={{ border: "1px solid" }}>
        <div className="invoice" ref={printComponentRef}>
          <div style={{ padding: "5px", margin: "5px", marginTop: "0px" }}>
            <div style={{ height: "150px" }}></div>
            <Grid
              container
              mt={3}
              spacing={2}
              style={{ marginLeft: "5%", marginRight: "5%" }}
            >
              <Grid md={7} className="font-link" align="center">
                <h5>
                  <b>Tax invoice</b>
                </h5>
              </Grid>
              <Grid md={5} style={{ marginLeft: "0%" }}>
                Page 01 of 1
              </Grid>
            </Grid>
            <Table
              className={classes.tableWithoutBorder}
              style={{ borderBottom: "none !important" }}
            >
              <TableBody>
                <TableRow className={classes.row}>
                  <TableCell
                    rowSpan={3}
                    className={classes.cell}
                    style={{ padding: "0px", width: "60%" }}
                  >
                    <b>M/s. :</b> <span>{invoiceData?.client_companyName}</span>
                    <br></br>
                    <br></br>
                    <br></br>
                    <b> Contact Person :</b>
                    <span>
                      {invoiceData?.client_contact?.contactPersonName}
                    </span>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <span>
                      <b> Invoice No. : {invoiceData?.poNumber}</b>
                      <br></br>
                    </span>
                    <span>
                      <b>Date :{invoiceData?.challan_date}</b>
                      <br></br>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.cell}>
                    <span>
                      <b>Challan No : {invoiceData?.challan_no}</b>
                      <br></br>
                    </span>
                    <span>
                      <b>Challan Dt :{invoiceData?.challan_date}</b>
                      <br></br>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.cell}>
                    <span>
                      <b>Party Chl No : {invoiceData?.party_challan_no}</b>
                      <br></br>
                    </span>
                    <span>
                      <b>Party Chl Date : {invoiceData?.party_challan_date}</b>
                      <br></br>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.cell}>
                    Place of Supply{" "}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {" "}
                    <span>
                      <b>PO No : {invoiceData?.poNumber}</b>
                      <br></br>
                    </span>
                    <span>
                      <b>P O Date :{invoiceData?.poDate}</b>
                      <br></br>
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <InstrumentTable
              classes={classes}
              productDetails={productDetails}
            ></InstrumentTable>
            <div
              style={{
                borderLeft: " 2px solid black",
                borderBottom: " 2px solid black",
                borderRight: " 2px solid black",
                paddingLeft: "10px",
              }}
            >
              <div className="font-link-footer">
                <br></br>
                <h6>
                  <b>* Terms And Condition:-</b>
                </h6>{" "}
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={5}
                  placeholder="Enter remark here"
                  style={{ width: 900, float: "left", border: "none" }}
                  value={termsAndConditions}
                />
                <br></br>
                <br></br>
                <br></br>
                <h6>
                  <b>Receiver's signature</b>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </Paper>

      <Toolbar style={{ padding: "0px", width: "100%" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Toolbar>
    </>
  );
}
